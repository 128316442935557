<div class="custom-modal">
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="arastavel">
    <div class="header">
      <h2 mat-dialog-title>Selecionar Tarefa</h2>
      <mat-icon mat-dialog-close="cancel">close</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-progress-bar [hidden]="!isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
  <div mat-dialog-content class="content">

    <form *ngIf="!isLoading && data.tela == 'timer'" [formGroup]="formSprintAndTask">
      <mat-form-field>
        <mat-label>Sprint</mat-label>
        <mat-select disableOptionCentering="true" [disabled]="isLoading" (selectionChange)="getTasks()"
          formControlName="sprintFormControl" placeholder="Sprint" [(ngModel)]="sprintIdSelecionado">
          <mat-option><ngx-mat-select-search [formControl]="sprintMultiFilter" placeholderLabel="Buscar..."
              noEntriesFoundLabel="Nenhum resultado encontrado..."></ngx-mat-select-search></mat-option>
          <mat-option [matTooltip]="sprint.name" *ngFor="let sprint of filteredSprints | async" [value]="sprint?.id">
            [{{sprint.tenant}}] - {{ sprint.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Suas Tarefas</mat-label>
        <mat-select disableOptionCentering="true" formControlName="taskFormControl" placeholder="Tarefa"
          [(ngModel)]="taskIdSelecionado">
          <mat-option><ngx-mat-select-search [formControl]="taskMultiFilter" placeholderLabel="Buscar..."
              noEntriesFoundLabel="Nenhum resultado encontrado..."></ngx-mat-select-search></mat-option>
          <mat-option [matTooltip]="task.title" *ngFor="let task of filteredTasks | async" [value]="task?.id">
            {{ task.parentTitle }} - {{ task.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div class="alert alert-info">
    <small class="text-left"><b>Fique atento:</b> As alterações em WorkItens são carregadas no Corp através de processos que podem demorar alguns minutos. Não se preocupe, caso não esteja visualizando sua tarefa ainda, confira se a mesma está atribuída a você e em sprint ativa nesta data. Caso contrário, aguarde alguns instantes a sincronização ou procure o seu Gestor de Projetos.</small>
  </div>

  <div class="modal-actions" mat-dialog-actions>
    <div>
      <button class="text-white" mat-raised-button (click)="openCreateNew()" style="background-color: #1c8763;">
        Novo Workitem
      </button>
    </div>
    <button *ngIf="!isLoading && data.tela == 'timer'" class="text-white" mat-raised-button (click)="concluir($event)"
      color="primary" [disabled]="!isLoading && formSprintAndTask.invalid">
      Selecionar <i class="fas fa-arrow-right"></i>
    </button>
  </div>
</div>