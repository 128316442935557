import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkService } from 'src/app/services/work.service';
import { ExpedienteDia } from 'src/app/models/expedientes';
import { UsuarioIndicadores } from 'src/app/models/usuario-indicadores';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ApontamentosComponent } from '../apontamentos/apontamentos.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})

export class WorkComponent implements OnInit {
  @Input() datasExpedientes: ExpedienteDia[];
  @Input() isLoading: Boolean;
  @Input() indicadores: UsuarioIndicadores;

  showFilter: boolean;
  isAdmin = StorageService.getAdmin();
  isOperacoes = StorageService.getIdTime() == 1;


  objetos: any = {
    horasComerciais: [],
    horasDiferenciadas: [],
    horasSobreAviso: [],
    itens: []
  };

  usuarios: any;
  searchString: string;
  analistaHoras: any;
  devHoras: any;

  isSobreAvisoReal: boolean = this.isAdmin ? false : true;
  isSequoia: boolean = false;
  selectedItem: any;

  constructor(
    private workService: WorkService,
    private usuarioService: UsuarioService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getExpediente();
  }

  refreshAll() {
    this.getExpediente();
  }

  getExpediente() {
    this.isLoading = true;
    this.usuarioService.getExpedientes().subscribe(response => {
      this.datasExpedientes = response;
      this.isLoading = false;
    },
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        console.error(error);
        this.openSnackBar('Erro ao consultar expedientes!', 'OK', 3000, 'danger-snackbar');
      });
  }

  public obterDiaSemanaPorExtenso(data: Date): string {
    const d = new Date(data);
    const diasDaSemana = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    const diaSemana = diasDaSemana[d.getDay()];
    return diaSemana;
  }

  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this.snackBar.open(message, action, { duration, panelClass });
  }

}
