import { Component, Input, OnInit } from '@angular/core';
import { Menu } from 'src/app/models/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-nav',
  templateUrl: './menu-nav.component.html',
  styleUrls: ['./menu-nav.component.scss']
})
export class MenuNavComponent implements OnInit {
  @Input() menuItems: any[];
  constructor(
    private router: Router
  ) {

  }

  ngOnInit(): void {

  }

  navigate(item: Menu) {
    if (!item.children?.length)
      this.router.navigate([item.url]);
    else {
      this.router.navigate([item.children[0].url]);
    }
  }


  isActive(item: Menu): boolean {

    if (item?.children?.length)
      return item?.children.some(m2 => this.router.isActive(m2.url, false)) || this.router.isActive(item.url, true);
    else
      return this.router.isActive(item.url, true);
  }

}
