<mat-card [ngClass]="{'atraso': atraso}"
  class="card-tfs slide d-flex justify-content-center align-items-stretch text-selection-none">
  <div class="barra-lateral" [ngStyle]="{'background-color': bgColor(task.status), 'opacity' : '0.8'}">
  </div>
  <mat-card-content class="tarefas-container text-center card-content-tfs">
    <div class="tarefa-item">
      <div class="tarefa-item-content">
        <!-- <p class="line-1">{{task.originalEstimate}}hrs - {{task.completedWork}}hrs = {{task.remaining|Work}}</p> -->
        <div class="d-flex" style="align-items: center;flex-grow: 1;width:100%;justify-content: center;">
          <app-timer [hidden]="admin" #timerChild [(task)]="task" [hideCron]="true" [origem]="origem">
          </app-timer>
          <div class="d-flex" style="align-items: flex-start;flex-direction: column;align-items: stretch;flex-grow: 1;">

            <p class="task-title pb-2" style="line-height: normal;">
              <a *ngIf="task.idWorkItem" [href]="itemUrl(task)" target="_blank"
                [matTooltip]="'Abrir link para a '+ task.type+' no board do projeto'">
                <img [src]="iconUrl(task)" class="icon-board" /><span>{{task.idWorkItem}}</span>
              </a>
              <span *ngIf="task.idWorkItem"> - {{task.title ? task.title :task.referenciaDescricao}}</span>
              <span *ngIf="!task.idWorkItem && task.title">{{task.title }}</span>
              <span *ngIf="!task.idWorkItem && !task.title && task.referenciaDescricao">{{task.referenciaDescricao}}
              </span>
              <span *ngIf="!task.idWorkItem && !task.title && !task.referenciaDescricao">Sem tarefa</span>

              <!-- <span *ngIf="!task.idWorkItem && !task.tag">
                <span>{{task.title ? task.title : "Sem tarefa"}}</span>
              </span> -->
            </p>
            <p *ngIf="task.bloqueada" [matTooltip]="task.motivoBloqueio">
              <small class="atraso"><i class="fas fa-ban"></i>&nbsp;BLOCKED</small>
            </p>
            <p *ngIf="task.targetDate" [matTooltip]="'Previsto para: '+ (task.targetDate | date:'dd/MM')">
              <small [ngClass]="{'atraso': atraso, 'no-prazo':!atraso}">
                <i class="fas fa-clock"></i>&nbsp;{{atraso ? 'ATRASADO' : 'NO PRAZO'}}
              </small>
            </p>
            <div class="line-horario mt-2">
              <div>{{task.horarioInicio?.substring(0,5)}} - {{task.horarioFim?.substring(0,5)}}
                <br />
                <small *ngIf="task.status" [ngStyle]="{'color': bgColor(task.status)}"><b>{{task.status}}</b></small>
              </div>

              <button mat-icon-button (click)="expanded=!expanded"
                [matTooltip]="!expanded?'Mostrar detalhes' : 'Recolher detalhes'">
                <mat-icon *ngIf="!expanded">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="expanded">keyboard_arrow_up</mat-icon>
              </button>
            </div>
            <p class="mt-4" *ngIf="expanded && task.idParent">
              <a [href]="itemParentUrl(task)" target="_blank" matTooltip="Item pai relacionado">
                {{task.idParent}} {{task.parentTitle ? task.parentTitle: task.referenciaDescricao}}
              </a>

            </p>
            <p class="line-1" *ngIf="expanded">
              <small>{{task.sprintName}}</small><br />
            </p>
            <p class="line-2" *ngIf="expanded">
              <small [hidden]="!task.tag">#{{task.tag}}</small>
            </p>


          </div>
        </div>
      </div>

      <div class="tarefa-item-actions">
        <button [hidden]="!task.idWorkItem" (click)="openEditTask(task)" matTooltip="Editar Tarefa"
          class="circle-button">
          <mat-icon>edit</mat-icon>
        </button>
        <button [hidden]="admin" (click)="openDialog(task)" matTooltip="Registrar Apontamento" class="circle-button">
          <mat-icon>timer</mat-icon>
        </button>
        <div class="remaining-work" matTooltip="Remaining Work" [hidden]="!task.remainingWork">
          <small>{{task.remainingWork?.toFixed(1)}}hrs</small>
        </div>
      </div>

    </div>
  </mat-card-content>
</mat-card>