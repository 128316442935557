<div class="devops-tfs">
  <div class="work-bar">
    <div class="day-title">
      <span class="day">{{day.dataRef | date:'dd'}}</span>
      <div class="month">
        /<span>{{day.dataRef | date:'MM'}}</span>
      </div>
    </div>
    <div class="day-actions">
      <small>capacity: {{day.capacity}}hrs</small>
    </div>
  </div>
  <div class="d-flex">
    <mat-card class="work-day-container mb-0">
      <mat-progress-bar mode="indeterminate" [hidden]="!isLoading" color="primary"></mat-progress-bar>
      <mat-card-content *ngIf="!isLoading" class="no-padding">

        <!-- <div class="d-flex barra-expediente">
          <div class="timepicker-field">
            <input matTooltip="Horário da entrada" matInput aria-label="default time"
              [ngxTimepicker]="inicioExpedienteValue" [format]="24" readonly [(ngModel)]="day.inicioExpediente" />
            <ngx-material-timepicker (closed)="updateExpediente()" #inicioExpedienteValue> </ngx-material-timepicker>
          </div>
          <div class="d-flex barra-interativa">
            <div class="d-flex justify-content-between align-items-center">
              <app-slider-horario-expediente class="slider-horario" *ngFor='let horario of day.horarios' basedOn="end"
                [horarios]="day.horarios" [horario]="horario" [parent]="this" [day]="day">
              </app-slider-horario-expediente>
            </div>
            <div class="d-flex justify-content-between align-items-center" style="margin-top:-58px">
              <app-slider-horario-expediente class="slider-horario" *ngFor='let horario of day.horarios' basedOn="start"
                [horarios]="day.horarios" [horario]="horario" [parent]="this" [day]="day">
              </app-slider-horario-expediente>
            </div>
          </div>
          <div class="timepicker-field">
            <input matTooltip="Horário da saída" matInput aria-label="default time" [ngxTimepicker]="fimExpedienteValue"
              [format]="24" readonly [(ngModel)]="day.fimExpediente" />
            <ngx-material-timepicker (closed)="updateExpediente()" #fimExpedienteValue> </ngx-material-timepicker>
          </div>
        </div> -->
        <div class="d-flex pt-4 pl-4 pr-4 pb-0">
          <mat-form-field style="width:40%;margin-right:10%">
            <mat-label>Entrada:</mat-label>
            <input matInput matTooltip="Horário da entrada" matInput aria-label="default time"
              [ngxTimepicker]="inicioExpedienteValue" [format]="24" readonly [(ngModel)]="day.inicioExpediente" />
            <ngx-material-timepicker (closed)="updateExpediente()" #inicioExpedienteValue> </ngx-material-timepicker>
          </mat-form-field>
          <mat-form-field style="width:40%;margin-left:10%">
            <mat-label>Saída:</mat-label>
            <input matInput matTooltip="Horário da saída" matInput aria-label="default time"
              [ngxTimepicker]="fimExpedienteValue" [format]="24" readonly [(ngModel)]="day.fimExpediente" />
            <ngx-material-timepicker (closed)="updateExpediente()" #fimExpedienteValue> </ngx-material-timepicker>
          </mat-form-field>
        </div>
        <div class="pb-4">
          <mat-button-toggle-group appearance="legacy" [value]="toggleDia" (change)="visionChange($event)">
            <mat-button-toggle [value]="false" matTooltip="Ver Programação">
              <mat-icon>work</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle [value]="true" matTooltip="Ver Pré Apontamentos">
              <mat-icon>schedule</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="work-bar gray" [hidden]="toggleDia">
          <div class="day-title">
            <span>Programação</span>
          </div>
          <div class="add-new">
            <button mat-icon-button color="primary" (click)="openBottomSheet()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <!-- <div class="set-default">
            <button mat-button (click)="setDefault()">Definir como padrão</button>
          </div> -->
        </div>
        <div class="expedientes d-flex justify-content-between align-items-center" *ngIf="!toggleDia">

          <!-- <p class="p-1 m-0" style="line-height: 12px;width: 80px;">
            
          </p> -->
          <div class="d-flex bd-highlight align-items-center" style="flex-grow: 1;" [hidden]="toggleDia">
            <div cdkDropList cdkDropListOrientation="vertical" class="expedientes-container" *ngIf="!toggleDia"
              (cdkDropListDropped)="drop($event)">
              <div class="expediente-item" *ngFor='let horario of day.horarios' cdkDrag
                cdkDragBoundary=".expedientes-container"
                [ngClass]="horario.contabilizaHoras === false ? 'ausencia' : 'normal'"
                [matTooltip]="horario.descricaoExpediente" (dblclick)="editItem(horario)">
                <div class="d-flex align-items-center" style="flex-grow:1">
                  <div>
                    <span style="white-space: nowrap;">{{horario.horarioInicio.substring(0,5)}} -
                      {{horario.horarioFim.substring(0,5)}}</span>
                  </div>
                  <div class="d-flex align-items-center pl-2" style="flex-grow:1;line-height: 12px;">
                    <div class="text-left" style="flex-grow:1">
                      <strong>
                        {{horario.tag ? horario.tag: 'sem tag proj.'}}
                      </strong><br />
                      <small>{{horario.tipo}}</small>
                    </div>
                    <mat-icon>unfold_more</mat-icon>
                  </div>
                </div>
              </div>
              <!-- <div class="expediente-item">
                <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div> -->
            </div>
          </div>
          <!-- <div class="button-grid" style="width: 80px;">
            <button mat-mini-fab color="accent" (click)="openNewTfs()" class="user-content-button ml-1"
              matTooltip="Nova tarefa">
              <i class="fas fa-plus"></i>
            </button>
          </div> -->
        </div>
        <div class="work-bar gray" [hidden]="!toggleDia">
          <div class="day-title">
            <span>Pré Apontamentos</span>
          </div>
          <div class="add-new">
            <button mat-icon-button color="primary" (click)="openCreateWorkItem(day.dataRef, refreshAll)"
              [matTooltip]="'Criar nova tarefa neste dia'">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <!-- <div class="set-default">
            <button mat-button (click)="setDefault()">Definir como padrão</button>
          </div> -->
        </div>
        <section class="active" [hidden]="!toggleDia">
          <div class="no-padding">
            <div *ngFor="let task of tasks">
              <app-task [task]="task"></app-task>
            </div>
          </div>
        </section>
      </mat-card-content>

      <mat-card-content *ngIf="exibeApontarTudo && permiteApontarTudo && tasks.length>0">
        <div class="d-flex justify-content-center">
          <button mat-button color="primary" (click)="saveAllDay(tasks)">
            <mat-icon>add</mat-icon>Lançar dia todo
          </button>
        </div>
      </mat-card-content>


      <mat-card-content [hidden]="!toggleDia" *ngIf="!isLoading && tasks.length === 0">
        <div class="d-flex justify-content-center">
          Nenhuma Task para exibição neste filtro.
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<br />