import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Saldo } from '../models/saldo';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};



export class Saldo {
  id?: number;
  logon?: string;
  mes: any;
  ano: any;
  fechamento: boolean;
  qtd?: number;
  dia?: number;
}

export class FiltroSaldo {
  logons?: string[];
  mes?: number;
  ano?: number;
  saldo?: number;
  fechamento?: boolean;
  qtd?: number;
  total?: string;
  itens?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SaldoService {

  constructor(private http: HttpClient) { }

  getListarSaldos(filtro: FiltroSaldo): Observable<FiltroSaldo[]> {
    return this.http.post<FiltroSaldo[]>(`Saldo/listar`, filtro, httpOptions);
  }

  getCriarSaldos(saldo: Saldo): Observable<Saldo> {
    return this.http.post<Saldo>(`Saldo/create`, saldo, httpOptions);
  }

  getEditarSaldo(saldo: Saldo): Observable<Saldo> {
    return this.http.put<Saldo>(`Saldo/edit`, saldo, httpOptions);
  }

  getSaldoDetalhes(id: number): Observable<FiltroSaldo[]> {
    return this.http.get<FiltroSaldo[]>(`Saldo/details/${id}`, httpOptions);
  }

  getExcluirSaldo(id: number): Observable<FiltroSaldo[]> {
    return this.http.delete<FiltroSaldo[]>(`Saldo/delete/${id}`, httpOptions);
  }

  getDashboardSaldo(logon: string): Observable<FiltroSaldo[]> {
    return this.http.get<FiltroSaldo[]>(`Saldo/dashboard/${logon}`, httpOptions);
  }

}
