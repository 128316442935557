import {
  Component,
  OnInit,
  Inject,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Usuario } from "src/app/models/usuario";
import { Time } from "src/app/models/time";
import { environment } from "src/environments/environment";
import { Cargo } from "src/app/models/cargo";
import { StorageService } from "src/app/services/storage/storage.service";
import { MsalService } from "@azure/msal-angular";
import { AuthJiraService } from "src/app/services/authJira.service";
import { UsuarioService } from "src/app/services/usuario.service";

@Component({
  selector: "app-modal-perfil",
  templateUrl: "./modal-perfil.component.html",
  styleUrls: ["./modal-perfil.component.scss"],
})
export class ModalPerfilComponent implements OnInit {
  isAdmin: boolean = StorageService.getAdmin();
  cadastroUsuario: FormGroup;
  isEditable: boolean;
  isLoading: boolean;
  times: Time[];
  cargos: Cargo[];
  logon: string;
  avatar = `${environment.api}Usuario/imagem/perfil/logon`;

  usuario: Usuario = {
    logon: "",
    idCargo: null,
    idTime: null,
    imagem: "",
    email: "",
    emailTFS: "",
    senha: "",
    emailJira: "",
    jiraAccountId: "",
    documento: "",
    nome: "",
    celular: "",
    admin: false,
    ativo: true,
    isCLT: true,
  };

  constructor(
    public userService: UsuarioService,
    public router: Router,
    private authJiraService: AuthJiraService,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private msAuthService: MsalService,
    private dialog: MatDialog,
    private cdref: ChangeDetectorRef
  ) {
    if (data.logon !== null && data.logon !== undefined) {
      this.isEditable = true;
      this.userDetails(data.logon);
      this.logon = data.logon;
    }
  }

  ngOnInit() {
    this.createForm();
    //this.getTeams();
    //this.getRoles();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  createForm() {
    this.cadastroUsuario = this.fb.group({
      logon: [
        { value: "", disabled: !this.isAdmin },
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      // idCargo: [''],
      // idTime: [''],
      imagem: [""],
      email: [
        { value: "", disabled: !this.isAdmin },
        Validators.compose([Validators.required, Validators.email]),
      ],
      emailTFS: ["", Validators.email],
      emailJira: ["", Validators.email],
      senha: [""],
      documento: [""],
      nome: [
        "",
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      celular: [""],
      admin: [""],
      isCLT: [""],
      ativo: [""],
    });
  }

  private userDetails(logon: any) {
    this.isLoading = true;
    this.userService.getUserById(logon).subscribe(
      (usuario: Usuario) => {
        this.isLoading = false;
        this.usuario = usuario;
      },
      (error: HttpErrorResponse) => {
        console.error(error.message);
      }
    );
  }

  createNewUser() {
    if (this.cadastroUsuario.status === "VALID") {
      this.isLoading = true;

      this.userService.createUser(this.usuario).subscribe(
        (usuario: Usuario) => {
          this.isLoading = false;
          this.dialog.closeAll();
          this.openSnackBar("Usuário cadastrado com sucesso!", "OK", 3000, 'success-snackbar');
        },
        (err: HttpErrorResponse) => {
          this.isLoading = false;
          console.error(err);
          this.openSnackBar("Erro ao cadastrar novo usuário!", "OK", 3000, 'danger-snackbar');
        }
      );
    }
  }

  editUser() {
    if (this.cadastroUsuario.status === "VALID") {
      this.isLoading = true;

      this.userService.editUser(this.usuario).subscribe(
        (usuario: Usuario) => {
          if (this.logon == StorageService.getLogon()) {
            const user = StorageService.getUsuario();
            user.emailTFS = this.usuario.emailTFS;
            let team = this.times.find((x) => x.id == this.usuario.idTime);
            user.team = team ? team.descricao : "";
            StorageService.setUsuario(user);
          }
          this.isLoading = false;
          this.dialog.closeAll();
          this.openSnackBar("Usuário editado com sucesso!", "OK", 3000, 'success-snackbar');
        },
        (error: HttpErrorResponse) => {
          console.error(error);
          this.openSnackBar("Erro ao editar usuário!", "OK", 3000, 'danger-snackbar');
        }
      );
    }
  }

  getTeams() {
    this.userService.getTeams().subscribe(
      (times: Time[]) => (this.times = times),
      (err: HttpErrorResponse) => console.error(err)
    );
  }

  // getRoles() {
  //   this.userService.getRoles()
  //     .subscribe(
  //       (cargos: Cargo[]) => this.cargos = cargos,
  //       (err: HttpErrorResponse) => console.error(err)
  //     );
  // }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any) {
    const file = inputValue.files[0];
    const myReader = new FileReader();

    if (file.size > 1097152) {
      this.openSnackBar("O tamanho máximo permitido é de 1MB!", "OK", 5000, 'warning-snackbar');
      return false;
    } else if (
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      this.openSnackBar(
        "As extensões permitidas são: JPG, JPEG e PNG!", "OK", 5000, 'warning-snackbar');
      return false;
    } else {
      myReader.onloadend = (e) => {
        this.usuario.imagem = myReader.result.toString();
      };

      myReader.readAsDataURL(file);

      myReader.onload = () => {
        this.usuario.imagem = myReader.result.toString();
      };
    }
  }

  save(event) {
    event.preventDefault();
    if (this.isEditable) {
      this.editUser();
      this.isLoading = false;
      this.openSnackBar("Usuário editado com sucesso!", "OK", 3000, 'success-snackbar');
      this.dialog.closeAll();
    } else {
      this.createNewUser();
    }
  }

  onlyFirstName(name) {
    return name.replace(/ .*/, "");
  }

  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this.snackBar.open(message, action, { duration, panelClass });
  }

  redirecionarParaLinkExterno() {
    var state = "popup";

    let link = `${environment.jiraConfig.bashUrl}authorize`;
    link += `?audience=api.atlassian.com`;
    link += `&client_id=${environment.jiraConfig.clientId}`;
    link += `&scope=${encodeURIComponent(environment.jiraConfig.scope)}`;
    link += `&redirect_uri=${encodeURIComponent(
      environment.jiraConfig.redirectUri
    )}`;
    link += `&state=${encodeURIComponent(state)}`;
    link += `&response_type=code`;
    link += `&prompt=consent`;

    window.open(link, "_blank", "width: 300px; height: 300px");
  }

  @HostListener("window:message", ["$event"])
  onMessage(event: MessageEvent): void {
    if (event.origin === window.location.origin) {
      const objetoRecebido = event.data;
      this.usuario.emailJira = objetoRecebido.email;
      this.usuario.jiraAccountId = objetoRecebido.account_id;
    }
  }

  loginWithMicrosoft() {
    const loginRequest = {
      scopes: ["user.read"],
    };

    this.msAuthService.loginPopup(loginRequest).subscribe(
      (response) => {
        this.usuario.emailTFS = response.account.username;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
