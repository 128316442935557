import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs'; 
import { Fase } from '../models/fase';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class FaseService {

  constructor(private http: HttpClient) { }

	getFases(ativo?: boolean): Observable<Fase[]> {
		let params = new HttpParams();
    
    if (ativo != undefined && ativo != null && ativo.toString() != '') {
      params = params.append('Ativo', ativo.toString());
    }
    return this.http.get<Fase[]>("Fase/Listar", { params }).pipe(map(result => result));
  }


  getFaseById(id: number): Observable<any> {
    return this.http.get(`Fase/details/${id}`, httpOptions) as Observable<any>;
  }

  createFase(fase: Fase): Observable<Fase> {
    return this.http.post<Fase>(`Fase/create`, fase, httpOptions);
  }

  editFase(fase: Fase): Observable<Fase> {
    return this.http.put<Fase>(`Fase/edit`, fase, httpOptions);
  }

  deleteFase(fase: Fase): Observable<Fase> {
    return this.http.delete<Fase>(`Fase/delete/${fase.id}`, httpOptions);
  }

  // OLD
  getFase(): Observable<any> {
    return this.http.get(`fase/getFase`, httpOptions) as Observable<any>;
  }

  getSlug(): Observable<any> {
    return this.http.get(`fase/getSlug`, httpOptions) as Observable<any>;
  }
}
