import { Injectable, EventEmitter } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class TimerService {

  private play = false;
  private pause = false;
  private stop = true;
  public playPauseStop$ = new ReplaySubject<any>();

  public playTimer(task) {
    this.play = true;
    this.pause = false;
    this.stop = false;

    this.playPauseStop$.next({
      play: this.play,
      task: task
    });
  }

  public getValues() {
    return this.playPauseStop$.asObservable();
  }

  public pauseTimer(task) {
    this.play = false;
    this.pause = true;
    this.stop = false;

    this.playPauseStop$.next({
      pause: this.pause,
      task: task
    });
  }

  public stopTimer(task) {
    this.play = false;
    this.pause = false;
    this.stop = true;

    this.playPauseStop$.next({
      stop: this.stop,
      task: task
    });
  }
}
