import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ExpedienteDia, ExpedienteHorario } from 'src/app/models/expedientes';
import { Projeto } from 'src/app/models/projeto';
import { UsuarioService } from 'src/app/services/usuario.service';
@Component({
  selector: "app-expediente-planejamento",
  templateUrl: "./expediente-planejamento.component.html",
  styleUrls: ["./expediente-planejamento.component.scss"],
})
export class ExpedientePlanejamentoComponent implements OnInit {
  constructor(
    private datePipe: DatePipe,
    private _bottomSheetRef: MatBottomSheetRef<ExpedientePlanejamentoComponent>,
    private usuarioService: UsuarioService,
    public snackBar: MatSnackBar,

    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      expediente: ExpedienteDia;
      editing: ExpedienteHorario;
    }
  ) {

  }

  projetos: Projeto[] = [];
  projeto: Projeto;
  step = 1;
  inicioExpediente: ExpedienteHorario;
  descricaoExpediente: string;

  minDuration: string = "00:15";
  maxDuration: string = "08:00";
  duracaoHorario: string;

  horarios: Array<ExpedienteHorario> = [];

  newAdd: ExpedienteHorario;

  ngOnInit(): void {
    this.carregarProjetos();
    this.carregarHorarios();
  }

  async carregarProjetos() {
    await this.usuarioService.getProjetos().subscribe((projetos) => {
      this.projetos = projetos;
      if (this.data.editing) {
        this.projeto = this.projetos.filter(p => p.projetoId == this.data.editing.referenciaId)[0];
      }
    });
  }

  carregarHorarios() {
    this.horarios = this.data.expediente.horarios;

    if (!this.data.editing) {
      this.duracaoHorario = "01:00";
    } else {
      let dtCalculoDuracao = new Date(this.data.editing.dataInicio);
      dtCalculoDuracao.setHours(0, 0, 0, 0);
      dtCalculoDuracao.setMinutes(
        dtCalculoDuracao.getMinutes() + this.totalMinutes(this.data.editing)
      );

      this.duracaoHorario = this.datePipe.transform(dtCalculoDuracao, "HH:mm");

      this.maxDuration = this.maxDuracao(this.data.editing);
      this.descricaoExpediente = this.data.editing.descricaoExpediente;
      this.inicioExpediente = this.data.editing;
      console.log(this.maxDuration);

    }
  }

  selecionouProjeto(projeto) {
    this.projeto = projeto;
    this.step = 1;
  }

  changeItem() {
    let copia = this.data.editing
      ? this.data.editing
      : Object.assign({}, this.inicioExpediente);
    copia.referenciaId = this.projeto?.id;
    copia.descricaoExpediente = this.descricaoExpediente;

    let dtCalculo = this.obtemDatafim(copia);
    copia.dataFim = dtCalculo;
    copia.horarioFim = this.datePipe.transform(dtCalculo, "HH:mm");

    this.newAdd = copia;
  }

  proceed() {
    if (!this.inicioExpediente) {
      this.openSnackBar("Defina algum horário para iniciar o Expediente", "OK", 3000, 'warning-snackbar');
      return;
    }
    this.updateHorariosArray();

    let dataFimExpediente = new Date(this.inicioExpediente.dataInicio);
    dataFimExpediente = new Date(
      this.datePipe.transform(dataFimExpediente, "yyyy-MM-dd") +
      " " +
      this.data.expediente.fimExpediente
    );
    this.data.expediente.horarios = this.data.expediente.horarios.filter(
      (h) => new Date(h.dataInicio) < dataFimExpediente
    );

    this._bottomSheetRef.dismiss();
  }

  updateHorariosArray() {
    this.changeItem();
    if (!this.data.editing) {
      let _index = this.data.expediente.horarios.findIndex(
        (v) => v === this.inicioExpediente
      );
      this.data.expediente.horarios.splice(_index, 0, this.newAdd);
    } else {
      this.data.editing = this.newAdd;
    }
  }

  totalMinutes(horario: ExpedienteHorario) {
    if (!horario) return 0;
    return this.diff_minutes(
      new Date(horario.dataFim),
      new Date(horario.dataInicio)
    );
  }

  excluirRegistro() {
    this.data.expediente.horarios = this.data.expediente.horarios.filter(
      (h) => h !== this.data.editing
    );
    this.proceed();
  }

  maxDuracao(horario: ExpedienteHorario) {
    let dtCalculoDuracao = new Date(this.data.editing.dataInicio);
    let total = 0;
    dtCalculoDuracao.setHours(0, 0, 0, 0);
    if (this.data.editing.next) {
      total =
        this.diff_minutes(
          this.data.editing.next.dataFim,
          this.data.editing.dataInicio
        ) - 10;
    } else {
      total = this.totalMinutes(this.data.editing);
    }

    dtCalculoDuracao.setMinutes(dtCalculoDuracao.getMinutes() + total);
    return this.datePipe.transform(dtCalculoDuracao, "HH:mm");
  }

  obtemDatafim(horario: ExpedienteHorario) {
    let dtCalculo = new Date(horario.dataInicio);
    let dtFim = new Date(
      this.datePipe.transform(dtCalculo, "yyyy-MM-dd") +
      " " +
      this.duracaoHorario
    );
    let dtInicio = new Date(
      this.datePipe.transform(dtCalculo, "yyyy-MM-dd") + " 00:00"
    );

    dtCalculo.setMinutes(
      dtCalculo.getMinutes() + this.diff_minutes(dtFim, dtInicio)
    );
    return dtCalculo;
  }

  diff_minutes(dt2, dt1) {
    dt2 = new Date(dt2);
    dt1 = new Date(dt1);
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this.snackBar.open(message, action, { duration, panelClass });
  }
}
