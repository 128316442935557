import { Component, OnInit, Inject, AfterViewInit, AfterContentInit } from '@angular/core';
import { ClienteService } from 'src/app/services/cliente.service';
import { ProjetosService } from 'src/app/services/projetos.service';
import { ApontamentoService } from 'src/app/services/apontamento.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ApiAzureDevopsService,
  FiltroGetTasksDevOps,
  FiltroCreateOrEditTaskDevOps,
} from 'src/app/services/api-azure-devops.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { readWorkItemJsonValue } from 'src/app/services/shared.service';
// import * as BalloonEditor from 'src/ckeditor/build/ckeditor.js';


import * as ClassicEditor from 'ckeditor5-build-classic-base64-upload';
import { EditWorkitemComponent } from '../../work/edit-workitem/edit-workitem.component';
import { DevopsHelpers } from 'src/app/@helpers/devops.helpers';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ModalSelecaoTarefaComponent } from '../../board/devops-tasks/modal-selecao-tarefa/modal-selecao-tarefa.component';
import { ModalConfirmacaoComponent } from 'src/app/@modules/manage/clientes/components/modal-confirmacao/modal-confirmacao.component';

//import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-modal-apontamentos',
  templateUrl: './modal-apontamentos.component.html',
  styleUrls: ['./modal-apontamentos.component.scss']
})

export class ModalApontamentosComponent implements OnInit, AfterViewInit, AfterContentInit {

  selectedTaskControl = new FormControl();

  public Editor = ClassicEditor;
  //public Base64UploadAdapter = Base64UploadAdapter;

  user = StorageService.getUsuario().id;
  logon = StorageService.getUsuario().logon;
  idUsuario: any;
  idCliente: any;
  idProjeto: any;
  date: Date = new Date();
  endHour: any;
  startHour: any;
  isLoading: boolean;
  clients = [];
  projects = [];
  apontamentoForm: any;
  tarefaForm: any;
  selectedClient: any;
  isEdicao = false;
  isLancamentoRapido = false;
  idApontamento: number;
  referenciaId: number;
  taskTfs: any;
  observacoes: any;
  task: any;
  taskCombo: any[] = [];
  horaFim: any;

  idAzureDevops: number = null;
  title: any;
  taskOriginalEstimated: string;
  taskRemainingWork: number;
  taskTargetDate: Date;
  taskPriority: number;
  taskTags: string;
  taskBloqueada: boolean;
  taskMotivoBloqueio: string;
  taskStatus: string;

  blocked = false;
  blockedText: string;

  sprint: any;
  tasks: any;
  createTask: any;
  status = ['To Do', 'In Progress', 'Done'];
  concluida: boolean;
  idSprint: string;

  responsavelId = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clienteService: ClienteService,
    private projetosService: ProjetosService,
    private apontamentoService: ApontamentoService,
    private usuarioService: UsuarioService,
    private snackBar: MatSnackBar,
    public fb: FormBuilder,
    private dialog: MatDialog,
    private devops: ApiAzureDevopsService,
    private dialogRef: MatDialogRef<ModalApontamentosComponent>
  ) {

  }

  ngOnInit() {
    this.createForm();
    if (this.data != null) {

      if (this.data.parametros?.date != null) {
        this.date = new Date(this.data.parametros.date);
        this.getUltimoApontamento();
      }

      if (this.data.parametros?.task) {
        this.changeTask(this.data.parametros.task);
      } else {
        this.getTasks();
      }

      if (this.data.parametros?.id != null) {
        this.isEdicao = true;
        this.idApontamento = this.data.parametros.id;
        this.details();
        return;
      }

      if (this.data.preApontamento) {
        // const { horaInicio, concluida, observacoes } = data.preApontamento;
        // this.date = horaInicio;
        // this.concluida = concluida;
        // this.endHour = new Date().getHours().toString().padStart(2, '0') + ':' + new Date().getMinutes().toString().padStart(2, '0');
        this.preApontamento(this.data.preApontamento);
      }
      // this.getTasks();

      // if (this.data.parametros?.task) {
      //   this.changeTask(this.data.parametros.task);
      //   // Defina o valor inicial do FormControl aqui
      //   this.selectedTaskControl.setValue(this.data.parametros.task.title);
      // }
      // console.log('selectTask: ',this.task)
      // this.title = this.selectedTaskControl.value;

      // console.log('Valor Task: ',this.title);
    }

    // if (!this.isEdicao
    //   && !this.isLancamentoRapido
    //   && this.data.tela != 'timer'
    //   && this.date
    //   && !this.data.parametros.task) {
    //   console.log("entrou");
    //   this.getUltimoApontamento();
    // }
  }
  iconUrl(task) {
    return DevopsHelpers.iconUrl(task);
  }

  itemUrl(task) {
    return DevopsHelpers.itemUrl(task);
  }

  itemParentUrl(task) {
    return DevopsHelpers.itemParentUrl(task);
  }


  changeTask(task) {
    this.setCustomValidators();
    this.task = task;
    this.idAzureDevops = task.idAzureDevops;

    this.title = this.task.title;
    this.observacoes = this.observacoes ?? this.readField(this.task.jSon, "System.Description");
    this.taskOriginalEstimated = this.task.originalEstimate;
    this.taskRemainingWork = this.task.remainingWork;
    this.taskTargetDate = this.task.targetDate;
    this.taskPriority = this.task.priority;
    this.taskStatus = this.task.status;
    this.taskBloqueada = this.task.bloqueada;
    this.taskMotivoBloqueio = this.task.motivoBloqueio;
    this.taskTags = this.task.tags;
    this.responsavelId = this.task.idUsuario ?? this.task.responsavelId;

    if (this.task.dataInicio) {
      this.date = new Date(this.task.dataInicio);

      this.startHour = this.task.horarioInicio.substr(0, 5);
      this.endHour = this.task.horarioFim.substr(0, 5);
    }

    if (this.task.referenciaId) {
      this.idProjeto = this.task.referenciaId;
    }
    // else {
    //   this.getClient();
    // }

    this.tarefaForm.updateValueAndValidity();
  }


  ngAfterViewInit() {
    this.validHour();
    setTimeout(() => {
      this.autoResize();
    }, 200);
  }

  ngAfterContentInit() {
    this.setCustomValidators();
  }

  readField(jsonString, fieldName) {
    if (jsonString)
      return JSON.parse(jsonString)["Fields"][fieldName];
    else return null;
  }

  async preApontamento(task: any) {
    this.isLoading = true;
    const retorno = task;
    if (retorno) {

      const { idAzureDevops, idUsuario, idCliente, idProjeto, dataInicio, horaInicio, descricao, concluida, idSprint } = await retorno;

      this.concluida = concluida;
      this.idUsuario = idUsuario;
      this.idAzureDevops = idAzureDevops;
      this.idCliente = idCliente;
      this.idProjeto = idProjeto;
      this.date = new Date(dataInicio);
      this.startHour = horaInicio.substr(0, 5);
      this.endHour = new Date().getHours().toString().padStart(2, '0') + ':' + new Date().getMinutes().toString().padStart(2, '0');
      this.observacoes = descricao;
      this.idSprint = idSprint;
      //      this.getSprint();
      if (this.idProjeto) {
        await this.changeClient(this.idCliente, this.idProjeto);
      }
    }
    this.isLoading = false;
  }

  changeProjeto(idProjeto) {
    this.getTasks();
  }

  createForm() {
    this.apontamentoForm = this.fb.group({
      client: [''],
      project: ['', []],
      date: [new Date(), [Validators.required]],
      startHour: ['', [Validators.required]],
      endHour: ['', [Validators.required]],
      obs: [''],
      idAzureDevops: ['']
    });

    this.tarefaForm = this.fb.group({
      title: [{ value: this.title, disabled: true }],
      status: [this.status[0]],
      tags: [''],
      estimateHour: [{ value: '', disabled: true }],
      remainingWork: [{ value: '' }],
      priority: [{ value: '' }],
      targetDate: [{ value: '' }],
      isBlocked: [''],
      blockedText: [null]
    });
  }

  getClient(): any {
    this.isLoading = true;
    this.clienteService.getClients(true)
      .subscribe(response => {
        this.clients = response;
        this.isLoading = false;
      },
        (error: HttpErrorResponse) => console.error(error.message));
  }

  getUltimoApontamento() {
    this.isLoading = true;
    this.apontamentoService.getUltimoApontamento(this.date, null)
      .subscribe(response => {
        if (response) {
          this.startHour = response.horaFim;
        }
        this.isLoading = false;
      },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.openSnackBar('Erro, tente novamente!', 'OK', 5000, 'danger-snackbar');
          console.error(error.message);
        });
  }

  async changeClient(id, referenciaId?) {
    this.usuarioService.getProjetos(id)
      .subscribe(async response => {
        this.projects = response;

        // iniciar o campo projeto com o primeiro registro
        const fullArray = this.projects.filter(projects => {
          return projects != undefined;
        });

        if (referenciaId === null || referenciaId === undefined) {
          setTimeout(() => {
            this.idProjeto = fullArray[0].id;
            this.isLoading = false;
          }, 5);
        }

        if (referenciaId != null) {
          setTimeout(() => {
            this.idProjeto = referenciaId;
            this.isLoading = false;
          }, 5);
        }

        if (this.projects.length === 0) {
          this.openSnackBar('Este cliente não possui projetos associados.', 'OK', 5000, 'warning-snackbar');
        }
      });
  }

  salvarApt(event) {
    event.preventDefault();
    if (this.isEdicao) {
      this.editApt();
    } else {
      this.createUpdateApt();
    }
  }

  salvarTask(event) {
    event.preventDefault();
    if (this.isEdicao) {
      this.editTask();
    } else {
      this.createUpdateTask();
    }
  }

  disableButtonTask(): boolean {

    return ((this.tarefaForm.invalid || this.isLoading)
      || (this.isLoading || this.idAzureDevops == null));

  }

  disableButtonApt(): boolean {
    return this.apontamentoForm.invalid || this.isLoading;

  }

  createUpdateApt() {
    this.isLoading = true;
    const date: Date = new Date(this.date);
    const obj: object = {
      referenciaId: this.idProjeto,
      data: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
      // tslint:disable-next-line: max-line-length
      horaInicio: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T' + this.startHour.substr(0, 2) + ':' + this.startHour.substr(this.startHour.includes(':') ? 3 : 2, 2) + ':00.000Z',
      // tslint:disable-next-line: max-line-length
      horaFim: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T' + this.endHour.substr(0, 2) + ':' + this.endHour.substr(this.endHour.includes(':') ? 3 : 2, 2) + ':00.000Z',
      observacoes: this.observacoes,
      concluida: this.concluida,
      idAzureDevops: this.idAzureDevops == 0 ? null : this.idAzureDevops
    };
    this.apontamentoService.create(obj)
      .subscribe(response => {
        this.dialogRef.close(this.idAzureDevops);
        this.isLoading = false;
        this.openSnackBar('Sucesso, apontamento efetuado!', 'OK', 5000, 'success-snackbar');
      },
        (error: HttpErrorResponse) => {
          console.error(error.message);
          this.isLoading = false;
          this.openSnackBar('Tente novamente!' + error.error.errorMessage, 'OK', 5000, 'warning-snackbar');
        });

  }

  createUpdateTask() {
    this.isLoading = true;

    const filtro: FiltroCreateOrEditTaskDevOps = {
      id: this.idAzureDevops,
      // idSprint: this.sprint.id,
      // sprintName: this.sprint.name,
      // iterationPath: this.sprint.iterationPath,
      // idParent: 5596, // TODO- CRIAR REGRA DE APONTAMENTO PARENT
      title: this.title,
      originalEstimate: this.tarefaForm.get('estimateHour').value,
      targetDate: this.tarefaForm.get('targetDate').value,
      priority: this.tarefaForm.get('priority').value,
      remainingWork: this.tarefaForm.get('remainingWork').value,
      tags: this.tarefaForm.get('tags').value,
      // type: 'Task',
      status: this.tarefaForm.get('status').value,
      bloqueada: this.tarefaForm.get('isBlocked').value ? true : false,
      concluida: this.concluida == true
        ? this.concluida
        : this.tarefaForm.get('status').value === 'Done'
          ? true
          : false,
      motivoBloqueio: this.tarefaForm.get('blockedText').value,
      responsavelId: this.responsavelId
    };
    // console.log(filtro);
    this.devops.editSprintTask(filtro).subscribe(x => {
      //this.dialogRef.close(true);
      this.isLoading = false;
      this.openSnackBar('Tarefa alterada com sucesso!', 'OK', 5000, 'success-snackbar');
      //this.tasks.push(x);
    },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.openSnackBar('Erro ao alterar tarefa!', 'OK', 5000, 'danger-snackbar');
        this.isLoading = false;
      });
  }

  details() {
    this.isLoading = true;
    this.apontamentoService.details(this.idApontamento)
      .subscribe(response => {
        console.log(response);
        this.idCliente = response.referencia?.clienteId;
        this.idProjeto = response.referencia?.id;
        this.observacoes = response.observacoes;
        this.startHour = response.horaInicio;
        this.endHour = response.horaFim;
        this.date = new Date(response.data);

        if (response.taskAzureDevops !== null) {
          this.idAzureDevops = response.idAzureDevops;
          this.changeTask(response.taskAzureDevops);
        } else if (response.referencia) {
          this.changeClient(response.referencia.clienteId, response.referencia.id);
          //this.getSprint();
          this.getClient();
        }

        this.isLoading = false;
      },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.openSnackBar('Tente novamente!', 'OK', 5000, 'warning-snackbar');
          console.error(error.message);
        });
  }

  editTask() {
    this.isLoading = true;

    const filtro: FiltroCreateOrEditTaskDevOps = {
      id: this.idAzureDevops,
      title: this.title,
      originalEstimate: this.tarefaForm.get('estimateHour').value,
      remainingWork: this.tarefaForm.get('remainingWork').value,
      targetDate: this.tarefaForm.get('targetDate').value,
      priority: this.tarefaForm.get('priority').value,
      tags: this.tarefaForm.get('tags').value,
      //type: 'Task',
      status: this.tarefaForm.get('status').value,
      bloqueada: this.tarefaForm.get('isBlocked').value ? true : false,
      concluida: this.concluida == true
        ? this.concluida
        : this.tarefaForm.get('status').value === 'Done'
          ? true
          : false,
      motivoBloqueio: this.tarefaForm.get('blockedText').value,
      responsavelId: this.responsavelId
    };
    // console.log(filtro);
    this.devops.editSprintTask(filtro).subscribe(x => {
      //this.dialogRef.close(true);
      this.isLoading = false;
      this.openSnackBar('Tarefa alterada com sucesso!', 'OK', 5000, 'success-snackbar');
      //this.tasks.push(x);
    },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.openSnackBar('Erro ao alterar tarefa!', 'OK', 5000, 'danger-snackbar');
        this.isLoading = false;
      });
  }

  editApt() {
    this.isLoading = true;
    const date: Date = new Date(this.date);
    const obj: object = {
      id: this.idApontamento,
      referenciaId: this.idProjeto,
      data: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
      // tslint:disable-next-line: max-line-length
      horaInicio: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T' + this.startHour.substr(0, 2) + ':' + this.startHour.substr(this.startHour.includes(':') ? 3 : 2, 2) + ':00.000Z',
      // tslint:disable-next-line: max-line-length
      horaFim: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T' + this.endHour.substr(0, 2) + ':' + this.endHour.substr(this.endHour.includes(':') ? 3 : 2, 2) + ':00.000Z',
      observacoes: this.observacoes,
      idAzureDevops: this.idAzureDevops == 0 ? null : this.idAzureDevops
    };
    this.apontamentoService.edit(obj)
      .subscribe(() => {
        this.dialogRef.close(this.idAzureDevops);
        this.isLoading = false;
        this.openSnackBar('Apontamento alterado com sucesso!', 'OK', 5000, 'success-snackbar');
      },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.openSnackBar('Erro, tente novamente!' + error.error.errorMessage, 'OK', 5000, 'danger-snackbar');
          console.error(error.message);
        });


  }

  deletar(event) {
    this.isLoading = true;

    const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
      width: '450px',
      data: { mensagem: 'Deseja realmente excluir este apontamento?' }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (response != 'cancel') {
          this.apontamentoService.delete(this.idApontamento)
            .subscribe(() => {
              this.dialogRef.close(this.idAzureDevops);
              this.isLoading = false;
              this.openSnackBar('Sucesso, apontamento deletado!', 'OK', 5000, 'success-snackbar');
            },
              (error: HttpErrorResponse) => {
                this.isLoading = false;
                this.openSnackBar('Erro, tente novamente!', 'OK', 5000, 'danger-snackbar');
                console.error(error.message);
              });
        } else
          this.isLoading = false;

      });


  }

  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this.snackBar.open(message, action, { duration, panelClass });
  }

  autoResize() {
    const autoResize = document.querySelector('[data-autoresize]') as HTMLInputElement;

    if (autoResize) {
      autoResize.style.boxSizing = 'border-box';
      const offset = autoResize.offsetHeight - autoResize.clientHeight;

      autoResize.addEventListener('input', (event: HTMLInputEvent) => {
        event.target.style.height = 'auto';
        event.target.style.height = event.target.scrollHeight + offset + 'px';
      });

      autoResize.removeAttribute('data-autoresize');
    }
  }

  almoco() {
    this.idCliente = 1;
    this.changeClient(this.idCliente, 1);
    this.observacoes = 'Almoço';
  }

  setCustomValidators() {
    this.tarefaForm.get('isBlocked').valueChanges
      .subscribe(checked => {
        if (checked) {
          this.tarefaForm.get('blockedText').setValidators([Validators.required]);

          if (!this.tarefaForm.get('tags').value.includes('#BLOCKED;')) {
            this.tarefaForm.get('tags').setValue(`#BLOCKED; ${this.taskTags}`);
            this.tarefaForm.get('tags').updateValueAndValidity();
          }
        } else {
          if (this.tarefaForm.get('tags').value && this.tarefaForm.get('tags').value.includes('#BLOCKED;')) {
            const block = this.tarefaForm.get('tags').value.replace('#BLOCKED; ', '');
            this.tarefaForm.get('tags').setValue(block);
          }
          this.tarefaForm.get('blockedText').setValidators(null);
          this.tarefaForm.get('tags').updateValueAndValidity();
          this.tarefaForm.get('blockedText').setValue(null);
          this.tarefaForm.get('blockedText').updateValueAndValidity();
        }
        this.tarefaForm.get('blockedText').updateValueAndValidity();
      });
  }

  updateSprint() {
    this.getTasks();
  }

  getTasks() {
    const filtro: FiltroGetTasksDevOps = {
      dataRef: this.date,
      types: ["Task", "Bug", 'Ticket'],
      referenciaId: this.idProjeto,
      userId: this.responsavelId
    };

    this.devops.getTasks(filtro).subscribe(x => {
      x = x ? x : [];
      this.tasks = x;
      if (this.data.parametros.task)
        this.task = x.find((y) => y.id === this.data.parametros.task?.id);

      this.isLoading = false;
    });
  }

  selectTask() {
    const dialogRef = this.dialog.open(ModalSelecaoTarefaComponent, {
      disableClose: true,
      width: '740px',
      data: { tela: 'timer', dataRef: this.data, responsavelId: this.responsavelId }
    });

    dialogRef.afterClosed()
      .subscribe(async retorno => {
        if (retorno != 'cancel') {
          this.task = retorno;
          //await this.getWorkItems();
        }
      });
  }

  getTask(idAzureDevops) {
    this.devops.getTaskById(idAzureDevops).subscribe(tarefa => {
      this.changeTask(tarefa);
      console.log(tarefa);
      this.isLoading = false;
    },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.openSnackBar('Erro ao consultar tarefa!', 'OK', 5000, 'danger-snackbar');
        this.dialogRef.close();
      });
  }

  openEditTask(task) {
    const dialogRef = this.dialog.open(EditWorkitemComponent, {
      disableClose: true,
      width: '100%',
      data: {
        task: task
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result != 'cancel') {
        result.sprintName = this.task.sprintName;
        result.tag = this.task.tag;
        this.changeTask(result);
      }
    });
  }


  validHour() {
    // if (this.startHour > this.endHour) {
    //   this.openSnackBar('Hora de início não pode ser maior que a hora fim!', 'OK', 5000);
    //   this.apontamentoForm.get('endHour').setErrors({ invalidHour: true });
    //   this.apontamentoForm.get('endHour').setValidators([Validators.required]);
    //   this.apontamentoForm.updateValueAndValidity();
    //   return false;
    // } else {
    //   this.apontamentoForm.get('endHour').setErrors(null);
    this.apontamentoForm.updateValueAndValidity();

    if (this.endHour == '' || this.endHour == null) {
      this.apontamentoForm.get('endHour').setErrors({ required: true });
      this.apontamentoForm.updateValueAndValidity();
    }
    return true;
    // }

  }
  bgColor(status) {
    switch (status) {
      case 'New': return 'rgb(178, 11, 30)';
      case 'To Do': return '#5199FF';
      case 'In Progress': return '#F3A712';
      case 'Commited': return '#939598';
      case 'Done': return '#00CF91';
      default: return 'gainsboro';
    }
  }
}
