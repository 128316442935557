import { Component, OnInit } from '@angular/core';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';
import { MenuItemsService } from '../../../services/menu-items.service';
import { AuthService } from '../../../services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { Menu } from 'src/app/models/menu';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [{ provide: TooltipConfig, useFactory: getAlertConfig }]
})
export class SidebarComponent implements OnInit {
  panelOpenState = false;
  customCollapsedHeight = '48px';
  customExpandedHeight = '48px';
  collapsed = true;
  isExpanded = true;
  showFiller = false;
  collapsedClass = '';
  icon = '';

  menuItems = [];
  userPermissions = [];
  constructor(
    public menu: MenuItemsService,
    public authService: AuthService,
    private matIconRegistry: MatIconRegistry
  ) {
    matIconRegistry.registerFontClassAlias('fas');
  }

  ngOnInit() {
    this.getUserPermissions();
    this.toggleExpanded();
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
    this.icon = this.isExpanded ? 'fas fa-angle-double-right' : 'fas fa-angle-double-left';

    if (this.isExpanded) {
      this.collapsedClass = 'collapsed';
    } else {
      this.collapsedClass = '';
    }
  }

  private getUserPermissions() {
    this.menu.getMenus().subscribe(
      (response: Menu[]) => this.menuItems = response,
      (error: HttpErrorResponse) => {
        console.error(error);
      });
  }
}

export function getAlertConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    placement: 'right',
    container: 'body'
  });
}
