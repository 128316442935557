<div class="custom-modal">
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="arastavel">
    <div class="header">
      <h2 mat-dialog-title>
        Cadastro de Empresa
      </h2>
      <mat-icon mat-dialog-close="cancel">close</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-progress-bar [hidden]="!isLoading" class="example-margin" color="primary" mode="indeterminate">
  </mat-progress-bar>
  <div mat-dialog-content class="content">
    <form [formGroup]="form">
      <mat-form-field>
        <mat-label>Razão Social:</mat-label>
        <input matInput placeholder="Razão Social" (blur)="validateRazaoSocial()" name="razaoSocial" formControlName="razaoSocial">
        <mat-error *ngIf="form.get('razaoSocial').errors?.required">
          <small>O campo RAZÃO SOCIAL é obrigatório.</small>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nome Fantasia:</mat-label>
        <input matInput placeholder="Nome Fantasia" name="nomeFantasia" formControlName="nomeFantasia">
        <mat-error *ngIf="form.get('nomeFantasia').errors?.required">
          <small>O campo NOME FANTASIA é obrigatório.</small>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>CNPJ:</mat-label>
        <input matInput placeholder="CNPJ" (blur)="validateCNPJ()" mask="00.000.000/0000-00" name="cnpj" formControlName="cnpj">
        <mat-error *ngIf="form.get('cnpj').errors?.required">
          <small>O campo CNPJ é obrigatório.</small>
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="modal-actions" mat-dialog-actions align="end">
    <button mat-flat-button [disabled]="form.invalid || isLoading" (click)="save($event)"
      class="colorsave">{{isEditable === true ? 'Salvar' : 'Cadastrar'}}</button>
  </div>
</div>
