import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ColaboradorOutput, EditColaboradorRequest, InviteRequest, InvitesOptionsRequest } from "../models/colaboradores.model";
import { map } from "rxjs/operators";
import { ConviteModel } from "src/app/models/usuario";
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: "root",
})
export class ColaboradorService {
  constructor(private http: HttpClient) { }

  public countAdministradoresGerais(): Observable<number> {
    return this.getColaboradoresEmpresa(true).pipe(
      map((colaboradores) => {
        return colaboradores.filter((colaborador) => colaborador.perfilAcessoId === 1).length;
      })
    );
  }

  public getProfiles(empresaId = null): Observable<any> {
    return this.http.get(`PerfilAcesso/ListarPerfils/${empresaId ?? ''}`, httpOptions);
  }

  public getColaboradoresEmpresa(ativo: boolean): Observable<ColaboradorOutput[]> {
    return this.http.get<ColaboradorOutput[]>(`usuario/colaboradores?ativo=${ativo == null ? '' : ativo}`, httpOptions);
  }

  public getColaboradorEmpresa(id: number): Observable<ColaboradorOutput> {
    return this.http.get<ColaboradorOutput>(`usuario/colaborador/${id}`, httpOptions);
  }

  public getTipoContratacao(empresaId = null): Observable<any> {
    return this.http.get(`PerfilAcesso/ListarTipoContratacao/${empresaId ?? ''}`, httpOptions);
  }

  public getPendingInvites(): Observable<HttpResponse<any>> {
    return this.http.get("convite/colaborador/pendentes", {
      observe: "response",
    });
  }

  public resendInvite(obj: InvitesOptionsRequest): Observable<HttpResponse<any>> {
    return this.http.post("convite/colaborador/reenviar", obj, {
      observe: "response",
    });
  }

  public cancelInvite(obj: InvitesOptionsRequest): Observable<HttpResponse<any>> {
    return this.http.post("convite/colaborador/cancelar", obj, {
      observe: "response",
    });
  }

  public efetivar(identificador: string): Observable<HttpResponse<any>> {
    return this.http.post("convite/colaborador/efetivar", {
      identificador: identificador
    }, {
      observe: "response",
    });
  }

  public sendInvite(obj: InviteRequest): Observable<HttpResponse<any>> {
    return this.http.post("convite/colaborador", obj, {
      observe: "response",
    });
  }


  public editContributors(obj: EditColaboradorRequest): Observable<HttpResponse<ColaboradorOutput>> {
    return this.http.put<ColaboradorOutput>("usuario/colaborador", obj, {
      observe: "response",
    });
  }

  public details(identificador: string): Observable<HttpResponse<ConviteModel>> {
    return this.http.get<ConviteModel>(`convite/colaborador/${identificador}`, {
      observe: "response",
    });
  }

}
