import { readWorkItemJsonValue } from "../services/shared.service";
import { Injectable } from '@angular/core';

enum ProjectManagementSoftware {
    AzureDevops = 1,
    Jira = 2
}

@Injectable()
export class DevopsHelpers {
    constructor() {
    }

    private static GetBaseUrl(url) {
        var pathArray = url.split('/');
        var protocol = pathArray[0];
        var host = pathArray[2];
        return protocol + '//' + host;
    }

    private static GetIconJira(task) {
        let baseUrl = task.tenantUrl;
        if (!baseUrl && !task.jSon) return "";

        baseUrl = this.GetBaseUrl(baseUrl);

        return `${baseUrl}/images/jira-safari-pintab-icon.svg`;
    }

    public static GetIconAzure(task?) {
        return "https://cdn.vsassets.io/content/icons/favicon.ico";
    }

    public static iconUrl(task) {
        var type: ProjectManagementSoftware = task.idProjectManagementSoftware;
        switch (type) {
            case ProjectManagementSoftware.Jira: return this.GetIconJira(task);
            case ProjectManagementSoftware.AzureDevops: return this.GetIconAzure(task);
            default: case ProjectManagementSoftware.AzureDevops: return this.GetIconAzure(task);
        }
    }

    public static itemUrl(task) {
        var type: ProjectManagementSoftware = task.idProjectManagementSoftware;
        switch (type) {
            case ProjectManagementSoftware.Jira: return this.JiraItemUrl(task);
            case ProjectManagementSoftware.AzureDevops: return this.AzureItemUrl(task);
            default: case ProjectManagementSoftware.AzureDevops: return this.AzureItemUrl(task);
        }
    }

    public static itemParentUrl(task) {
        var type: ProjectManagementSoftware = task.idProjectManagementSoftware;
        switch (type) {
            case ProjectManagementSoftware.Jira: return this.JiraitemParentUrl(task);
            case ProjectManagementSoftware.AzureDevops: return this.AzureitemParentUrl(task);
            default: case ProjectManagementSoftware.AzureDevops: return this.AzureitemParentUrl(task);
        }
    }

    private static AzureItemUrl(task) {
        let baseUrl = task.tenantUrl;
        if (!baseUrl && !task.jSon) return "";

        if (!baseUrl) {
            var api_url = readWorkItemJsonValue(task.jSon, "Url");
            var organization = api_url.split('/')[3];
            baseUrl = `https://dev.azure.com/${organization}`;
        }
        return `${baseUrl}/_workitems/edit/${task.idWorkItem}`;
    }

    private static JiraItemUrl(task) {
        let baseUrl = task.tenantUrl;
        if (!baseUrl && !task.jSon) return "";

        baseUrl = this.GetBaseUrl(baseUrl);
        var key = readWorkItemJsonValue(task.jSon, "Key");

        return `${baseUrl}/browse/${key}`;
    }

    private static AzureitemParentUrl(task) {
        let baseUrl = task.tenantUrl;
        if (!baseUrl && !task.jSon) return "";

        if (!baseUrl) {
            var api_url = readWorkItemJsonValue(task.jSon, "Url");
            var organization = api_url.split('/')[3];
            baseUrl = `https://dev.azure.com/${organization}`;
        }
        return `${baseUrl}/_workitems/edit/${task.idParent}`;
    }

    private static JiraitemParentUrl(task) {
        let baseUrl = task.tenantUrl;
        if (!baseUrl && !task.jSon) return "";

        baseUrl = this.GetBaseUrl(baseUrl);
        var parent = readWorkItemJsonValue(task.jSon, "Fields")["Parent"];
        if (parent) {
            var key = parent["Key"];
            return `${baseUrl}/browse/${key}`;
        } else {
            return '';
        }
    }
}
