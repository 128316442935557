<div id="wrapper">
    <div id="sidebarContent" class="sidebar-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  

<!-- <app-header></app-header> 
<main>
    <div *ngIf="isLoading" class="d-flex flex-column justify-content-center align-items-center"
        style="height: 100vh;width: 100vw;">
        <mat-progress-spinner color="primary" mode="indeterminate" class="m-3"></mat-progress-spinner>
    </div>
    <div *ngIf="!isLoading" class="container-fluid">
        <div class="row">
            <div class="col-lg-2 p-0">
                <div class="user-side">
                    <div class="user-side--content">
                        <div class="hr-text">
                            <span class="reception">Olá, <br /> bem vindo ao Corp</span>
                        </div>
                         <div class="main-nav" *ngIf="menuItems?.length">
                            <div *ngFor="let item of menuItems">
                                <button [routerLink]="item.url" [class]='{"active" : isActive(item)}'>
                                    {{item.nomeExibicao}}
                                </button>
                            </div>
                            <div *ngIf="menuItems?.length == 1 && !this.empresas?.length">
                                <button (click)="cadastrarEmpresa()">
                                    Empresa
                                </button>
                            </div>
                            <div *ngIf="menuItems?.length == 1 && this.empresas?.length">
                                <button routerLink="/manage">
                                    Empresa
                                </button>
                            </div>
                        </div> 
                    </div>

                    <div class="mat-sub-nav-wrapper">
                        <app-menu-nav class="sub-nav-nav" *ngIf="currentMenu?.length"
                            [menuItems]="currentMenu"></app-menu-nav>
                    </div>
                     <div class="hr-text">
                    <span>Ou</span>
                </div>
                <mat-selection-list #working>
                    <mat-list-option *ngFor="let empresa of empresas">
                        {{empresa.nomeFantasia}}
                    </mat-list-option>
                </mat-selection-list> 
                </div>
            </div>
            <div class="col-lg-10 p-0">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</main>

<div class="sidebar-content">
    <router-outlet></router-outlet>
</div> -->
