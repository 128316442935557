<div class="custom-modal">
    <div class="header">
        <h2 mat-dialog-title>
            Vínculo efetivado
        </h2>
        <mat-icon mat-dialog-close="cancel">close</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div mat-dialog-content>
        <div class="text-center">
            <p class="lead">Parabéns {{data.nomeConvidado}}!</p>
            <img src="/assets/images/convite-aceito.png" />
            <p>Você aceitou o convite de <strong>{{data.nomeEmpresa}}</strong> e agora faz parte do seu quadro de membros.</p>
        </div>
    </div>
    <div class="float-right" mat-dialog-actions>
        <button mat-flat-button mat-dialog-close color="primary" (click)="confirm()">{{textoOk}}</button>
    </div>
</div>