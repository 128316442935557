import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cliente } from '../models/cliente';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private http: HttpClient) { }

  getClients(ativo?: boolean): Observable<Cliente[]> {
    return this.http.get<Cliente[]>(`Cliente/listar/${ativo ? 'true' : 'false'}`, httpOptions);
  }

  getClientById(id: number): Observable<any> {
    return this.http.get(`Cliente/details/${id}`, httpOptions) as Observable<any>;
  }

  createClient(client: Cliente): Observable<Cliente> {
    return this.http.post<Cliente>(`Cliente/create`, client, httpOptions);
  }

  editClient(client: Cliente): Observable<Cliente> {
    return this.http.put<Cliente>(`Cliente/edit`, client, httpOptions);
  }

  deleteClient(client: Cliente): Observable<Cliente> {
    return this.http.delete<Cliente>(`Cliente/delete/${client.id}`, httpOptions);
  }

  // OLD
  getCliente(): Observable<any> {
    return this.http.get(`cliente/getCliente`, httpOptions) as Observable<any>;
  }

  getSlug(): Observable<any> {
    return this.http.get(`cliente/getSlug`, httpOptions) as Observable<any>;
  }
}
