<div class="custom-modal">
  <div class="header">
    <h2 mat-dialog-title>Alterar Workitem</h2>
    <mat-icon mat-dialog-close="cancel">close</mat-icon>
  </div>
  <mat-divider class="mb-0"></mat-divider>
  <mat-progress-bar mode="indeterminate" [hidden]="!isLoading" color="primary"></mat-progress-bar>
  <div mat-dialog-content class="content apontamento" *ngIf="!isLoading">
    <form class="form-tarefa" [formGroup]="tarefaForm">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <mat-card *ngIf="this.task"
              class="card-tfs slide d-flex justify-content-center align-items-center text-selection-none">
              <div class="barra-lateral"
                [ngStyle]="{ 'margin' :0, 'background-color': bgColor(task.status), 'opacity' : '0.8'}">
              </div>
              <mat-card-content class="tarefas-container text-center card-content-tfs">
                <div class="tarefa-item">
                  <div class="tarefa-item-actions">
                    <div class="remaining-work">
                      <small>{{task.type}}</small>
                    </div>
                  </div>
                  <div class="tarefa-item-content">
                    <!-- <p class="line-1">{{task.originalEstimate}}hrs - {{task.completedWork}}hrs = {{task.remaining|Work}}</p> -->
                    <p class="line-3">
                      <a [href]="itemParentUrl(task)" target="_blank" matTooltip="Abrir no board">
                        <strong>{{task.parentTitle ? task.parentTitle: task.referenciaDescricao}}</strong>
                      </a>
                    </p>

                    <div class="d-flex" style="align-items: center;flex-grow: 1;">
                      <p class="line-2" [matTooltip]="task.title">
                        <a [href]="itemUrl(task)" target="_blank" matTooltip="Abrir no board">
                          <img [src]="iconUrl(task)" class="icon-board" />
                          <span>{{task.title}}</span>
                        </a>
                      </p>

                    </div>

                    <p class="line-1">
                      <small>{{task.sprintName}}</small>
                      <small>#{{task.tag}}</small>
                    </p>
                  </div>
                  <div class="tarefa-item-actions">
                    <div>
                      <mat-form-field class="no-margin" floatLabel="always" appearance="none"
                        style="flex:0.25;width:60px;text-align: center;height: 40px;">
                        <mat-label [ngStyle]="{'color': taskBloqueada ? 'white' : ''}">Bloqueada</mat-label>
                        <mat-slide-toggle [disabled]="isLoading" #isBlocked color="primary" formControlName="isBlocked"
                          class="ml-3" [(ngModel)]="taskBloqueada">
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row align-items-start">
          <div class="col-lg-6" style="max-height: 50vh;overflow: auto;">
            <div>
              <mat-form-field>
                <mat-label>Título</mat-label>
                <input matInput placeholder="Título" name="titulo" formControlName="title" [(ngModel)]="title">
              </mat-form-field>
            </div>
            <div>
              <mat-label>Descrição:</mat-label>
              <ckeditor [editor]="EditorDescription" formControlName="description" data-autoresize> </ckeditor>
            </div>
            <div>
              <mat-label>Critérios de Aceite:</mat-label>
              <ckeditor  [editor]="EditorAcept" formControlName="acceptance" data-autoresize>
              </ckeditor>
            </div>
            <div>
              <mat-label>Repro Steps:</mat-label>
              <ckeditor [editor]="EditorRepro" formControlName="reproSteps" data-autoresize>
              </ckeditor>
            </div>
          </div>
          <div class="col-lg-6" style="max-height: 50vh;overflow: auto;">
            <div class="d-flex align-items-end">
              <div style="flex-grow: 1;">
                <mat-form-field>
                  <mat-label>Status</mat-label>
                  <mat-select matNativeControl [disabled]="isLoading" formControlName="status" [(ngModel)]="taskStatus">
                    <mat-option disabled value="">Status da Task</mat-option>
                    <mat-option *ngFor="let status of status" [value]="status">
                      {{ status }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div style="width:125px">
                <mat-form-field>
                  <mat-label>Data de Target</mat-label>
                  <input matInput [disabled]="isLoading" [matDatepicker]="targetDateTarefa" formControlName="targetDate"
                    placeholder="Data" [(ngModel)]="taskTargetDate">
                  <mat-datepicker-toggle matSuffix [for]="targetDateTarefa"></mat-datepicker-toggle>
                  <mat-datepicker #targetDateTarefa></mat-datepicker>
                </mat-form-field>
              </div>
              <div style="width:65px">
                <mat-form-field>
                  <mat-label>Prioridade</mat-label>
                  <input matInput formControlName="priority" placeholder="Prioridade" type="number"
                    [(ngModel)]="taskPriority" max="20" min="0">
                </mat-form-field>
              </div>

              <div style="width:65px">
                <mat-form-field appearance="legacy">
                  <input matInput formControlName="remainingWork" placeholder="Remaining" type="number"
                    [(ngModel)]="taskRemainingWork">
                </mat-form-field>
              </div>

              <div style="width:65px">
                <mat-form-field appearance="legacy">
                  <input matInput disabled formControlName="estimateHour" placeholder="Original"
                    [(ngModel)]="taskOriginalEstimated">
                </mat-form-field>
              </div>

            </div>
            <div>
              <mat-form-field *ngIf="taskBloqueada" style="flex: 1;">
                <mat-label>Motivo do bloqueio</mat-label>
                <textarea matInput placeholder="Motivo do bloqueio" [disabled]="isLoading" formControlName="blockedText"
                  [(ngModel)]="taskMotivoBloqueio"></textarea>
                <mat-error *ngIf="tarefaForm.get('blockedText').errors?.required">
                  Obrigatório informar o motivo do bloqueio!
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <mat-label>Tags</mat-label>
                <input matInput [disabled]="isLoading" placeholder="Tags" name="tag" formControlName="tags"
                  [(ngModel)]="taskTags">
                <mat-hint>Separar as tags por ;</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>

  </div>
  <div class="modal-actions d-flex" [ngClass]="'justify-content-end'" mat-dialog-actions>
    
    <button class="text-white" mat-raised-button color="primary" (click)="salvarTask($event)"
      [disabled]="disableButtonTask()">Salvar Tarefa
    </button>
  </div>
</div>