import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from '../models/usuario';
import { Time } from '../models/time';
import { Cargo } from '../models/cargo';
import { ExpedienteDia } from '../models/expedientes';
import { Projeto } from '../models/projeto';
import { UsuarioIndicadores } from '../models/usuario-indicadores';
import { montarQueryString } from './shared.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) { }

  getCurrentUser(): Observable<any> {
    return this.http.get('.auth', httpOptions);
  }

  getUsers(ativo?: boolean): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(`Usuario/listar/${ativo == null ? '' : ativo}`, httpOptions);
  }

  getUserById(logon: string): Observable<Usuario> {
    return this.http.get<Usuario>(`Usuario/details/${logon}`, httpOptions);
  }

  createUser(usuario: Usuario): Observable<Usuario> {
    return this.http.post<Usuario>(`Usuario/create`, usuario, httpOptions);
  }

  editUser(usuario: Usuario): Observable<Usuario> {
    return this.http.put<Usuario>(`Usuario/edit`, usuario, httpOptions);
  }

  getTeams(): Observable<Time[]> {
    return this.http.get<Time[]>(`Usuario/teams`, httpOptions);
  }

  getRoles(): Observable<Cargo[]> {
    return this.http.get<Cargo[]>(`Usuario/cargos`, httpOptions);
  }

  getSequoiaUsers(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(`Usuario/listarSequoia`, httpOptions);
  }

  getExpedientes(filtro?: any): Observable<ExpedienteDia[]> {
    return this.http.get<ExpedienteDia[]>(`Usuario/expedientes?${montarQueryString(filtro)}`, httpOptions);
  }

  getExpedientesData(dataRef: string): Observable<ExpedienteDia[]> {
    return this.http.get<ExpedienteDia[]>(`Usuario/expedientes/${dataRef}`, httpOptions);
  }

  putExpedientesData(Input: any) {
    return this.http.put<any>(`Usuario/expedientes`, Input, httpOptions);
  }

  getProjetos(id?: number): Observable<Projeto[]> {
    return this.http.get<Projeto[]>(`usuario/projetos/${id ?? ""}`, httpOptions);
  }

  getIndicadores(userId?: number): Observable<UsuarioIndicadores> {
    return this.http.get<UsuarioIndicadores>(`usuario/indicadores/${userId ?? ''}`, httpOptions);
  }
}