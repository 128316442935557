import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { StorageService } from 'src/app/services/storage/storage.service';
import {
  ApiAzureDevopsService,
  FiltroGetSprintDevOps,
  FiltroGetTasksDevOps,
  FiltroCreateOrEditTaskDevOps,
} from 'src/app/services/api-azure-devops.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimerComponent } from 'src/app/@shared/components/timer/timer.component';
import { readWorkItemJsonField, SharedService } from 'src/app/services/shared.service';
import { ProjetosService } from 'src/app/services/projetos.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { Cliente } from 'src/app/models/cliente';
import { Projeto } from 'src/app/models/projeto';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import * as ClassicEditor from 'ckeditor5-build-classic-base64-upload';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-create-workitem',
  templateUrl: './create-workitem.component.html',
  styleUrls: ['./create-workitem.component.scss']
})

export class CreateWorkitemComponent implements OnInit {

  public Editor = ClassicEditor;

  isLoading: boolean;
  cadastroTask: FormGroup;
  NUMERIC_PATTERN = /^\d{1,2}(?:\.\d{1,2})?$/;

  parentFilteredOptions: Observable<string[]>;
  public filteredParents: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public parentMultiFilter: FormControl = new FormControl();
  private onParent = new Subject<void>();



  sprintFilteredOptions: Observable<string[]>;
  public filteredSprints: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public sprintMultiFilter: FormControl = new FormControl();
  private onDestroy = new Subject<void>();
  funcionarioSelecionado: number = StorageService.getUsuario().id;
  sprintsSelecionadas: string[] = [];
  clienteId: string[] = [];

  idWorkItem: any;
  sprint: any;
  sprints: any[];

  resumido: boolean = true;

  get type(): string {
    return this.cadastroTask.get("type").value;
  }

  get parent(): any {
    return this.cadastroTask.get("parent").value;
  }

  set parent(value) {
    this.cadastroTask.get("parent").setValue(value);
  }

  get project(): any {
    return this.cadastroTask.get("project").value;
  }

  get areaPath(): any {
    return this.cadastroTask.get("areaPath").value;
  }

  set areaPath(value) {
    this.cadastroTask.get("areaPath").setValue(value);
  }

  get targetDate(): any {
    return this.cadastroTask.get("targetDate").value;
  }

  set targetDate(value) {
    this.cadastroTask.get("targetDate").setValue(value);
  }

  description = "";
  reproSteps = "";
  acceptance = "";

  typesNew = ['Task', 'Bug', 'Ticket', 'Product Backlog Item', 'Feature', 'Epic'];
  displayClientProject: boolean;
  parents = [];
  clients: Cliente[] = [];
  projects: Projeto[] = [];

  idProject = null;
  dataRef: Date;

  task: any;
  story: any;

  responsavelId = null;

  constructor(
    private fb: FormBuilder,
    public devops: ApiAzureDevopsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TimerComponent>,
    private sharedService: SharedService,
    public clientService: ClienteService,
    public projectService: ProjetosService,
    public snackBar: MatSnackBar
  ) {
    console.log(data.sprint);
    if (data.sprint) this.sprint = data.sprint;
    if (data.sprints) this.sprints = data.sprints;
  }

  ngOnInit() {
    this.createCadastroForm();

    this.responsavelId = this.data.responsavelId;

    if (this.data?.dataRef) {
      this.dataRef = new Date(this.data?.dataRef);
      this.targetDate = this.dataRef;
    }

    this.getClients();

    if (!this.sprints)
      this.getSprints();
    else {
      this.filteredSprints.next(this.sprints.slice());
      this.sprintMultiFilter.valueChanges
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          this.filtroSprint(this.sprints, this.sprintMultiFilter, this.filteredSprints, 'id', true);
        });
    }

    if (this.sprint)
      this.getParents();
  }

  private filtroSprint(filtro: any, multifilter: any, filtered: any, field: string, sprint?: boolean) {
    if (!filtro) {
      return;
    }
    let search = multifilter.value;
    if (!search) {
      filtered.next(filtro.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    filtered.next(
      sprint ?
        filtro.filter(response => `${response.tenant} - ${response.name}`.toLowerCase().indexOf(search) > -1) :
        filtro.filter(response => response[field].toLowerCase().indexOf(search) > -1)
    );
  }

  private filtroParent(filtro: any, multifilter: any, filtered: any, field: string, parent?: boolean) {
    if (!filtro) {
      return;
    }
    let search = multifilter.value;
    if (!search) {
      filtered.next(filtro.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    filtered.next(
      parent ?
        filtro.filter(response => `${response.title} `.toLowerCase().indexOf(search) > -1) :
        filtro.filter(response => response[field].toLowerCase().indexOf(search) > -1)
    );
  }


  getSprints() {
    //this.isLoadingSprints = true;
    let filtro: FiltroGetSprintDevOps = {
      userId: this.funcionarioSelecionado
    };

    this.devops.getSprintsUsuario(filtro)
      .subscribe(sprints => {
        this.sprints = sprints;

        this.filteredSprints.next(this.sprints.slice());
        this.sprintMultiFilter.valueChanges
          .pipe(takeUntil(this.onDestroy))
          .subscribe(() => {
            this.filtroSprint(this.sprints, this.sprintMultiFilter, this.filteredSprints, 'id', true);
          });
        this.isLoading = false;
      });
  }

  createCadastroForm() {
    this.cadastroTask = this.fb.group({
      type: ['Task', Validators.required],
      parent: ['Task', Validators.required],
      title: ['', Validators.required],
      areaPath: ['', Validators.required],
      targetDate: [{ value: '' }],
      estimateHour: ['',
        Validators.compose([
          Validators.maxLength(5),
          Validators.pattern(this.NUMERIC_PATTERN)
        ])],
      client: [''],
      project: ['']
    });
  }

  sendUpdateDevops(): void {
    //this.sharedService.updateDevopsGrid('novatask');
  }

  clearMessages(): void {
    this.sharedService.clearMessages();
  }

  getClients() {
    this.clientService.getClients(true)
      .subscribe(
        (clients: Cliente[]) => this.clients = clients,
        (error: HttpErrorResponse) => console.error(error)
      );
  }

  async changeClient(id, referenciaId?) {
    this.projectService.getProjects(id, null, true)
      .subscribe(async response => {
        this.projects = response.filter(project => !!project.tag);
        if (this.projects.length === 0) {
          this.openSnackBar('Este cliente não possui PROJETOS com TAG associada', 'OK', 5000, 'warning-snackbar');
        }
      });
  }

  async getParents() {
    var parentTypes = this.getParentTypes();
    if (parentTypes) {
      this.isLoading = true;
      const filtro: FiltroGetTasksDevOps = {
        dataRef: this.dataRef,
        types: parentTypes,
        sprintId: this.sprint?.id,
        userId: this.responsavelId
      };

      this.devops.getTasks(filtro)
        .subscribe(parents => {
          this.parents = parents;

          this.filteredParents.next(this.parents.slice());
          this.parentMultiFilter.valueChanges
            .pipe(takeUntil(this.onParent))
            .subscribe(() => {
              this.filtroParent(this.parents, this.parentMultiFilter, this.filteredParents, 'id', true);
            });

          this.isLoading = false;
        });
    } else {
      this.parents = [];
    }
  }

  getParentDescription() {
    if (this.type == "Task" || this.type == "Bug") {
      return "Em qual US ou Ticket?";
    }

    if (this.type == "Ticket") {
      return "Em qual serviço?";
    }

    if (this.type == "Product Backlog Item") {
      return "Em qual Feature?";
    }

    if (this.type == "Feature") {
      return "Em qual Épico?";
    }

    return "";
  }

  getTitleLabel() {
    if (this.type == "Task")
      return "Título da Tarefa";

    if (this.type == "Bug") {
      return "Título do Bug";
    }

    if (this.type == "Ticket") {
      return "Título do Chamado";
    }

    if (this.type == "Product Backlog Item") {
      return "Título da User Story";
    }

    if (this.type == "Feature") {
      return "Nome da Feature";
    }

    if (this.type == "Epic") {
      return "Nome do Épico";
    }

    return "";
  }

  getParentTypes() {
    if (this.type == "Task" || this.type == "Bug") {
      return ["Product Backlog Item", "Ticket"];
    }

    if (this.type == "Ticket" || this.type == "Product Backlog Item") {
      return ["Feature"];
    }

    if (this.type == "Feature") {
      return ["Epic"];
    }

    return null;
  }

  getNewStatus() {
    if (this.type == "Task") {
      return "To Do";
    }

    // if (this.type == "Ticket" || this.type == "Product Backlog Item") {
    //   return "New";
    // }

    // if (this.type == "Feature") {
    //   return "New";
    // }

    return "New";
  }

  changeParent() {
    this.areaPath = readWorkItemJsonField(this.parent.jSon, "System.AreaPath");

    let d = this.parent.targetDate?.split('T')[0];
    if (d) this.targetDate = new Date(d);
    else this.targetDate = new Date();
  }

  novaTarefa(event?: any) {

    if (this.type != 'Epic' && !this.parent) {
      this.openSnackBar('Selecione o item relacionado!', 'OK', 3000, 'warning-snackbar');
      return;
    }

    if (!this.cadastroTask.get('title').value) {
      this.openSnackBar('Título não informado!', 'OK', 3000, 'warning-snackbar');
      return;
    }

    this.isLoading = true;
    const filtro: FiltroCreateOrEditTaskDevOps = {
      sprintId: this.sprint.id,
      // sprintName: this.sprint.name,
      // iterationPath: this.sprint.iterationPath,
      idParent: this.parent?.idWorkItem,
      parentTitle: this.parent?.title,
      title: this.cadastroTask.get('title').value,
      originalEstimate: this.cadastroTask.get('estimateHour').value,
      remainingWork: this.cadastroTask.get('estimateHour').value,
      tags: this.parent?.tags ?? this.project,
      type: this.type,
      targetDate: this.dataRef,
      priority: 1,
      status: this.getNewStatus(),
      bloqueada: false,
      concluida: false,
      motivoBloqueio: '',
      responsavelId: this.responsavelId,
      areaPath: this.areaPath,
      description: this.description,
      reproSteps: this.reproSteps,
      acceptanceCriteria: this.acceptance
    };
    // console.log(filtro);
    this.devops.createSprintTask(filtro).subscribe(tarefa => {
      this.isLoading = false;
      this.idWorkItem = tarefa.id;
      // this.sharedService.startNewTask(tarefa);
      this.dialogRef.close(tarefa);
    },
      (err: HttpErrorResponse) => {
        console.error(err);
        this.isLoading = false;
      });
  }

  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this.snackBar.open(message, action, { duration, panelClass });
  }

  changeSprint() {
    this.parent = null;
    this.getParents();
  }

  typeChanged() {
    if (this.sprint) {
      setTimeout(() => {
        this.getParents();
      }, 100);
    }
  }

  concluir(e) {
    this.dialogRef.close(this.task);
  }

  save(e) {
    this.novaTarefa();
  }
}
