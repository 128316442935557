import {  HttpBackend, HttpClient, HttpHandler, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthJiraService {     

    private httpClient: HttpClient; 
    private apiUrl = 'https://api.atlassian.com/oauth/token/accessible-resources'; 
    
    constructor(handleR: HttpBackend, 
                private http: HttpClient) {
        //private httpXhrBackend: HttpBackend
        this.httpClient = new HttpClient(handleR);
        
    } 
    

    getTokenAcesso(accessToken): Observable<any> {
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${accessToken}`,
        'Accept': 'application/json'
      });
      
  
      return this.httpClient.get<any>(this.apiUrl, { headers }).pipe(
        tap(response => console.log(response)),
        
        catchError(error => {
          console.error('Erro na chamada à API:', error);
          return throwError(error);
        }));
    }

    getUserInfo(accessToken: string): Observable<any> {
      const urlInfo = 'https://api.atlassian.com/me';
      const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Accept': 'application/json'
      }; 
       
        return this.httpClient.get(`${urlInfo}`, { headers });      
    }


}