import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AppDefaultComponent } from "./app-default/app-default.component";
import { SharedModule } from "src/app/@shared/shared.module";
@NgModule({
  declarations: [AppDefaultComponent],
  providers: [],
  imports: [CommonModule, SharedModule],
  exports: [AppDefaultComponent],
})
export class AppDefaultModule {}
