import { Component, OnInit, Inject, AfterViewInit, AfterContentInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ApiAzureDevopsService,
  FiltroCreateOrEditTaskDevOps,
} from 'src/app/services/api-azure-devops.service';
import { readWorkItemJsonValue } from 'src/app/services/shared.service';
import * as ClassicEditor from 'ckeditor5-build-classic-base64-upload';
import { DevopsHelpers } from 'src/app/@helpers/devops.helpers';

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-edit-workitem',
  templateUrl: './edit-workitem.component.html',
  styleUrls: ['./edit-workitem.component.scss']
})

export class EditWorkitemComponent implements OnInit, AfterViewInit, AfterContentInit {

  public EditorDescription = ClassicEditor;
  public EditorAcept = ClassicEditor;
  public EditorRepro = ClassicEditor;
  //public Base64UploadAdapter = Base64UploadAdapter;

  idUsuario: any;
  isLoading: boolean;
  tarefaForm: any;
  referenciaId: number;
  task: any = null;

  idAzureDevops: number = null;
  get title() {
    return this.task?.title;
  };

  set title(value) {
    if (this.task)
      this.task.title = value;
  };

  taskOriginalEstimated: string;
  taskRemainingWork: number;
  taskTargetDate: Date;
  taskPriority: number;
  taskTags: string;
  taskBloqueada: boolean;
  taskMotivoBloqueio: string;
  taskStatus: string;

  blocked = false;
  blockedText: string;

  sprint: any;
  tasks: any;
  createTask: any;
  status = ['To Do', 'In Progress', 'Done', 'Commited', 'Ready for test', 'Staging'];
  concluida: boolean;
  idSprint: string;

  responsavelId = null;

  get description() {
    return this.tarefaForm.get("description")?.value;
  };

  set description(value) {
    this.tarefaForm.get("description")?.setValue(value);
  };

  get reproSteps() {
    return this.tarefaForm.get("reproSteps")?.value;
  };

  set reproSteps(value) {
    this.tarefaForm.get("reproSteps")?.setValue(value);
  };

  get acceptance() {
    return this.tarefaForm.get("acceptance")?.value;
  };

  set acceptance(value) {
    this.tarefaForm.get("acceptance")?.setValue(value);
  };


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    public fb: FormBuilder,
    private devops: ApiAzureDevopsService,
    private dialogRef: MatDialogRef<EditWorkitemComponent>
  ) {

  }

  ngOnInit() {
    this.createForm();
    if (this.data.task) {
      this.changeTask(this.data.task);
    } else if (this.data.idAzureDevops) {
      //buscar pelo ID
      this.isLoading = true;
      this.devops.getTaskById(this.data.idAzureDevops).subscribe(tarefa => {
        this.changeTask(tarefa);
        console.log(tarefa);
        this.isLoading = false;
      },
        (error: HttpErrorResponse) => {
          console.error(error);
          this.openSnackBar('Erro ao consultar tarefa!', 'OK', 5000, 'danger-snackbar');
          this.dialogRef.close();
        });
    }
  }
  
  iconUrl(task) {
    return DevopsHelpers.iconUrl(task);
  }

  itemUrl(task) {
    return DevopsHelpers.itemUrl(task);
  }

  itemParentUrl(task) {
    return DevopsHelpers.itemParentUrl(task);
  }

  changeTask(task) {
    console.log(task);
    this.task = task;
    this.idAzureDevops = task.idAzureDevops;

    this.title = this.task.title;
    //this.observacoes = this.observacoes ?? this.readField(this.task.jSon, "System.Description");
    this.taskOriginalEstimated = this.task.originalEstimate;
    this.taskRemainingWork = this.task.remainingWork;
    this.taskTargetDate = this.task.targetDate;
    this.taskPriority = this.task.priority;
    this.taskStatus = this.task.status;
    this.taskBloqueada = this.task.bloqueada;
    this.taskMotivoBloqueio = this.task.motivoBloqueio;
    this.taskTags = this.task.tags;
    this.responsavelId = this.task.idUsuario ?? this.task.responsavelId;

    // this.EditorDescription.setValue(this.readField(this.task.jSon, "System.Description"));
    // this.EditorAcept.setValue(this.readField(this.task.jSon, "Microsoft.VSTS.Common.AcceptanceCriteria"));
    // this.EditorRepro.setValue(this.readField(this.task.jSon, "Microsoft.VSTS.TCM.ReproSteps"));

    this.description = this.readField(this.task.jSon, "System.Description");
    this.acceptance = this.readField(this.task.jSon, "Microsoft.VSTS.Common.AcceptanceCriteria");
    this.reproSteps = this.readField(this.task.jSon, "Microsoft.VSTS.TCM.ReproSteps");

    this.tarefaForm.updateValueAndValidity();
  }


  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.autoResize();
    // }, 200);
  }

  ngAfterContentInit() {
    this.setCustomValidators();
  }

  readField(jsonString, fieldName) {
    if (jsonString)
      return JSON.parse(jsonString)["Fields"][fieldName];
    else return null;
  }

  createForm() {
    this.tarefaForm = this.fb.group({
      title: [{ value: this.title }],
      status: [this.status[0]],
      tags: [''],
      estimateHour: [{ value: '', disabled: true }],
      remainingWork: [{ value: '' }],
      priority: [2, [Validators.required, Validators.min(1), Validators.max(20)]],
      targetDate: [{ value: '' }],
      isBlocked: [''],
      blockedText: [null],
      description: [{ value: '' }],
      reproSteps: [{ value: '' }],
      acceptance: [{ value: '' }],
    });
  }

  salvarTask(event) {
    event.preventDefault();
    this.editTask();
  }

  disableButtonTask(): boolean {
    return ((this.tarefaForm.invalid || this.isLoading)
      || (this.isLoading || this.idAzureDevops == null));
  }

  editTask() {
    this.isLoading = true;

    const filtro: FiltroCreateOrEditTaskDevOps = {
      id: this.idAzureDevops,
      title: this.title,
      originalEstimate: this.tarefaForm.get('estimateHour').value,
      remainingWork: this.tarefaForm.get('remainingWork').value,
      targetDate: this.tarefaForm.get('targetDate').value,
      priority: this.tarefaForm.get('priority').value,
      tags: this.tarefaForm.get('tags').value,
      //type: 'Task',
      status: this.tarefaForm.get('status').value,
      bloqueada: this.tarefaForm.get('isBlocked').value ? true : false,
      concluida: this.concluida == true
        ? this.concluida
        : this.tarefaForm.get('status').value === 'Done'
          ? true
          : false,
      motivoBloqueio: this.tarefaForm.get('blockedText').value,
      responsavelId: this.responsavelId,
      description: this.description,
      reproSteps: this.reproSteps,
      acceptanceCriteria: this.acceptance
    };
    // console.log(filtro);
    this.devops.editSprintTask(filtro).subscribe(x => {
      this.dialogRef.close(x);
      this.isLoading = false;
      this.openSnackBar('Tarefa alterada com sucesso!', 'OK', 5000, 'success-snackbar');
      //this.tasks.push(x);
    },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.openSnackBar('Erro ao alterar tarefa!', 'OK', 5000, 'danger-snackbar');
        this.isLoading = false;
      });
  }

  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this.snackBar.open(message, action, { duration, panelClass });
  }

  setCustomValidators() {
    this.tarefaForm.get('isBlocked').valueChanges
      .subscribe(checked => {
        if (checked) {
          this.tarefaForm.get('blockedText').setValidators([Validators.required]);

          if (!this.tarefaForm.get('tags').value.includes('#BLOCKED;')) {
            this.tarefaForm.get('tags').setValue(`#BLOCKED; ${this.taskTags}`);
            this.tarefaForm.get('tags').updateValueAndValidity();
          }
        } else {
          if (this.tarefaForm.get('tags').value && this.tarefaForm.get('tags').value.includes('#BLOCKED;')) {
            const block = this.tarefaForm.get('tags').value.replace('#BLOCKED; ', '');
            this.tarefaForm.get('tags').setValue(block);
          }
          this.tarefaForm.get('blockedText').setValidators(null);
          this.tarefaForm.get('tags').updateValueAndValidity();
          this.tarefaForm.get('blockedText').setValue(null);
          this.tarefaForm.get('blockedText').updateValueAndValidity();
        }
        this.tarefaForm.get('blockedText').updateValueAndValidity();
      });
  }

  bgColor(status) {
    switch (status) {
      case 'To Do': return '#5199FF';
      case 'In Progress': return '#F3A712';
      case 'Commited': return '#939598';
      case 'Done': return '#00CF91';
      default: return 'gainsboro';
    }
  }

  bgColorBlocked(isBlocked) {
    return isBlocked ? 'var(--red)' : "white";
  }


}
