import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TimerService } from 'src/app/services/timer.service';
import { timer } from 'rxjs';
import { ApontamentoService } from 'src/app/services/apontamento.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageService } from 'src/app/services/storage/storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedService } from 'src/app/services/shared.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ModalConfirmacaoComponent } from 'src/app/@modules/manage/clientes/components/modal-confirmacao/modal-confirmacao.component';
import { ModalSelecaoTarefaComponent } from 'src/app/@modules/main/components/board/devops-tasks/modal-selecao-tarefa/modal-selecao-tarefa.component';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})

export class TimerComponent implements OnInit, OnDestroy {

  @ViewChild(MatMenuTrigger) triggerBtnPlay: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) triggerBtnStop: MatMenuTrigger;

  usuario = StorageService.getUsuario();
  isLoading: boolean;
  window: any;

  private playPauseStopUnsubscribe: any;
  public play: boolean = true;

  @Input() id: any;
  @Input() hideCron: boolean;

  descricao: any;

  start = 0;
  ticks = 0;

  minutesDisplay = 0;
  hoursDisplay = 0;
  secondsDisplay = 0;

  sub: Subscription;

  mesSelecionado = ((new Date()).getMonth() + 1).toString();
  anoSelecionado = (new Date()).getFullYear().toString();

  // Apontamento
  _task: any;
  @Input() set task(value: any) {
    this._task = value;
    this.timerSeconds = value?.timerSeconds ?? 0;
    this.start = value?.timerSeconds ?? 0;
    this.descricao = value?.descricao;

    var res = {
      task: this.task,
      play: !!this.task,
      stop: !this.task
    };
    this.playPauseStop(res);
  }

  get task(): any {
    return this._task;
  }

  runningTask: any;

  date: any;
  startHour: any;
  endHour: any;
  timerSeconds: any;

  @Input() origem: string;

  lastAppointment: any;
  startByLastAppointment: boolean;


  constructor(
    private apontamentoService: ApontamentoService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private sharedService: SharedService,
    private timerService: TimerService
  ) {

  }

  ngOnInit() {
    // this.runningTask = this.sharedService.runningTask;
    this.playPauseStopUnsubscribe =
      this.timerService.getValues()
        .subscribe((res: any) => {
          this.runningTask = res?.task;
          this.playPauseStop(res)
        });
  }

  openMenu() {
    this.triggerBtnPlay?.openMenu();
    this.triggerBtnStop?.openMenu();
  }

  ngOnDestroy() {
    this.playPauseStopUnsubscribe?.unsubscribe();
  }

  sendUpdateAppointment(): void {
    this.sharedService.updateAppointmentGrid('timer');
  }

  startNewTask(task: any): void {
    this.timerService.playTimer(task);
  }

  // clearMessages(): void {
  //   this.sharedService.clearMessages();
  // }

  public playPauseStop(res: any) {
    if (res?.task?.idAzureDevops && res?.task?.idAzureDevops == this.task?.idAzureDevops) {
      if (res.play) {
        this.startTimer();
      } else if (res.pause) {
        this.pauseTimer();
      } else if (res.stop) {
        this.stopTimer();
      }
    }
    else {
      this.stopTimer();
    }
  }

  public startTimer(startIn?: number) {
    this.play = false;
    if (startIn) {
      this.start = startIn;
    }

    const timer$ = timer(0, 1000);

    this.sub?.unsubscribe();
    this.sub = timer$.subscribe(t => {
      this.ticks = this.start + t;
      this.secondsDisplay = this.getSeconds(Math.abs(this.ticks));
      this.minutesDisplay = this.getMinutes(Math.abs(this.ticks));
      this.hoursDisplay = this.getHours(Math.abs(this.ticks));
    });
  }

  private pauseTimer() {
    this.play = true;
    this.start = ++this.ticks;
    this.sub?.unsubscribe();

  }

  private stopTimer() {
    this.play = true;
    this.start = 0;
    this.ticks = 0;


    this.minutesDisplay = 0;
    this.hoursDisplay = 0;
    this.secondsDisplay = 0;

    this.sub?.unsubscribe();
  }

  private getSeconds(ticks: number) {
    return this.pad(ticks % 60);
  }

  private getMinutes(ticks: number) {
    return this.pad((Math.floor(ticks / 60)) % 60);
  }

  private getHours(ticks: number) {
    return this.pad(Math.floor((ticks / 60) / 60));
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

  async playT() {
    this.isLoading = true;
    //await this.playTime(this.startByLastAppointment ? this.lastAppointment : new Date());
    await this.playTime(this.startByLastAppointment ? this.lastAppointment : new Date());
  }

  async playTime(dataInicio?: Date) {
    if (!this.task?.idAzureDevops) {
      await this.openSelectTask();
    } else {
      if (this.runningTask) {
        // ao dar play, encerrar apontamento em andamento
        await this.autoAppointment();
      } else await this.create(this.task?.idAzureDevops);
    }
  }
  menuOpened() {
    this.getLastAppointment();
  }

  async stopT(done?: boolean) {
    this.isLoading = true;
    await this.autoAppointment(done);
  }

  getLastAppointment() {
    this.apontamentoService.getLastAppointment()
      .subscribe(async retorno => {
        this.lastAppointment = new Date(`${retorno.data.substr(0, 11)}${retorno.horaFim}`);
        this.getStartByLastAppointment();
      });
  }

  async autoAppointment(done?: boolean) {
    const { dataInicio, horaInicio, idAzureDevops } = await this.runningTask;

    this.date = new Date(dataInicio.substr(0, 10));
    this.startHour = horaInicio.substr(0, 5);
    this.endHour =
      new Date()
        .getHours()
        .toString()
        .padStart(2, '0') +
      ':' +
      new Date()
        .getMinutes()
        .toString()
        .padStart(2, '0');

    if ((idAzureDevops)
      && (this.date)
      && (this.startHour)) {

      await this.createAppointment(done);
    } else {
      this.openAppointment(done);
    }
  }

  async createAppointment(done?: boolean) {
    const { idProjeto, idAzureDevops, descricao } = await this.runningTask;
    const date: Date = new Date();
    const obj = {
      referenciaId: idProjeto,
      data: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
      horaInicio: this.startHour,
      horaFim: this.endHour,
      observacoes: descricao,
      idAzureDevops: idAzureDevops,
      concluida: done
    };
    this.isLoading = true;
    await this.apontamentoService.create(obj).subscribe(() => {
      if (this.task?.idAzureDevops
        && this.runningTask
        && this.runningTask.idAzureDevops
        && this.runningTask.idAzureDevops != this.task?.idAzureDevops) {

        this.create(this.task?.idAzureDevops);
      } else {
        // forcar zerar todos os timers
        this.startNewTask(null);
      }

      this.openSnackBar('Sucesso, apontamento efetuado!', 'OK', 5000, 'success-snackbar');
      this.sendUpdateAppointment();
      this.isLoading = false;
    },
      (error: HttpErrorResponse) => {
        console.error(error.message);
        this.openSnackBar('Apontamento conflitante! confira os horários', 'OK', 5000, 'warning-snackbar');
        this.isLoading = false;
      });
  }

  async create(id: number) {
    const obj = {
      idAzureDevops: id
    };
    this.isLoading = true;
    await this.apontamentoService.preCreate(obj)
      .subscribe((response) => {
        this.isLoading = false;
        this.startNewTask(response);
      },
        (error: HttpErrorResponse) => {
          console.error(error);
          this.openSnackBar('Tente novamente! Apontamento Conflitante!', 'OK', 5000, 'warning-snackbar');
          this.isLoading = false;
        });

  }

  getStartByLastAppointment() {
    if ((this.lastAppointment.toLocaleString().substr(0, 10)) ==
      (new Date().toLocaleString().substr(0, 10))) {
      this.startByLastAppointment = true;
    } else {
      this.startByLastAppointment = false;
    }
  }

  openAppointment(done?: boolean) {
    this.isLoading = true;

    const preApontamento = this.runningTask;
    preApontamento.concluida = done;

    const dialogRef = this.dialog.open(ModalSelecaoTarefaComponent, {
      disableClose: true,
      width: '540px',
      data: { tela: 'timer', preApontamento }
    });

    dialogRef.afterClosed().subscribe(retorno => {
      if (retorno && retorno != 'cancel') {
        if (retorno.idAzureDevops) {
          this.create(retorno.idAzureDevops);
        } else {
          this.startNewTask(null);
        }

        this.sendUpdateAppointment();
      }
      // else {
      //   this.startNewTask(this.runningTask);
      // }
      this.isLoading = false;
    });
  }

  openSelectTask() {
    const dialogRef = this.dialog.open(ModalSelecaoTarefaComponent, {
      disableClose: true,
      width: '740px',
      data: { tela: 'timer' }
    });

    dialogRef.afterClosed().subscribe(async retorno => {
      if (retorno != 'cancel' && retorno) {
        if (this.runningTask) {
          // ao dar play, encerrar apontamento em andamento
          await this.autoAppointment();
        }
        this.runningTask = retorno;
        this.create(this.runningTask?.idAzureDevops);
      }
      this.isLoading = false;
    });
  }

  async openConfirmation() {
    this.isLoading = await true;
    await this.apontamentoService.preAtual()
      .subscribe(preApontamento => {
        const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
          width: '450px',
          data: {
            descricao: preApontamento.descricao
          }
        });

        dialogRef.afterClosed()
          .subscribe(
            async retorno => {
              if (retorno === 1) {
                await this.cancelAppointment(preApontamento.id);
              }
            });
      },
        (error: HttpErrorResponse) => {
          console.error(error.message);
          this.openSnackBar('Erro, tente novamente!' + error.error, 'OK', 5000, 'danger-snackbar');
          this.isLoading = false;
        });
  }

  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this.snackBar.open(message, action, { duration, panelClass });
  }

  async cancelAppointment(id) {
    await this.apontamentoService.preDelete(id)
      .subscribe(async retorno => {
        await this.startNewTask(null);
        //await this.getLastAppointment();
        this.openSnackBar('Tarefa cancelada', 'OK', 3000, 'success-snackbar');
        this.isLoading = false;
      },
        (error: HttpErrorResponse) => {
          console.error(error.message);
          this.openSnackBar('Erro, tente novamente!' + error.error, 'OK', 5000, 'danger-snackbar');
          this.isLoading = false;
        });
  }

  clean() {
    this.descricao = null;
    this.stopTimer();
  }
}
