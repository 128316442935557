import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export class FiltroHorasMes {
  logon?: string;
  mes: number;
  ano: number;
  idCliente?: number;
  idProjeto?: number;
  data: Date;
  logons: string[];
}

@Injectable({
  providedIn: 'root'
})

export class ApontamentoService {

  constructor(private http: HttpClient) { }

  getHours(filtro: FiltroHorasMes): Observable<any> {
    return this.http.post(`Relatorio/joit/horas/`, filtro, httpOptions) as Observable<any>;
  }

  create(obj: object): Observable<any> {
    return this.http.post(`Apontamento/create`, obj, httpOptions) as Observable<any>;
  }

  getUltimoApontamento(date: Date, login?: any): Observable<any> {
    return this.http
      .get(
        `Apontamento/ultimoapontamento/${date.getFullYear()}-${(date.getMonth() + 1)}-${date.getDate()}/${login ? login : ''}`,
        httpOptions
      ) as Observable<any>;
  }

  getLastAppointment(): Observable<any> {
    return this.http.post(`Apontamento/ultimoapontamento/`, httpOptions) as Observable<any>;
  }

  details(id: number): Observable<any> {
    return this.http.get(`Apontamento/details/${id}`, httpOptions) as Observable<any>;
  }

  edit(obj: object): Observable<any> {
    return this.http.put(`Apontamento/edit`, obj, httpOptions) as Observable<any>;
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`Apontamento/delete/${id}`, httpOptions) as Observable<any>;
  }

  getcards(mes: any, ano: any, logon: any, contabilizaHoras: any): Observable<any> {
    return this.http.post(`Apontamento/listarcards`, { mes, ano, logon, contabilizaHoras }, httpOptions) as Observable<any>;
  }

  // <<<< Pré Apontamento >>>>
  preListar(logon?: string): Observable<any> {
    return this.http.post(`Apontamento/pre/listar`, { logon }, httpOptions) as Observable<any>;
  }

  preCreate(obj: object): Observable<any> {
    return this.http.post(`Apontamento/pre/create`, obj, httpOptions) as Observable<any>;
  }

  preDetails(id: number): Observable<any> {
    return this.http.get(`Apontamento/pre/details/${id}`, httpOptions) as Observable<any>;
  }

  preEdit(obj: object): Observable<any> {
    return this.http.put(`Apontamento/pre/edit`, obj, httpOptions) as Observable<any>;
  }

  preDelete(id: number): Observable<any> {
    return this.http.delete(`Apontamento/pre/delete/${id}`, httpOptions) as Observable<any>;
  }

  preEncerrar(id: number): Observable<any> {
    return this.http.post(`Apontamento/pre/encerrar/${id}`, httpOptions) as Observable<any>;
  }

  preAtual(idTask?: number): Observable<any> {
    return this.http.get(`Apontamento/pre/atual/${idTask ? idTask : ''}`, httpOptions) as Observable<any>;
  }
}