<div class="custom-modal">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="arastavel">
        <div class="header">
            <h2 mat-dialog-title>
                Confirma Operação ?
            </h2>
            <mat-icon mat-dialog-close="cancel">close</mat-icon>
        </div>
    </div>
    <mat-divider></mat-divider>
    <mat-progress-bar [hidden]="!isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
    <div *ngIf="!isLoading" mat-dialog-content class="content">
        <p *ngIf="!mensagem">Tem certeza que deseja excluir o registro de
            <strong *ngIf="data.client">{{ data.client.nome }}</strong>
            <strong *ngIf="data.fase">{{ data.fase.descricao }}</strong>
            <strong *ngIf="data.cargo">{{ data.cargo.nome }}</strong>
            <strong *ngIf="data.preApontamento">{{ data.preApontamento.cliente }}</strong>
            <strong *ngIf="data.project">{{ data.project.descricao }}</strong>
            <strong *ngIf="data.saldo">{{ data.saldo.funcionario }}</strong>
            <strong *ngIf="data.planejamento">{{ data.planejamento.nome }}</strong>
            <strong *ngIf="data.descricao">{{ data.descricao }}</strong>
            <strong *ngIf="data.jornada">{{ data.jornada.nome }}</strong>
            ?
        </p>
        <p *ngIf="mensagem">
            {{mensagem}}
        </p>
        <p>Esta operação não poderá ser desfeita.</p>
    </div>
    <div class="modal-actions" mat-dialog-actions align="end">
        <button mat-button mat-dialog-close="cancel">Cancelar</button>
        <button *ngIf="data.jornada && !isLoading" mat-flat-button class="botaoexcluir"
            (click)="deleteJornada(data.jornada)" [disabled]="isLoading">Excluir</button>
        <button *ngIf="data.client && !isLoading" mat-flat-button class="botaoexcluir"
            (click)="deleteClient(data.client)" [disabled]="isLoading">Excluir</button>
        <button *ngIf="data.cargo && !isLoading" mat-flat-button class="botaoexcluir" (click)="deleteCargo(data.cargo)"
            [disabled]="isLoading">Excluir</button>
        <button *ngIf="data.fase && !isLoading" mat-flat-button class="botaoexcluir" (click)="deleteFase(data.fase)"
            [disabled]="isLoading">Excluir</button>
        <button *ngIf="data.preApontamento && !isLoading" mat-flat-button class="botaoexcluir" class="text-white"
            (click)="deletePreAppointment(data.preApontamento.id)" [disabled]="isLoading">Excluir</button>
        <button *ngIf="data.project && !isLoading" mat-flat-button class="botaoexcluir"
            (click)="deleteProject(data.project)" [disabled]="isLoading">Excluir</button>
        <button *ngIf="data.saldo && !isLoading" mat-flat-button class="botaoexcluir"
            (click)="excluirSaldo(data.saldo.id)" [disabled]="isLoading">Excluir</button>
        <button *ngIf="data.planejamento && !isLoading" mat-flat-button class="botaoexcluir"
            (click)="deletePlanning(data.planejamento.id)" [disabled]="isLoading">Excluir</button>
        <button
            *ngIf="!data.project && !data.saldo && !data.planejamento && !data.client  && !data.jornada && !data.fase  && !data.cargo && !data.preApontamento && !isLoading"
            mat-flat-button class="botaoexcluir" color="warn" class="text-light"
            (click)="confirmDelete()">Excluir</button>
    </div>
</div>