import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
import { EmpresasService } from "src/app/services/empresas.service";
import { StorageService } from "src/app/services/storage/storage.service";

@Injectable({
  providedIn: "root",
})
export class ManageAuthGuard implements CanActivate {
  mySubscription: any;
  constructor(
    private router: Router,
    private authService: AuthService,
    private empresaService: EmpresasService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    var token = state.root.queryParams.at;


    if (state.root.queryParams.newUser === "true") {
      StorageService.deleteUsuario();
    }

    if (!StorageService.getUsuario() || !StorageService.getToken() || (token && StorageService.getToken() != token)) {
      //forçar relogar usuário
      let queryParams: any = {
        ...state.root.queryParams,
        redirectUrl: state.url,
      };
      this.router.navigate(["/signin"], { queryParams });
      return false;

    } else {
      const guidEmpresa = this.getParamRecursive(next, "company");
      if (!guidEmpresa) {
        if (
          state.root.queryParams.projectName &&
          state.root.queryParams.organizationName
        ) {
          return this.empresaService
            .getAllCompanies({
              projectName: state.root.queryParams.projectName,
              organizationName: state.root.queryParams.organizationName,
            })
            .pipe(
              map((check) => {
                if (check.body?.identificadorGuid) {
                  const empresa: string = check.body?.identificadorGuid;
                  const navigate_url = "/" + empresa.toUpperCase() + state.url.split('?')[0];
                  const navigate_params = state.url
                    ? this.router.parseUrl(state.url)?.queryParams ?? {}
                    : {};
                  this.router.navigate([navigate_url], {
                    queryParams: {
                      ...state.root.queryParams,
                      ...navigate_params,
                    },
                  });
                  return true;
                } else {
                  let queryParams: any = {
                    ...state.root.queryParams,
                    redirectUrl: state.url,
                  };
                  this.router.navigate(["/select-company"], { queryParams });
                  return false;
                }
              })
            );
        } else {
          return this.authService.redirectToCompany(next, state);
        }
      } else
        return this.authService.checkFeature(
          next.data["FEATURE_ID"] || 0,
          guidEmpresa
        );
    }
  }

  getParamRecursive(route, paramName) {
    let value = route.parent?.params[paramName];
    if (value) return value;
    else if (route.parent != null)
      return this.getParamRecursive(route.parent, paramName);
    else return null;
  }
}
