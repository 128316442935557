import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./@core/guards/default/auth.guard";
import { AppDefaultComponent } from "./@core/app-default/app-default/app-default.component";
import { AppManageEmbedComponent } from "./@core/app-manage-embed/app-manage-embed/app-manage-embed.component";

const routes: Routes = [
  {
    path: "",
    data: { title: "Corp Management" },
    loadChildren: () =>
      import("./@modules/public/public.module").then((m) => m.PublicModule),
  },
  {
    path: "embed/manage",
    component: AppManageEmbedComponent,
    data: { title: "Corp Management" },
    loadChildren: () =>
      import("./@modules/manage/manage.module").then((m) => m.ManageModule),
  },
  {
    path: "manage",
    component: AppDefaultComponent,
    data: { title: "Corp Management - Admin" },
    loadChildren: () =>
      import("./@modules/manage/manage.module").then((m) => m.ManageModule),
  },
  {
    path: ":company/embed/manage",
    component: AppManageEmbedComponent,
    data: { title: "Corp Management - Admin" },
    loadChildren: () =>
      import("./@modules/manage/manage.module").then((m) => m.ManageModule),
  },
  {
    path: ":company/manage",
    component: AppDefaultComponent,
    data: { title: "Corp Management - Admin" },
    loadChildren: () =>
      import("./@modules/manage/manage.module").then((m) => m.ManageModule),
  },
  {
    path: "",
    canActivate: [AuthGuard],
    data: { title: "Corp Management" },
    loadChildren: () =>
      import("./@modules/main/main.module").then((m) => m.MainModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
