<div class="custom-modal">
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="arastavel">
    <div class="header">
      <h2 mat-dialog-title>Criar Item de Trabalho</h2>
      <mat-icon mat-dialog-close="cancel">close</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-progress-bar [hidden]="!isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
  <div mat-dialog-content class="content">
    <form class="form-tarefa" [formGroup]="cadastroTask" autocomplete="off">
      <div>
        <mat-label>Qual o tipo do item?</mat-label>
        <mat-radio-group formControlName="type">
          <mat-radio-button class="example-margin" *ngFor="let types of typesNew" [value]="types"
            (change)="typeChanged()">
            {{ types }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field>
        <mat-label>{{getTitleLabel()}}</mat-label>
        <input matInput [placeholder]="getTitleLabel()" name="task" formControlName="title" />
        <mat-error *ngIf="cadastroTask.get('title').errors?.required">
          O Campo é obrigatório!
        </mat-error>
      </mat-form-field>
    </form>

    <mat-form-field>
        <mat-label>Em qual sprint</mat-label>
        <mat-select [(value)]="sprint" (selectionChange)="changeSprint()" formControlName="sprintFormControl"  >
          <mat-option><ngx-mat-select-search [formControl]="sprintMultiFilter" placeholderLabel="Buscar..."
            noEntriesFoundLabel="Nenhum resultado encontrado..."></ngx-mat-select-search></mat-option>
        <mat-option *ngFor="let sprint of filteredSprints | async" [value]="sprint">
          [{{sprint.tenant}}] - {{ sprint.name }}
        </mat-option>
        </mat-select>
    </mat-form-field>

    

    <form class="form-tarefa" [formGroup]="cadastroTask" autocomplete="off">
<!--
   <mat-form-field *ngIf="type != 'Epic'">
        <mat-label>{{getParentDescription()}}</mat-label>
        <mat-select matNativeControl formControlName="parent" required (selectionChange)="changeParent()">
          <mat-option *ngFor="let parent of parents" [value]="parent">
            {{ parent.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
-->


      <mat-form-field *ngIf="type != 'Epic'">
        <mat-label>{{getParentDescription()}}</mat-label>
        <mat-select [(value)]="parent" matNativeControl required (selectionChange)="changeParent()" formControlName="parentFormControl" [(ngModel)]="parent">
         <!--<mat-option *ngFor="let parent of parents" [value]="parent">
            {{ parent.title }}
          </mat-option>-->
          <mat-option><ngx-mat-select-search [formControl]="parentMultiFilter" placeholderLabel="Buscar..."
            noEntriesFoundLabel="Nenhum resultado encontrado..."></ngx-mat-select-search></mat-option>
        <mat-option *ngFor="let parent of filteredParents | async" [value]="parent">
          {{ parent.title }}
        </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field *ngIf="type == 'Epic'">
        <mat-label>Selecione o cliente</mat-label>
        <mat-select matNativeControl (selectionChange)="changeClient($event.value)" formControlName="client">
          <mat-option *ngFor="let client of clients" [value]="client.id">
            {{ client.nome }}
          </mat-option>
        </mat-select>
        <mat-error class="mt-2" *ngIf="
            cadastroTask.get('client').touched &&
            cadastroTask.get('client').errors?.required
          ">
          Obrigatório o cliente!
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="type == 'Epic'">
        <mat-label>Selecione o projeto</mat-label>
        <mat-select matNativeControl formControlName="project">
          <mat-option *ngFor="let project of projects" [value]="project.tag" required>
            {{ project.descricao }}
          </mat-option>
        </mat-select>
        <mat-error class="mt-2" *ngIf="
            cadastroTask.get('project').touched &&
            cadastroTask.get('project').errors?.required
          ">
          Obrigatório informar o projeto!
        </mat-error>
      </mat-form-field>

      <div class="row">
        <div class="col-lg-4">
          <mat-form-field>
            <mat-label>Data de Target</mat-label>
            <input matInput [disabled]="isLoading" [matDatepicker]="targetDateTarefa" formControlName="targetDate"
              placeholder="Data">
            <mat-datepicker-toggle matSuffix [for]="targetDateTarefa"></mat-datepicker-toggle>
            <mat-datepicker #targetDateTarefa></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-lg-4">
          <mat-form-field *ngIf="type == 'Task' || type == 'Ticket'">
            <mat-label>Horas estimadas</mat-label>
            <input type="number" matInput placeholder="Horas estimadas" name="estimate"
              formControlName="estimateHour" />
            <mat-error *ngIf="cadastroTask.get('estimateHour').errors?.pattern">
              O Formato aceito é 00.00!
            </mat-error>
            <mat-error *ngIf="cadastroTask.get('estimateHour').errors?.required">
              O Campo de hora estimada é obrigatório!
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4">
          <mat-form-field>
            <mat-label>Área Path</mat-label>
            <input matInput placeholder="Área Path" name="areaPath" formControlName="areaPath" />
            <mat-error *ngIf="cadastroTask.get('areaPath').errors?.required">
              O Campo é obrigatório!
            </mat-error>
          </mat-form-field>
        </div>
      </div>

    </form>

    <mat-form-field floatLabel="always" appearance="none">
      <mat-label>Cadastro Resumido</mat-label>
      <mat-slide-toggle [(ngModel)]="resumido" color="primary"> </mat-slide-toggle>
      <textarea matInput hidden></textarea>
    </mat-form-field>

    <hr [hidden]="resumido">
    <div [hidden]="resumido">
      <mat-label>Descrição:</mat-label>
      <ckeditor [editor]="Editor" [(ngModel)]="description" data-autoresize>
      </ckeditor>
    </div>
    <hr [hidden]="resumido">
    <div [hidden]="resumido">
      <mat-label>Critérios de Aceite:</mat-label>
      <ckeditor [editor]="Editor" [(ngModel)]="acceptance" data-autoresize>
      </ckeditor>
    </div>
    <hr [hidden]="resumido">
    <div [hidden]="resumido">
      <mat-label>Repro Steps:</mat-label>
      <ckeditor [editor]="Editor" [(ngModel)]="reproSteps" data-autoresize>
      </ckeditor>
    </div>
  </div>

  <div class="modal-actions" mat-dialog-actions align="end">
    <button class="text-white" mat-raised-button (click)="save($event)" color="primary"
      [disabled]="isLoading || cadastroTask.invalid">
      Salvar
    </button>
  </div>
</div>