<div id="wrapper">
    <mat-drawer-container class="custom-drawer mat-typography" autosize>
        <!-- <mat-drawer id="sidebar" [class]="collapsedClass" #drawer mode="side" disableClose="true" opened="true">
            <ul class="navigation">
                <div class="expandBtn">
                    <i (click)="toggleExpanded()" [class]="icon" style="font-size: x-large;"
                        matTooltip="Diminuir/Expandir Menu" matTooltipPosition="right"></i>
                </div>
                <li *ngFor="let menu of menuItems; index as i" routerLink="{{menu.url}}">
                    <ng-container *ngIf="!menu.children">
                        <a [routerLink]="menu.url" routerLinkActive="menu-active" style="display: flex;"
                            [matTooltip]="menu.title" matTooltipPosition="right">
                            <div class="menu-icone">
                                <i [class]="menu.icon"></i>
                            </div>
                            <span *ngIf="!isExpanded">{{menu.title}}</span>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="menu.children">
                        <mat-accordion class="sidebar-accordion" routerLinkActive="menu-active"
                            [routerLinkActiveOptions]="{exact: true}">
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header routerLinkActive="menu-active"
                                    [routerLinkActiveOptions]="{exact: true}" [collapsedHeight]="customCollapsedHeight"
                                    [expandedHeight]="customExpandedHeight" [matTooltip]="menu.title"
                                    matTooltipPosition="right">
                                    <mat-panel-title>
                                        <div class="menu-icone">
                                            <i [class]="menu.icon"></i>
                                        </div>
                                        <span *ngIf="!isExpanded">{{menu.title}}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <a *ngFor="let child of menu.children" class="sidebar-child" [routerLink]="child.url"
                                    routerLinkActive="menu-active" [matTooltip]="child.title"
                                    matTooltipPosition="right">
                                    <div class="menu-icone">
                                        <i [class]="child.icon"></i>
                                    </div>
                                    <span *ngIf="!isExpanded">{{child.title}}</span>
                                </a>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-container>
                </li>
            </ul>
            <div class="sidebar-background"></div>
        </mat-drawer> -->
        <div id="sidebarContent" class="sidebar-content">
            <router-outlet></router-outlet>
        </div>
    </mat-drawer-container>
</div>