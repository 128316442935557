import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalPerfilComponent } from 'src/app/@layout/modais/modal-perfil/modal-perfil.component';
import { ModalEmpresaComponent } from 'src/app/@modules/manage/admin/cadEmpresas/modal-empresa/modal-empresa.component';
import { ColaboradorService } from 'src/app/@modules/manage/colaboradores/services/colaboradores.service';
import { ModalAceiteConviteColaboradorComponent } from 'src/app/@shared/components/modal-aceite-convite-colaborador/modal-aceite-convite-colaborador.component';
import { TimerComponent } from 'src/app/@shared/components/timer/timer.component';
import { Menu } from 'src/app/models/menu';
import { ApontamentoService } from 'src/app/services/apontamento.service';
import { AuthService } from 'src/app/services/auth.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { MenuItemsService } from 'src/app/services/menu-items.service';
import { SharedService } from 'src/app/services/shared.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TimerService } from 'src/app/services/timer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default',
  templateUrl: './app-default.component.html',
  styleUrls: ['./app-default.component.scss']
})

export class AppDefaultComponent implements OnInit, OnDestroy {
  @ViewChild("working") working: MatSelectionList;
  @ViewChild("timerChild", { static: false }) timerChild: TimerComponent;

  envUrl = `${environment.api}Usuario/imagem/perfil/${StorageService.getLogon()}`;
  user: any = {};
  empresas = [];
  isLoading = true;
  runningTask: any;
  private playPauseStopUnsubscribe: any;
  private play: boolean;

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    public menu: MenuItemsService,
    private router: Router,
    private route: ActivatedRoute,
    public empresaService: EmpresasService,
    private conviteService: ColaboradorService,
    public timerService: TimerService,
    private _snackBar: MatSnackBar,
  ) {
    this.user = StorageService.getUsuario();
    this.carregarNomesEmpresas();
  }

  ngOnDestroy(): void {
    this.playPauseStopUnsubscribe?.unsubscribe();
  }

  ngOnInit(): void {

    // this.playPauseStopUnsubscribe = this.timerService.playPauseStop$.subscribe(
    //   (res: any) => {
    //     this.setPlay(res);
    //   }
    // );

    // this.apontamentoService.preAtual().subscribe((retorno) => {
    //   if (retorno) {
    //     this.runningTask = retorno;
    //     this.startNewTask(this.runningTask);
    //   }
    // });

    let id_convite = this.route.snapshot.queryParamMap.get('invite');
    if (id_convite) {
      this.conviteService.efetivar(id_convite).subscribe(dados => {
        if (dados.status == 200) {
          const convite = dados.body;
          const dialogRef = this.dialog.open(ModalAceiteConviteColaboradorComponent, {
            width: "440px",
            data: convite,
          });

          dialogRef.afterClosed().subscribe(async (response) => {
            if (response === 1) {

            }
          });
        }
      },
        (error: HttpErrorResponse) => {
          this.openSnackBar(error.error.errorMessage, "OK", 5000, 'danger-snackbar');
          // console.error(error);
        });
    }
    this.getUserMenus();
  }


  // openSnackBar('E-mail enviado com sucesso!','OK', 3000)
  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this._snackBar.open(message, action, { duration, panelClass });
  }


  // startNewTask(task: any): void {
  //   this.sharedService.startNewTask({task:task});
  // }

  private setPlay(res: any) {
    res.play ? (this.play = true) : (this.play = false);
  }

  menuItems = [];
  currentMenu = [];
  private getUserMenus() {
    this.menu.getMenus().subscribe(
      (response: Menu[]) => {
        this.menuItems = response;
        this.currentMenu = this.activeMenu();
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      },
      (error: HttpErrorResponse) => {
        console.error(error);
      });
  }

  public cadastrarEmpresa(empresa?: any) {
    const dialogRef = this.dialog.open(ModalEmpresaComponent, {
      width: "450px",
      data: { empresa },
    });
    dialogRef
      .afterClosed()
      .subscribe((response) => {
        if (response === "cancel") {
          return;
        } else {
          this.empresaService
            .detailsUserEmpresa()
            .subscribe((c: HttpResponse<any>) => {
              const redirectUrl = "/" + c.body[0].identificadorGuid.toUpperCase() + "/manage/cadastro/integracoes";
              this.router.navigateByUrl(redirectUrl);
              this.getUserMenus();
            });
        }
      });
  }

  private activeMenu() {
    let menus = this.menuItems?.filter(m => this.isActive(m))[0];
    return menus?.children;
  }

  isActive(item: Menu): boolean {
    return item?.children.some(m2 => this.router.isActive(m2.url, false)) ||
      (
        this.router.isActive(item.url, true)
      );
  }

  onlyFirstName(name) {
    return name ? name.replace(/ .*/, "") : "";
  }

  openProfileModal() {
    const dialogRef = this.dialog.open(ModalPerfilComponent, {
      width: "540px",
      data: { logon: StorageService.getLogon() },
    });
    dialogRef.afterClosed().subscribe(() => { });
  }

  logout() {
    this.authService.logout();
    location.reload();
  }

  carregarNomesEmpresas() {
    this.empresaService
      .detailsUserEmpresa()
      .subscribe(
        (data: any) => {
          this.empresas = data.body;
          setTimeout(() => {
            this.working?.selectAll();
          }, 200);
        },
        (error) => {
          console.error("Ocorreu um erro:", error);
        }
      )
    //.add(() => (this.isLoading = false));
  }

}
