import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs'; 
import { Cargo } from '../models/cargo';
import { map } from 'rxjs/operators';
import { Jornada } from '../models/Jornada';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class JornadaService {

  constructor(private http: HttpClient) { }

  getJornadas(ativo?: boolean): Observable<Jornada[]> {
    let params = new HttpParams();
    
    if (ativo != undefined && ativo != null && ativo.toString() != '') {
      params = params.append('Ativo', ativo.toString());
    }
 
    return this.http.get<Jornada[]>("Jornada/Listar", { params }).pipe(map(result => result));
  }

  
  getJornadasByEmpresaId(empresaId): Observable<Jornada[]> {
    return this.http.get<Jornada[]>(`Jornada/Listar/${empresaId}`, httpOptions) as Observable<Jornada[]>;
  }

  getJornadaById(id: number): Observable<any> {
    return this.http.get(`Jornada/Details/${id}`, httpOptions) as Observable<any>;
  }

  createJornada(jornada: Jornada): Observable<Jornada> {
    return this.http.post<Jornada>(`Jornada/create`, jornada, httpOptions);
  }

  editJornada(jornada: Jornada): Observable<Jornada> {
    return this.http.put<Jornada>(`Jornada/Edit`, jornada, httpOptions);
  }

  deleteJornada(jornada: Jornada): Observable<Jornada> {
    return this.http.delete<Jornada>(`Jornada/delete/${jornada.id}`, httpOptions);
  }

  // OLD
  getJornada(): Observable<any> {
    return this.http.get(`jornada/getJornada`, httpOptions) as Observable<any>;
  }

}
