<div class="custom-modal">
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="arastavel">
    <div class="header">
      <h2 mat-dialog-title>Apontamento</h2>
      <mat-icon mat-dialog-close="cancel">close</mat-icon>
    </div>
  </div>
  <mat-divider class="mb-4"></mat-divider>
  <mat-progress-bar mode="indeterminate" [hidden]="!isLoading" color="primary"></mat-progress-bar>
  <div mat-dialog-content class="content apontamento" *ngIf="!isLoading">
    <form [formGroup]="apontamentoForm" autocomplete="off">
      <div *ngIf="this.task">
        <mat-card class="card-tfs slide d-flex justify-content-center align-items-center text-selection-none">
          <mat-card-content class="tarefas-container text-center card-content-tfs p-0">
            <div class="tarefa-item">
              <div class="tarefa-item-actions" [ngStyle]="{'background-color': bgColor(task.status)}">
                <div class="remaining-work">
                  <small></small>
                </div>
              </div>
              <div class="tarefa-item-content">
                <!-- <p class="line-1">{{task.originalEstimate}}hrs - {{task.completedWork}}hrs = {{task.remaining|Work}}</p> -->
                <p [hidden]="!task.idAzureDevops" class="line-3">
                  <a [href]="itemParentUrl(task)" target="_blank" matTooltip="Abrir no board">
                    <strong>{{task.parentTitle ? task.parentTitle: task.referenciaDescricao}}</strong>
                  </a>
                </p>
                <p [hidden]="task.idAzureDevops" class="line-3">
                  <strong>{{task.parentTitle ? task.parentTitle: task.referenciaDescricao}}</strong>
                </p>

                <div [hidden]="!task.idAzureDevops" class="d-flex" style="align-items: center;flex-grow: 1;">
                  <p class="line-2">
                    <a [href]="itemUrl(task)" target="_blank" matTooltip="Abrir no board">
                      <img [src]="iconUrl(task)" class="icon-board" /><span>{{task.title}}</span>
                    </a>
                  </p>
                </div>

                <p class="line-1">
                  <small>{{task.sprintName}} | #{{task.tag}}</small>
                </p>
              </div>
              <div class="p-2">
                <button mat-icon-button color="accent" matTooltip="Clique para alterar o Work Item relacionado"
                  (click)="selectTask()"><mat-icon>edit</mat-icon></button>
              </div>
            </div>
            <!-- <button *ngIf="task.idAzureDevops" class="mt-3" (click)="openEditTask(task)" mat-raised-button
              class="botaoeditar"><mat-icon>edit</mat-icon>&nbsp;Editar Tarefa</button> -->
          </mat-card-content>
        </mat-card>
      </div>
      <fieldset [disabled]="isLoading">
        <div class="d-flex">
          <mat-form-field class="field-data">
            <input matInput [disabled]="isLoading" [matDatepicker]="dataApontamento" formControlName="date"
              placeholder="Data" [(ngModel)]="date" (dateChange)="updateSprint()">
            <mat-datepicker-toggle matSuffix [for]="dataApontamento"></mat-datepicker-toggle>
            <mat-datepicker #dataApontamento></mat-datepicker>
            <mat-error *ngIf="apontamentoForm.get('date').touched && apontamentoForm.get('date').errors?.required ">
              Obrigatório informar a data!
            </mat-error>
          </mat-form-field>
          <div style="flex-grow: 1;justify-content: flex-end;">
            <mat-form-field class="field-time">
              <input matInput type="time" [disabled]="isLoading" formControlName="startHour" placeholder="Hora Início"
                [(ngModel)]="startHour" (blur)="validHour()">
              <mat-error
                *ngIf="apontamentoForm.get('startHour').touched && apontamentoForm.get('startHour').errors?.required">
                Obrigatório informar a hora início!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="field-time">
              <input matInput type="time" [disabled]="isLoading" formControlName="endHour" placeholder="Hora Fim"
                [(ngModel)]="endHour" (blur)="validHour()">
              <mat-error
                *ngIf="apontamentoForm.get('endHour').touched && apontamentoForm.get('endHour').errors?.required ">
                Obrigatório informar a hora fim!
              </mat-error>
              <mat-error *ngIf="apontamentoForm.get('endHour')?.hasError('invalidHour')">
                Hora fim deve ser maior que hora início.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div>
          <mat-card class="card-select" [hidden]="clients?.length <= 1">
            <div class="item1">
              <mat-form-field>
                <mat-label>Selecione o cliente</mat-label>
                <mat-select matNativeControl (selectionChange)="changeClient($event.value)" [disabled]="isLoading"
                  formControlName="client" [(ngModel)]="idCliente">
                  <mat-option disabled value="">Selecione o cliente</mat-option>
                  <mat-option *ngFor="let client of clients" [value]="client.id">
                    {{client.nome}}
                  </mat-option>
                </mat-select>
                <mat-error class="mt-2"
                  *ngIf="apontamentoForm.get('client').touched && apontamentoForm.get('client').errors?.required ">
                  Obrigatório o cliente!
                </mat-error>
              </mat-form-field>
            </div>
            <div class="item2" [hidden]="projects?.length <= 1">
              <mat-form-field>
                <mat-label>Selecione o projeto</mat-label>
                <mat-select matNativeControl formControlName="project" (selectionChange)="changeProjeto($event.value)"
                  [disabled]="isLoading" [(ngModel)]="idProjeto">
                  <mat-option *ngFor="let project of projects" [value]="project.id"> {{ project.descricao }}
                  </mat-option>
                </mat-select>
                <mat-error class="mt-2"
                  *ngIf="apontamentoForm.get('project').touched && apontamentoForm.get('project').errors?.required ">
                  Obrigatório informar o projeto!
                </mat-error>
              </mat-form-field>
            </div>
          </mat-card>

          <!-- <mat-card class="card-select" [hidden]="tasks?.length <= 1">
            <div class="p-0">
              <mat-form-field>
                <mat-label>Tarefa</mat-label>
                <mat-select matNativeControl [disabled]="isLoading" formControlName="idAzureDevops" [(ngModel)]="task"
                  (selectionChange)="changeTask(task)">
                  <mat-option *ngFor="let task of tasks" [value]="task">
                    {{ task.title }}
                  </mat-option>
                </mat-select>
                <mat-error class="my-2"
                  *ngIf="apontamentoForm.get('idAzureDevops').touched && apontamentoForm.get('idAzureDevops').errors?.required ">
                  Obrigatório informar uma tarefa!
                </mat-error>
              </mat-form-field>
            </div>
          </mat-card> -->
        </div>

        <div>
          <mat-label>Informações adicionais:</mat-label>
          <ckeditor [editor]="Editor" [(ngModel)]="observacoes" formControlName="obs" data-autoresize
            [config]="{toolbar: [ 'bold', 'italic', 'link', 'image' ] }">

          </ckeditor>
          <mat-error *ngIf="apontamentoForm.get('obs').touched && apontamentoForm.get('obs').errors?.required ">
            Obrigatório informar as observações!
          </mat-error>
        </div>
        <!-- <mat-form-field style="margin-top: -12px">
                <mat-label>Observações:</mat-label>
                <textarea matInput
                  data-autoresize
                  placeholder="Observações"
                  [disabled]="isLoading"
                  [(ngModel)]="observacoes"
                  name="obs"
                  formControlName="obs"></textarea>
                <mat-error *ngIf="apontamentoForm.get('obs').touched && apontamentoForm.get('obs').errors?.required ">
                  Obrigatório informar as observações!
                </mat-error>
              </mat-form-field> -->
      </fieldset>
    </form>
  </div>
  <div class="modal-actions d-flex" style="justify-content:space-between" mat-dialog-actions>
    <div>
      <i *ngIf="!task && !isEdicao" class="fas fa-utensils" style="cursor: pointer;" (click)="almoco()"></i>
      <button mat-icon-button [hidden]="!isEdicao" [disabled]="isLoading" (click)="deletar($event)"
        matTooltip="Excluir apontamento">
        <mat-icon>delete_forever</mat-icon> </button>
    </div>
    <div>

      <button class="text-white" mat-raised-button color="primary" (click)="salvarApt($event)"
        [disabled]="disableButtonApt()">Salvar Apontamento
      </button>
    </div>
  </div>
</div>