import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, ReplaySubject } from "rxjs";
import { Injectable, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { StorageService } from "./storage/storage.service";
import { concatMap, map, switchMap } from "rxjs/operators";
import { EmpresasService } from "./empresas.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

export class User {
  auth_Token: string;
  id: string;
  company_id: number;
  is_admin: boolean;
  company_logo: string;
  company_name: string;
  company_rule: boolean;
}

export class UserInput {
  email?: string;
  password?: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnInit {
  constructor(private http: HttpClient, private router: Router, private empresaService: EmpresasService) { }

  ngOnInit(): void { }

  authenticate(credentials: any): Observable<any> {
    return this.http.post(".auth/", credentials, httpOptions);
  }

  logAs(credentials: any): Observable<any> {
    return this.http.post(".auth/log-as", credentials, httpOptions);
  }

  setState(data: any) {
    StorageService.setUsuario(data);
  }

  setAccessToken(at: string) {
    StorageService.setToken(at);
  }

  setSlug(data: any) {
    sessionStorage["slug"] = data;
  }

  getActiveSlug() {
    return sessionStorage["slug"];
  }

  getClientToken(client_id: number) {
    return this.http.get(`.auth/${client_id}`, httpOptions);
  }

  isAuthenticated() {
    return !!StorageService.getToken();
  }

  logout() {
    StorageService.deleteUsuario();
    this.router.navigate(["/"]);
  }

  forgotPassword(email): Observable<any> {
    return this.http.get(
      `.auth/forgotPassword/${email}`,
      httpOptions
    ) as Observable<any>;
  }

  exiteUsuarioByEmail(email): Observable<any> {
    return this.http.get(
      `Usuario/ExisteUsuarioByEmail/${email}`,
      httpOptions
    ) as Observable<any>;
  }

  existeUsuarioJiraSemEmail(jiraaccountid): Observable<any> {
    return this.http.get(
      `Usuario/existeUsuarioJiraSemEmail/${jiraaccountid}`,
      httpOptions
    ) as Observable<any>;
  }

  existeUsuarioEmailTFSSemEmail(emailTFS): Observable<any> {
    return this.http.get(
      `Usuario/existeUsuarioEmailTFSSemEmail/${emailTFS}`,
      httpOptions
    ) as Observable<any>;
  }

  existeUsuarioCadastradoByEmail(email): Observable<any> {
    return this.http.get(
      `Usuario/existeUsuarioCadastradoByEmail/${email}`,
      httpOptions
    ) as Observable<any>;
  }

  checkAuthenticationCode(code: string, email: string): Observable<any> {
    return this.http.get(
      `.auth/checkAuthenticationCode/${code}/${email}`,
      httpOptions
    ) as Observable<any>;
  }

  signUpAuthenticationCode(email, nome): Observable<any> {
    return this.http.get(
      `.auth/signUpAuthenticationCode/${email}/${nome}`,
      httpOptions
    ) as Observable<any>;
  }

  updatePassword(obj: UserInput): Observable<any> {
    return this.http.put(
      `.auth/updatePassword`,
      obj,
      httpOptions
    ) as Observable<any>;
  }
  checkSecondStepAuthenticationCodeJira(
    code: string,
    jiraAccountId: string
  ): Observable<any> {
    return this.http.post(
      `.auth/checkSecondStepAuthenticationCode`,
      { code: code, jiraaccountid: jiraAccountId },
      httpOptions
    ) as Observable<any>;
  }

  secondStepAuthentication(userName: string): Observable<any> {
    return this.http.get(
      `.auth/secondStepAuthentication/${userName}`,
      httpOptions
    ) as Observable<any>;
  }

  checkSecondStepAuthenticationCode(
    code: string,
    userName: string
  ): Observable<any> {
    return this.http.post(
      `.auth/checkSecondStepAuthenticationCode`,
      { code: code, username: userName },
      httpOptions
    ) as Observable<any>;
  }

  getUserByUserNameAndPassword(
    username: string,
    password: string
  ): Observable<any> {
    return this.http.post(
      `.auth/getUserByUserNameAndPassword`,
      { username: username, password: password },
      httpOptions
    ) as Observable<any>;
  }

  getUserClients(): Observable<any> {
    return this.http.get(
      `.auth/getUserClients`,
      httpOptions
    ) as Observable<any>;
  }

  verifyPassword(obj: any): Observable<any> {
    return this.http.post(
      `.auth/verifyPassword`,
      obj,
      httpOptions
    ) as Observable<any>;
  }

  getUserPermissions(): Observable<any> {
    return this.http.get(
      `.auth/getUserPermissions`,
      httpOptions
    ) as Observable<any>;
  }

  confirmCadastro(obj: any): Observable<any> {
    return this.http.post(
      `Usuario/createNewUser`,
      obj,
      httpOptions
    ) as Observable<any>;
  }


  checkFeature(
    nomeModulo: string,
    guidEmpresa: string
  ): Observable<| boolean | UrlTree> {
    return this.checkFeatureRequest(nomeModulo, guidEmpresa).pipe(
      map((response) => {
        if (response.status === 200) {
          this.setState(response.body);
          return true;
        } else if (response.status === 209) {
          //negado porém nao existe admin na empresa - redirecionar para tela específica correspondente
          this.router.navigate(["/requisitar-acesso/" + guidEmpresa]);
          return false;
        }
        else if (response.status === 403) {
          this.router.navigate(["/unauthorized"]);
          return false;
        }
      })
    );
  }

  redirectToCompany(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<| boolean | UrlTree> {

    return this.empresaService.detailsUserEmpresa().pipe(
      map((response: HttpResponse<any[]>) => {
        if (response.status === 200 && response.body.length) {
          const empresa: string = response.body[0].identificadorGuid;
          this.router.navigate(['/' + empresa.toUpperCase() + state.url]);
          return true;
        }
        else {
          this.router.navigate(["/unauthorized"]);
          return false;
        }
      }));
  }

  checkFeatureRequest(
    nomeModulo: string,
    guidEmpresa: string
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `.auth/checkFeature/${guidEmpresa}/${nomeModulo}`,
      { observe: "response" }
    );
  }

  checkCompany(projectName: string, organizationName: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `DevOps/verificarEmpresa?ProjectName=${projectName}&OrganizationName=${organizationName}`,
      { observe: "response" }
    );
  }

  requisitarAcesso(perfilAcessoId, empresaId) {
    return this.http.post(
      `convite/colaborador/requisitar-acesso/${empresaId}`,
      { perfilAcessoId },
      httpOptions
    ) as Observable<any>;
  }
}
