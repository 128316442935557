import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cargo } from '../models/cargo';
import { map } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { StorageService } from './storage/storage.service';
import { AuthenticationResult } from '@azure/msal-browser';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AzureSSOService {

  constructor(private http: HttpClient,
    private msAuthService: MsalService) { }

  loginWithMicrosoft() {
    const loginRequest = {
      scopes: ["user.read"], // Adicione outras permissões necessárias aqui
    };

    return this.msAuthService.loginPopup(loginRequest);
  }
}
