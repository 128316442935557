import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TimerComponent } from 'src/app/@shared/components/timer/timer.component';
import { ExpedienteDia, ExpedienteHorario, ExpedienteTarefa } from 'src/app/models/expedientes';
import { ApiAzureDevopsService, FiltroGetSprintDevOps, FiltroGetTasksDevOps, FiltroSyncTasksDevOps } from 'src/app/services/api-azure-devops.service';
import { SharedService } from 'src/app/services/shared.service';
import { TimerService } from 'src/app/services/timer.service';
import { WorkComponent } from '../work.component';
import { DatePipe } from '@angular/common';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UsuarioService } from 'src/app/services/usuario.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuarioIndicadores } from 'src/app/models/usuario-indicadores';
import { ApontamentoService } from 'src/app/services/apontamento.service';
import { ExpedientePlanejamentoComponent } from './expediente-planejamento/expediente-planejamento.component';
import { ModalSelecaoTarefaComponent } from '../../board/devops-tasks/modal-selecao-tarefa/modal-selecao-tarefa.component';
import { ModalApontamentosComponent } from '../../apontamentos/modal-apontamentos/modal-apontamentos.component';
import { EditWorkitemComponent } from '../edit-workitem/edit-workitem.component';
import { CreateWorkitemComponent } from '../create-workitem/create-workitem.component';
import { DateToString } from 'src/app/@shared/helpers/helpers';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
@Component({
  selector: 'app-work-day',
  templateUrl: './work-day.component.html',
  styleUrls: ['./work-day.component.scss']
})

export class WorkDayComponent implements OnInit, OnDestroy {
  @Input() parent: WorkComponent;

  _day: ExpedienteDia;

  get day(): ExpedienteDia {
    return this._day;
  }
  isLoadingApontamentos: boolean;
  @Input() set day(value: ExpedienteDia) {
    this._day = value;
    // console.log('input dia: ', value);
    if (value) {
      this.tasks = this.day.tarefas;

      this.day.inicioExpediente = this.day.horarios[0].horarioInicio.substring(0, 5);
      this.day.fimExpediente = this.day.horarios.slice(-1)[0].horarioFim.substring(0, 5);

      this.permiteApontarTudo = new Date(this._day.dataRef) < new Date();
    }
  }
  @Input() isLoading: Boolean;
  @Input() exibeApontarTudo: Boolean = true;

  toggleDia = false;
  visionChange(change: MatButtonToggleChange) {
    this.toggleDia = change.value;
  }

  @ViewChild('timerChild', { static: false }) timerChild: TimerComponent;

  isVisible = false;
  permiteApontarTudo = false;
  sprintName = '';
  sprintStartDate: any;
  sprintFinishDate: any;
  mesSelecionado = ((new Date()).getMonth() + 1).toString();
  anoSelecionado = (new Date()).getFullYear().toString();
  sprint: any = {};
  tasks: ExpedienteTarefa[] = [];
  selectedItem: any;
  widthPorcentagemHoras: any;
  porcentagemHoras: any = 0.00;

  widthPorcentagemTarefas: any;
  porcentagemTarefas: any = 0.00;
  indicadores: UsuarioIndicadores;
  status: any = ['To Do', 'In Progress', 'Done'];
  selectedOptions = new FormControl(['To Do', 'In Progress']);

  private playPauseStopUnsubscribe: any;
  private play: boolean;
  public appointments: any = [];
  messages = [];
  subscription: Subscription;
  origem = 'devops';

  constructor(
    public apontamentoService: ApontamentoService,
    private usuarioService: UsuarioService,
    private snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
    public devops: ApiAzureDevopsService,
    public dialog: MatDialog,
    public userService: UsuarioService,
    public timerService: TimerService,
    private sharedService: SharedService,
    private datePipe: DatePipe
  ) {
    this.subscription = this.sharedService.getMessageDevopsGrid()
      .subscribe(message => {
        if (message) {
          this.messages.push(message);
          //this.getWorkItems();
        } else {
          this.messages = [];
        }
      });
  }

  ngOnInit() {
    this.playPauseStopUnsubscribe = this.timerService.playPauseStop$
      .subscribe((res: any) => this.setPlay(res));

  }

  ngOnDestroy() {
    this.playPauseStopUnsubscribe.unsubscribe();
    this.subscription.unsubscribe();
  }

  async drop(event: CdkDragDrop<ExpedienteHorario[]>) {
    moveItemInArray(this.day.horarios, event.previousIndex, event.currentIndex);
    await this.updateExpediente();
  }

  editItem(horario: ExpedienteHorario) {
    console.log(horario);
    const bottomSheetRef = this._bottomSheet.open(ExpedientePlanejamentoComponent,
      {
        data: {
          expediente: this.day,
          editing: horario
        }
      });

    bottomSheetRef.afterDismissed().subscribe(async (response) => {
      if (response != "cancel") {
        await this.updateExpediente();
      }
    });
  }

  openBottomSheet(): void {
    const bottomSheetRef = this._bottomSheet.open(ExpedientePlanejamentoComponent,
      {
        data: { expediente: this.day }
      });

    bottomSheetRef.afterDismissed().subscribe(async (response) => {
      if (response != "cancel") {
        await this.updateExpediente();
      }
      // setTimeout(() => {
      //   window.location.reload();  
      // }, 5000);      
    });
  }

  async updateExpediente() {
    //remover horarios com duraçao zerada
    this.day.horarios = this.day.horarios.filter(h => this.totalMinutes(h) > 0);

    this.updateHorariosArray();

    let dataFimExpediente = new Date(this.day.horarios[0].dataInicio);
    dataFimExpediente = new Date(this.datePipe.transform(dataFimExpediente, "yyyy-MM-dd") + " " + this.day.fimExpediente);
    const expedienteInput = {
      inicioExpediente: this.day.inicioExpediente,
      fimExpediente: this.day.fimExpediente,
      definicoes: this.day.horarios
        .filter(h => new Date(h.dataInicio) < dataFimExpediente)
        .map((it) => {
          return {
            dataRef: this.datePipe.transform(it.dataInicio, 'yyyy-MM-dd'),
            usuarioId: it.usuarioId,
            horarioInicio: it.horarioInicio,
            horarioFim: it.horarioFim,
            referenciaId: it.referenciaId,
            descricao: it.descricaoExpediente
          };
        })
    };
    await this.updateExpedienteWork(expedienteInput);
  }

  updateExpedienteWork(input) {
    this.isLoading = true;
    console.log('parent updateExpediente:', input);
    this.usuarioService.putExpedientesData(input).subscribe(response => {
      this.openSnackBar('Atualizado com sucesso!', 'OK', 3000, 'success-snackbar');
      //window.location.reload();
      //this.getIndicadores();
      this.getExpediente();
      this.isLoading = false;
    },
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        console.error(error);
        this.openSnackBar('Erro ao atualizar expediente!', 'OK', 3000, 'danger-snackbar');
      })
  }


  // getIndicadores() {
  //   this.usuarioService.getIndicadores().subscribe(dados => {
  //     this.indicadores = dados;
  //   },
  //     (error: HttpErrorResponse) => {
  //       console.error(error);
  //       this.openSnackBar('Erro ao consultar indicadores!', 'OK', 3000, 'danger-snackbar');
  //     });
  // }

  async setDefault() {
    const expedienteInput = {
      inicioExpediente: this.day.inicioExpediente,
      fimExpediente: this.day.fimExpediente,
      definicoes: this.day.horarios.map((it) => {
        return {
          dataRef: null,
          usuarioId: it.usuarioId,
          horarioInicio: it.horarioInicio,
          horarioFim: it.horarioFim,
          referenciaId: it.referenciaId,
          descricao: it.descricaoExpediente
        };
      })
    };

    await this.updateExpedienteWork(expedienteInput);

  }

  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this.snackBar.open(message, action, { duration, panelClass });
  }
  updateHorariosArray() {
    const qtdItens = this.day.horarios.length;

    for (let i = 0; i < qtdItens; i++) {
      const element = this.day.horarios[i];

      let anterior: ExpedienteHorario;
      let proximo: ExpedienteHorario;
      let minutes = this.totalMinutes(element);

      if (i > 0) {
        anterior = this.day.horarios[i - 1];
        element.dataInicio = anterior.dataFim;
        element.horarioInicio = anterior.horarioFim;
        element.prev = anterior;
      } else {
        //definir prev como null
        // Criar uma data
        const data = new Date(this.day.dataRef);

        // String de exemplo de horário
        const horarioString = this.day.inicioExpediente;

        // Dividir a string de horário em horas e minutos
        const [horas, minutos] = horarioString.split(":").map(Number);

        // Definir o novo horário na data
        data.setHours(horas);
        data.setMinutes(minutos);
        element.dataInicio = data;

        element.prev = null;
      }

      if (i < qtdItens - 1) {
        proximo = this.day.horarios[i + 1];
        element.next = proximo;
      } else {
        element.next = null;
      }

      let dtfim = new Date(element.dataInicio);
      dtfim.setMinutes(dtfim.getMinutes() + minutes);

      element.dataFim = DateToString(dtfim);
      element.horarioFim = this.datePipe.transform(dtfim, 'HH:mm');

      // if (element.next) {
      //   element.next.dataInicio = element.dataFim;
      //   element.next.horarioInicio = element.horarioFim;
      // }
    }
  }


  totalMinutes(horario: ExpedienteHorario) {
    if (!horario) return 0;
    return this.diff_minutes(new Date(horario.dataFim), new Date(horario.dataInicio));
  }

  diff_minutes(dt2, dt1) {

    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));

  }

  // async changeInicioExpediente(e) {
  //   this.inicioExpediente = e;
  //   await this.updateExpediente();
  // }

  // async changeFimExpediente(e) {
  //   this.fimExpediente= e;
  //   await this.updateExpediente();
  // }

  private setPlay(res: any) {
    (res.play) ? this.play = true : this.play = false;
  }

  // playTimer() {
  //   this.timerService.playTimer();
  // }

  // pauseTimer() {
  //   this.timerService.pauseTimer();
  // }

  // stopTimer() {
  //   this.timerService.stopTimer();
  // }

  statusChange(event?: any) {
    //this.getWorkItems();
  }

  async openNovoApontamento(apontamento?: object, id?: number, date?: any, callback = null) {
    if (id) {
      this.oppenApontamentosModal(apontamento, id, date, null, callback);
    }
    else {
      await this.openNovaTarefa(date, (task) => {
        this.oppenApontamentosModal(apontamento, id, date, task, callback);
        this.isLoading = false;
      });
    }
  }

  async openNovaTarefa(date?: any, callback = null, responsavelId = null) {

    const dialogTarefaRef = this.dialog.open(ModalSelecaoTarefaComponent, {
      disableClose: true,
      width: '740px',
      data: { tela: 'timer', dataRef: date, responsavelId: responsavelId }
    });

    dialogTarefaRef.afterClosed().subscribe(async task => {
      if (task != 'cancel') {
        if (callback) setTimeout(() => { callback(task); }, 100);
      }
    });
  }

  oppenApontamentosModal(apontamento?: object, id?: number, date?: any, task?: any, callback = null) {
    const dialogRef = this.dialog.open(ModalApontamentosComponent, {
      disableClose: true,
      width: '500px',
      data: {
        tela: 'apontamentos',
        apontamento: { apontamento },
        parametros: { id, date, task }
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (callback) callback();

      if (result && result != 'cancel') {
        if (typeof result == 'number') {
          //retornando id da task para edição
          this.openEditTask(result);
        }
        //this.appointments = [];
        await this.refreshAll();
      }
    });
  }


  async refreshAll() {
    await this.getCards();
    await this.getExpediente();
  }


  async getCards() {
    //this.isLoadingApontamentos = true;
    await this.apontamentoService
      .getcards(this.mesSelecionado, this.anoSelecionado, null, null)
      .subscribe(response => {
        this.appointments = response;
        this.isLoadingApontamentos = false;
      });
    // this.child.listHours();
  }

  getExpediente() {
    this.isLoading = true;
    this.usuarioService.getExpedientes().subscribe(response => {
      this.parent.datasExpedientes = response;
      //this.getIndicadores();
      this.isLoading = false;
    },
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        console.error(error);
        this.openSnackBar('Erro ao consultar expedientes!', 'OK', 3000, 'danger-snackbar');
      });
  }


  openDialog(task: any, callback = null): void {

    if (!task.referenciaId) {

      this.openNovoApontamento(null, null, task.dataInicio, callback);

    } else {

      const dialogRef = this.dialog.open(ModalApontamentosComponent, {
        disableClose: true,
        width: '540px',
        data: {
          tela: 'devops',
          parametros: {
            idSprint: this.sprint.id,
            sprintName: this.sprint.name,
            date: task.dataInicio,
            task
          }
        }
      });

      dialogRef.afterClosed().subscribe(retorno => {

        if (callback) callback();
        this.getCards();
        this.refreshAll();
        // if (retorno && retorno != 'cancel') {
        //   if (typeof retorno == 'number') {
        //     //retornando id da task para edição
        //     //this.openEditTask(retorno);
        //   }
        //   if (callback) callback();
        //   this.getCards();
        //   this.refreshAll();
        // }

        //if (retorno != 'cancel') {

        //this.getWorkItems();
        //}
      });
    }
  }


  openEditTask(idAzureDevops, callback = null) {
    const dialogRef = this.dialog.open(EditWorkitemComponent, {
      disableClose: true,
      width: '100%',
      data: {
        idAzureDevops: idAzureDevops
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result != 'cancel') {
        if (callback != null) callback();
      }
    });
  }

  saveAllDay(tasks: any[]) {
    var qtd = tasks.length;
    var idx = 0;
    var call_next = () => {
      if (idx < qtd) {
        this.openDialog(tasks[idx], call_next);
        idx++;
      }
    };
    call_next();
  }

  openCreateWorkItem(date?: any, callback = null, responsavelId = null) {
    const dialogRef = this.dialog.open(CreateWorkitemComponent, {
      disableClose: true,
      width: '940px',
      data: { tela: 'timer', dataRef: date, responsavelId: responsavelId }
    });

    dialogRef.afterClosed().subscribe(async task => {
      if (task != 'cancel') {
        if (callback) setTimeout(() => { callback(task); }, 100);
      }
    });
  }

  openNewTfs() {
    const dialogRef = this.dialog.open(ModalSelecaoTarefaComponent, {
      disableClose: true,
      width: '740px',
      data: { tela: 'devops' }
    });

    dialogRef.afterClosed()
      .subscribe(async retorno => {
        if (retorno != 'cancel') {
          //await this.getWorkItems();
        }
      });
  }

  bgColor(status) {
    switch (status) {
      case 'To Do': return '#5199FF';
      case 'In Progress': return '#F3A712';
      case 'Commited': return '#939598';
      case 'Done': return '#00CF91';
      default: return 'gainsboro';
    }
  }
}
