import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export interface Empresa {
  id?: number;
  identificador?: string;
  razaoSocial?: string;
  nomeFantasia?: boolean;
  cpnj?: string;
  totalCount?: number;
  itensPorPagina?: number;
  paginaAtual?: number;
}

const filtro = (obj: Empresa) => {
  const filter = Object.entries(obj).filter(([k, v]) => v);
  return new URLSearchParams(filter);
};

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class EmpresasService {
  constructor(private http: HttpClient) { }

  get(empresa?: Empresa): Observable<Empresa[]> {
    return this.http.get<Empresa[]>(
      `Empresa/listar?${filtro(empresa)}`,
      httpOptions
    );
  }

  getById(id: number): Observable<any> {
    return this.http.get(
      `Empresa/details/${id}`,
      httpOptions
    ) as Observable<any>;
  }

  create(empresa: Empresa): Observable<Empresa> {
    return this.http.post<Empresa>(`Empresa/create`, empresa, httpOptions);
  }

  edit(empresa: Empresa): Observable<Empresa> {
    return this.http.put<Empresa>(`Empresa/edit`, empresa, httpOptions);
  }

  delete(empresa: Empresa): Observable<Empresa> {
    return this.http.delete<Empresa>(
      `Empresa/delete/${empresa.id}`,
      httpOptions
    );
  }

  validateCnpj(cnpj: string): Observable<any> {
    return this.http.get<any>(`Empresa/validateCnpj?cnpj=${cnpj}`, httpOptions);
  }

  validateRazaoSocial(razaoSocial: string): Observable<any> {
    return this.http.get<any>(`Empresa/validateRazaoSocial?razaoSocial=${razaoSocial}`, httpOptions);
  }

  detailsUserEmpresa(): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(
      `Empresa/detailsUserEmpresa`,
      { observe: "response" }
    );
  }

  getAllCompanies(obj): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `DevOps/GetCompany`, obj,
      { observe: "response" }
    );
  }

  setFirstOrganization(obj): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      `DevOps/CompanyInOrganization`, obj,
      { observe: "response" }
    );
  }
}
