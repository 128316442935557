import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { EmpresasService } from "src/app/services/empresas.service";

export interface Empresa {
  id?: any;
  razaoSocial: string;
  nomeFantasia: string;
  cnpj: string;
}

@Component({
  selector: "app-modal-empresa",
  templateUrl: "./modal-empresa.component.html",
  styleUrls: ["./modal-empresa.component.scss"],
})
export class ModalEmpresaComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean;
  isEditable = false;

  empresa: any = {
    id: 0,
    razaoSocial: "",
    nomeFantasia: "",
    cnpj: "",
  };


  constructor(
    private fb: FormBuilder,
    private service: EmpresasService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalEmpresaComponent>,
    private cdr: ChangeDetectorRef,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.empresa !== undefined) {
      this.isEditable = true;
      this.empresa = data.empresa;
      this.fillDetails(data.empresa.id);
    }
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.createForm();
  }

  public createForm() {
    this.form = this.fb.group({
      razaoSocial: ["", Validators.required],
      nomeFantasia: ["", Validators.required],
      cnpj: ["", Validators.required],
    });
  }

  public save(event) {
    if (this.isEditable) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {
    if (this.form.status === "VALID") {
      const obj = {
        id: null,
        razaoSocial: this.form.value.razaoSocial,
        nomeFantasia: this.form.value.nomeFantasia,
        cnpj: this.form.value.cnpj,
      };
      this.isLoading = true;
      this.service.create(obj).subscribe({
        next: (e) => {
          debugger;
          this.dialogRef.close(e);
          this.openSnackBar('Empresa criada com sucesso!', 'OK', 5000, 'success-snackbar');
          if (this.data.redirect !== false) {
            this.router
              .navigate(['/' + e.identificador.toUpperCase() + '/manage/cadastro/integracoes'])
              .then(() => { window.location.reload(); });
          }
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }
  }

  private edit() {
    if (this.form.status === "VALID") {
      const obj = {
        id: this.empresa.id,
        razaoSocial: this.form.value.razaoSocial,
        nomeFantasia: this.form.value.nomeFantasia,
        cnpj: this.form.value.cnpj,
      };
      this.isLoading = true;
      this.service.edit(obj).subscribe({
        next: (e) => {
          this.dialogRef.close(e);
          this.openSnackBar("Empresa editada com sucesso!", "OK", 5000, 'success-snackbar');
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }
  }

  public fillDetails(id: number): void {
    this.isLoading = true;
    this.service.getById(id).subscribe({
      next: (empresa: Empresa) => {
        this.form.patchValue({
          razaoSocial: empresa.razaoSocial,
          nomeFantasia: empresa.nomeFantasia,
          cnpj: empresa.cnpj,
        });
        //this.form.get('cnpj').disable();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  public validateCNPJ() {
    if (this.empresa.cnpj === this.form.value.cnpj) {
      return;
    }
    if (this.validaCNPJ(this.form.value.cnpj) == true) {
      this.isLoading = true;
      this.service.validateCnpj(this.form.value.cnpj).subscribe({
        next: (validate: any) => {
          if (!validate.sucesso) {
            this.form.get('cnpj').setValue("");
            return this.openSnackBar(validate.mensagem, "OK", 5000, 'warning-snackbar');
          } else {
            null
          }
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }
    else {
      this.form.get('cnpj').setValue("");
      return this.openSnackBar("CNPJ Inválido", "OK", 5000, 'warning-snackbar');
    }
  }

  public validaCNPJ(cnpj: string): boolean {
    var b: number[] = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    var c: string = String(cnpj).replace(/[^\d]/g, '')

    if (c.length !== 14)
      return false

    if (/0{14}/.test(c))
      return false

    for (var i = 0, n = 0; i < 12; n += Number(c[i]) * b[++i]);
    if (c[12] !== (((n %= 11) < 2) ? '0' : String(11 - n))) {
      return false
    }

    for (var i = 0, n = 0; i <= 12; n += Number(c[i]) * b[i++]);
    if (c[13] != (((n %= 11) < 2) ? '0' : String(11 - n))) {
      return false
    }

    return true
  }

  public validateRazaoSocial() {
    if (this.empresa.razaoSocial === this.form.value.razaoSocial) {
      return;
    }
    this.isLoading = true;
    this.service.validateRazaoSocial(this.form.value.razaoSocial).subscribe({
      next: (validate: any) => {
        if (!validate.sucesso) {
          this.form.get('razaoSocial').setValue("");
          return this.openSnackBar(validate.mensagem, "OK", 5000, 'warning-snackbar');
        } else {
          return null;
        }
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this.snackBar.open(message, action, { duration, panelClass });
  }
}
