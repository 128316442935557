export const environment = {
  language: 'pt-BR',
  production: true,
  api: 'https://corp-api.joit.com.br/',
  recaptchaKey: '6LdXdZInAAAAAFEPoD2FGGWyvSaXMU0rQhYAcB4i',
  msalConfig:{
    auth:{
      clientId :"a4d13af3-2de5-465e-9c42-d708781d8efb",
      authority: "https://login.microsoftonline.com/common/",
      redirectUri: "https://corp.management/microsoft-callback"
    }
  },
  jiraConfig: {
    bashUrl: 'https://auth.atlassian.com/',
    clientId: 'CXHdX3641rzjr6oBU5zcYcXD3baeSEqd',
    clientSecret: 'ATOAcYn_6v7KI35tQrOxwuxQ8XclRfn734VqGZBZByppfD0yoyLGIzHW8sYPjZAZHR5G9638211E',
    redirectUri: "https://corp.management/jira-callback",
    scope: "read:me read:account"
  }
};

// export const environment = {
//   language: 'pt-BR',
//   production: true,
//   api: 'https://corp.joit.com.br:7083/',
//   recaptchaKey: '6LdXdZInAAAAAFEPoD2FGGWyvSaXMU0rQhYAcB4i',
//   msalConfig:{
//     auth:{
//       clientId :"a4d13af3-2de5-465e-9c42-d708781d8efb",
//       authority: "https://login.microsoftonline.com/common/",
//       redirectUri: "https://corp.joit.com.br:7080/microsoft-callback"
//     }
//   },
//   jiraConfig: {
//     bashUrl: 'https://auth.atlassian.com/',
//     clientId: 'CXHdX3641rzjr6oBU5zcYcXD3baeSEqd',
//     clientSecret: 'ATOAcYn_6v7KI35tQrOxwuxQ8XclRfn734VqGZBZByppfD0yoyLGIzHW8sYPjZAZHR5G9638211E',
//     redirectUri: "https://corp.joit.com.br:7080/jira-callback",
//     scope: "read:me read:account"
//   }
// };
