import { Injectable } from '@angular/core';
import { Menu } from '../models/menu';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

// export enum AmbienteAcesso {
//   Trabalho = 1,
//   Manage = 2
// }

@Injectable({
  providedIn: "root",
})
export class MenuItemsService {
  constructor(private http: HttpClient) {

  }

  public getMenus(): Observable<Menu[]> {
    return this.http.get<Menu[]>(`PerfilAcesso/ListarMenuAcesso`, httpOptions);
  }

  // //public static navigation = MenuItemsService.navigationFunction();
  // public static navigationFunction(ambiente: AmbienteAcesso): Menu[] {
  //   switch (ambiente) {
  //     case AmbienteAcesso.Manage:
  //     {
  //       return null;
  //     }
  //     case AmbienteAcesso.Trabalho:
  //       return this.menusTrabalho();
  //     default:
  //       return this.menusTrabalho();
  //   }
  // }

  // private static menusTrabalho(): Menu[] {
  //   return [
  //     // {
  //     //   title: "Dashboard",
  //     //   url: "/dashboard",
  //     //   icon: "fas fa-tachometer-alt",
  //     //   permission: true,
  //     // },
  //     {
  //       title: "Minhas Horas",
  //       url: "/apontamentos",
  //       icon: "fas fa-calendar-alt",
  //       permission: true,
  //     },
  //     {
  //       title: "Kanban",
  //       url: "/board",
  //       icon: "fas fa-tasks",
  //       permission: true,
  //     },
  //     {
  //       title: "Expediente",
  //       url: "/expediente",
  //       icon: "far fa-clock",
  //       permission: true,
  //     },
  //     {
  //       title: "Reembolso",
  //       url: "/reembolso",
  //       icon: "fas fa-hand-holding-usd",
  //       permission: true,
  //     },
  //     {
  //       title: "Gestão",
  //       url: "/manage",
  //       icon: "fas fa-cogs",
  //       permission: true,
  //     } 
  //   ];
  // }
}
