import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/@shared/shared.module";
import { AppManageEmbedComponent } from "./app-manage-embed/app-manage-embed.component";

@NgModule({
  declarations: [AppManageEmbedComponent],
  providers: [],
  imports: [CommonModule, SharedModule],
  exports: [AppManageEmbedComponent],
})
export class AppManageEmbedModule {}
