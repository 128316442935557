import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/@shared/shared.module";
import { AppBlankComponent } from "./app-blank/app-blank.component";

@NgModule({
  declarations: [AppBlankComponent],
  providers: [],
  imports: [CommonModule, SharedModule],
  exports: [AppBlankComponent],
})
export class AppBlankModule { }
