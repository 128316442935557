import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClienteService } from 'src/app/services/cliente.service';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Cliente } from 'src/app/models/cliente';
import { ApontamentoService } from 'src/app/services/apontamento.service';
import { ProjetosService } from 'src/app/services/projetos.service';
import { Projeto } from 'src/app/models/projeto';
import { SaldoService } from 'src/app/services/saldo.service';
import { CargoService } from 'src/app/services/cargo.service';
import { Cargo } from 'src/app/models/cargo';
import { FaseService } from 'src/app/services/fase.service';
import { Fase } from 'src/app/models/fase';
import { PlanejamentoService } from 'src/app/@modules/manage/planejamento/services/planejamento.service';
import { Planejamento } from 'src/app/models/planejamento';
import { ApontamentosComponent } from 'src/app/@modules/main/components/apontamentos/apontamentos.component';
import { JornadaService } from 'src/app/services/jornada.service';
import { Jornada } from 'src/app/models/Jornada';

@Component({
  selector: 'app-modal-confirmacao',
  templateUrl: './modal-confirmacao.component.html',
  styleUrls: ['./modal-confirmacao.component.scss']
})
export class ModalConfirmacaoComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  private unsubscribe$: Subject<any> = new Subject<any>();

  criarsaldo = {};

  mensagem;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ApontamentosComponent>,
    private clientService: ClienteService,
    private cargoService: CargoService,
    private faseService: FaseService,
    private jornadaService: JornadaService,
    private apontamentoService: ApontamentoService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private saldoService: SaldoService,
    private projetosService: ProjetosService,
    private planejamentoService: PlanejamentoService
  ) {
    if (data.tela === 'cadastroCliente' && data.client !== null) {
      this.getClient(data.client.id);
    }
    if (data.tela === 'cadastroFase' && data.fase !== null) {
      this.getFase(data.fase.id);
    }
    if (data.tela === 'cadastroCargo' && data.cargo !== null) {
      this.getClient(data.cargo.id);
    }
    if (data.tela === 'tarefasPausadas' && data.apontamento !== null) {
      this.getPreAppointment(data.apontamento);
    }
    if (data.tela === 'cadastroProjeto' && data.project !== null) {
      this.getProject(data.project.id);
    }
    if (data.tela === 'saldo' && data.saldo !== null) {
      this.saldoDetails(data.saldo.id);
    }
    if (data.tela === 'planejamento' && data.planejamento !== null) {
      this.getPlanning(data.planejamento.id);
    }
    if (data.tela === 'jornada' && data.jornada !== null) {
      this.getPlanning(data.jornada.id);
    }

    this.mensagem = this.data.mensagem;
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  getClient(id: number) {
    this.clientService.getClientById(id)
      .subscribe(
        () => console.log(),
        (err: HttpErrorResponse) => console.error(err)
      );
  }

  getFase(id: number) {
    this.faseService.getFaseById(id)
      .subscribe(
        () => console.log(),
        (err: HttpErrorResponse) => console.error(err)
      );
  }

  getJornada(id: number) {
    this.jornadaService.getJornadaById(id)
      .subscribe(
        () => console.log(),
        (err: HttpErrorResponse) => console.error(err)
      );
  }

  getCargo(id: number) {
    this.cargoService.getCargoById(id)
      .subscribe(
        () => console.log(),
        (err: HttpErrorResponse) => console.error(err)
      );
  }


  deleteClient(client: Cliente) {
    this.isLoading = true;
    this.clientService.deleteClient(client)
      .subscribe(
        () => {
          this.isLoading = false;
          this.dialog.closeAll();
          this.openSnackBar('Cliente deletado com sucesso!', 'OK', 3000, 'success-snackbar');
        },
        (err: HttpErrorResponse) => {
          console.error(err);
          this.openSnackBar('Erro ao deletar cliente!', 'OK', 3000, 'danger-snackbar');
        }
      ).add(() => this.isLoading = false);
  }


  deleteJornada(jornada: Jornada) {
    this.isLoading = true;

    this.jornadaService.deleteJornada(jornada)
      .subscribe(
        () => {
          this.isLoading = false;
          this.dialog.closeAll();
          this.openSnackBar('Jornada deletada com sucesso!', 'OK', 3000, 'success-snackbar');
        },
        (err: HttpErrorResponse) => {
          console.error(err);
          this.openSnackBar('Erro ao deletar jornada!', 'OK', 3000, 'danger-snackbar');
        }
      );
  }

  deleteCargo(cargo: Cargo) {
    this.isLoading = true;

    this.cargoService.deleteCargo(cargo)
      .subscribe(
        () => {
          this.isLoading = false;
          this.dialog.closeAll();
          this.openSnackBar('Cargo deletado com sucesso!', 'OK', 3000, 'success-snackbar');
        },
        (err: HttpErrorResponse) => {
          console.error(err);
          this.openSnackBar('Erro ao deletar cargo!', 'OK', 3000, 'danger-snackbar');
        }
      );
  }


  deleteFase(fase: Fase) {
    this.isLoading = true;

    this.faseService.deleteFase(fase)
      .subscribe(
        () => {
          this.isLoading = false;
          this.dialog.closeAll();
          this.openSnackBar('Fase deletada com sucesso!', 'OK', 3000, 'success-snackbar');
        },
        (err: HttpErrorResponse) => {
          console.error(err);
          this.openSnackBar('Erro ao deletar fase!', 'OK', 3000, 'danger-snackbar');
        }
      );
  }


  getPreAppointment(apontamento) {
    this.apontamentoService.preDetails(apontamento)
      .subscribe(
        () => console.log(),
        (err: HttpErrorResponse) => console.error(err)
      );
  }

  deletePreAppointment(apontamento) {
    this.isLoading = true;

    this.apontamentoService.preDelete(apontamento)
      .subscribe(
        () => {
          this.isLoading = false;
          this.dialog.closeAll();
          this.openSnackBar('Apontamento removido com sucesso!', 'OK', 3000, 'success-snackbar');
        },
        (err: HttpErrorResponse) => {
          console.error(err);
          this.openSnackBar('Erro ao remover apontamento!', 'OK', 3000, 'danger-snackbar');
        }
      );
  }

  saldoDetails(id: number) {
    this.isLoading = true;
    this.saldoService.getSaldoDetalhes(id)
      .subscribe(
        (saldo: any) => {
          this.isLoading = false;
          // this.criarsaldo = saldo;
          this.criarsaldo = {
            id: saldo.id,
            logon: saldo.logon,
            mes: saldo.mes.toString(),
            ano: saldo.ano.toString(),
            fechamento: saldo.fechamento,
            saldo: saldo.saldo
          };
        },
        (err: HttpErrorResponse) => console.error(err)
      );
  }

  excluirSaldo(id) {
    this.saldoService.getExcluirSaldo(id)
      .subscribe(response => {
        this.isLoading = false;
        this.dialog.closeAll();
        this.openSnackBar('Saldo deletado com sucesso!', 'OK', 3000, 'success-snackbar');
        console.log(response);
      },
        (error: HttpErrorResponse) => {
          console.error(error.message);
          this.openSnackBar('Erro ao deletar saldo!', 'OK', 3000, 'danger-snackbar');
        });
  }

  getProject(id: number) {
    this.projetosService.getProjectById(id)
      .subscribe(
        () => console.log(),
        (err: HttpErrorResponse) => console.error(err)
      );
  }

  deleteProject(project: Projeto) {
    this.isLoading = true;

    this.projetosService.deleteProject(project)
      .subscribe(
        () => {
          this.isLoading = false;
          this.dialog.closeAll();
          this.openSnackBar('Deletado com sucesso!', 'OK', 3000, 'success-snackbar');
        },
        (err: HttpErrorResponse) => {
          console.error(err);
          this.openSnackBar('Erro ao deletar!', 'OK', 3000, 'danger-snackbar');
        }
      );
  }

  getPlanning(id: number) {
    this.planejamentoService.getPlanningById(id)
      .subscribe(
        () => console.log(),
        (error: HttpErrorResponse) => console.error(error)
      );
  }

  deletePlanning(id: number) {
    this.isLoading = true;

    this.planejamentoService.deletePlanning(id).subscribe(
      (planejamento: Planejamento) => {
        this.isLoading = false;
        this.dialog.closeAll();
        this.openSnackBar("Projeto deletado com sucesso!", "OK", 3000, 'success-snackbar');
      },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.openSnackBar("Erro ao deletar projeto!", "OK", 3000, 'danger-snackbar');
      }
    );
  }

  confirmDelete() {
    this.dialogRef.close(1);
  }

  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this.snackBar.open(message, action, { duration, panelClass });
  }
}
