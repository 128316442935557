import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRootComponent } from './app-root/app-root.component';
import { FooterComponent } from 'src/app/@layout/base/footer/footer.component';
import { SharedModule } from 'src/app/@shared/shared.module';
@NgModule({
  declarations: [AppRootComponent, FooterComponent],
  providers: [],
  imports: [CommonModule, SharedModule],
  exports: [AppRootComponent, FooterComponent],
})
export class AppRootModule {}
