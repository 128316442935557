<header class="no-print">
  <div class="header p-0">
    <div class="logo-side container-fluid p-0">
      <div class="col-lg-2">
        <a class="logo-link" href="/apontamentos">
          <!-- <img class="logo" src="../../../assets/images/logo.svg" alt="Logo Joit"> -->
          <img class="logo" src="/assets/images/logo.png" alt="Logo Joit">
        </a>
      </div>
      <div class="col-lg-10 p-0">
        <div class="d-flex justify-content-between align-items-baseline flex-wrap">
          <div *ngIf="showTimer" id="resolve" class="d-flex align-items-center p-2" style="flex-grow: 1;">
            <app-timer #timerChild [(task)]="runningTask"
              style="min-width: 150px;color:white;margin-right: 20px;flex-grow:1"></app-timer>
          </div>
          <div class="empresas-selector" *ngIf="guidEmpresa">
            <mat-accordion class="mat-panel-grid">
              <mat-expansion-panel class="panel expansion-body">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mat-panel-grid ">
                    <p class="title-company mb-0">{{ empresaAtual?.nomeFantasia }}<br />
                      <small class="cnpj-company mt-0">SOU {{empresaAtual?.perfilAcesso}}</small>
                      <!-- <small class="cnpj-company mt-0">Sou {{empresaAtual?.perfilAcesso}} <br />{{ empresaAtual?.cnpj | cpfCnpj}}</small> -->
                    </p>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-progress-bar [hidden]="!isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
                <div [hidden]="isLoading">
                  <mat-divider></mat-divider>
                  <div class="cnpj-company mt-3" *ngIf="empresa.length > 1"> <i>Selecione outra empresa para
                      gerenciar</i>
                  </div>
                  <div class="cnpj-company mb-3" *ngIf="empresa.length === 0"> <i>Não existem outras empresas
                      cadastradas.</i>
                  </div>
                  <div *ngFor="let cnpjs of empresa;let last = last" class="onHoverCompany"
                    (click)="selectCompany(cnpjs)">
                    <div class="company-select d-flex justify-content-between align-items-center">
                      <div>
                        <p class="title-company-list mb-0">{{cnpjs.nomeFantasia}}</p>
                        <small style="text-transform: uppercase;">Sou {{cnpjs?.perfilAcesso}}</small>
                        <!-- <small>Sou {{cnpjs?.perfilAcesso}}<br /> {{cnpjs.cnpj | cpfCnpj }}</small> -->
                      </div>
                      <mat-icon>chevron_right</mat-icon>
                    </div>
                    <mat-divider *ngIf="!last"></mat-divider>
                  </div>
                  <mat-action-row>
                    <button mat-raised-button color="primary" (click)="cadastrarEmpresa()"> <i class="fas fa-plus"></i>
                      Criar
                      nova
                      empresa</button>
                  </mat-action-row>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="user-side no-print">
            <div class="user-side--content">
              <button mat-button [matMenuTriggerFor]="menuUser">
                <div class="menu-user">
                  <div class="photo">
                    <img [src]="envUrl" alt="Foto">
                  </div>
                  <span class="name">{{ onlyFirstName(user.name) }}</span>
                  <i class="fas fa-chevron-down"></i>
                </div>
              </button>
              <mat-menu #menuUser="matMenu" xPosition="before">
                <button (click)="openProfileModal()" class="user-content-button" mat-menu-item>
                  <i class="fas fa-user"></i>
                  Dados pessoais
                </button>
                <button class="user-content-button" (click)="logout()" mat-menu-item>
                  <i class="fas fa-sign-out-alt"></i>
                  Sair
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>