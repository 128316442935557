import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApontamentoReferencia } from '../models/apontamentoReferencia';
import { Projeto } from '../models/projeto';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

//vai chamar projeto
export class ProjetosService {
  constructor(private http: HttpClient) { }

  getProjects(idCliente: number, idProject?: number, ativo?: boolean): Observable<Projeto[]> {
    let params = new HttpParams();
    if (idCliente)
      params = params.append('ClienteId', idCliente.toString());

    if (ativo != undefined && ativo != null && ativo.toString() != '') {
      params = params.append('Ativo', ativo.toString());
    }

    if (idProject != null) {
      params = params.append('ProjetoId', idProject.toString());
    }
    return this.http.get<Projeto[]>("ApontamentoReferencia/listar", { params }).pipe(map(result => result));
  }

  getProjectById(id: number): Observable<Projeto> {
    return this.http.get<Projeto>(`ApontamentoReferencia/details/${id}`, httpOptions);
  }

  createProject(project: Projeto): Observable<Projeto> {
    return this.http.post<Projeto>(`ApontamentoReferencia/create`, project, httpOptions);
  }

  editProject(project: Projeto): Observable<Projeto> {
    return this.http.put<Projeto>(`ApontamentoReferencia/edit`, project, httpOptions);
  }

  deleteProject(project: Projeto): Observable<Projeto> {
    return this.http.delete<Projeto>(`ApontamentoReferencia/delete/${project.id}`, httpOptions);
  }
}
