//Dafault Material;
import { CommonModule, registerLocaleData } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
// import { FilterPipe } from "./pipes";
import { HammerModule } from "@angular/platform-browser";
import { AppMaterialModule } from "./material/app-material.module";

// Third Plugins;
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CdkTableModule } from "@angular/cdk/table";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatPaginatorIntl } from "@angular/material/paginator";

//Date and language;
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS,
} from "@angular/material/core";
import ptBR from "@angular/common/locales/pt";

// import { TooltipModule } from "@swimlane/ngx-charts";
import { FilterPipe } from "../pipes/filter.pipe";
import { MenuPanelComponent } from "../@layout/base/menu-panel/menu-panel.component";
import { SidebarComponent } from "../@layout/base/sidebar/sidebar.component";
import { PtBrPaginatorIntl } from "./helpers/pt-br-paginator-intl";
import { TimerComponent } from "./components/timer/timer.component";
import { PaginacaoComponent } from "./components/paginacao/paginacao.component";
import { CpfMaskDirective } from "./directives/cpf-mask.directive";
import { CpfCnpjMaskDirective } from "./directives/cpf-cnpj-mask.directive";
import { TabDirective } from "./directives/tab.directive";
import { ClickStopPropagationDirective } from "./directives/click-stop-propagation.directive";
import { globalRippleConfig } from "../@core/core.module";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ModalPerfilComponent } from "../@layout/modais/modal-perfil/modal-perfil.component";
import { CpfCnpjPipe } from "../pipes/cpfCnpj.pipe";
import { ModalEmpresaComponent } from "../@modules/manage/admin/cadEmpresas/modal-empresa/modal-empresa.component";
import { IConfig, NgxMaskModule } from "ngx-mask";
// Ngx Material timepicker
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { Ng2CarouselamosModule } from "ng2-carouselamos";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgxEchartsModule } from "ngx-echarts";
import { ChartsModule } from "ng2-charts";
import { NgChartjsModule } from "ng-chartjs";
import { MatSliderModule } from "@angular/material/slider";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { MdePopoverModule } from "@material-extended/mde";
import {
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { environment } from "src/environments/environment";
import { AzureSSOService } from "../services/azure-sso.service";
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
} from "@azure/msal-angular";
import { MenuNavComponent } from "../@layout/base/menu-nav/menu-nav.component";
import { RouterLinkDisabledDirective } from "./directives/routerlink-disabled.directive";
import { MenuAccordionComponent } from "../@layout/base/menu-accordion/menu-accordion.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ModalConfirmacaoPadraoComponent } from "./components/modal-confirmacao-padrao/modal-confirmacao-padrao.component";
import { ModalAceiteConviteColaboradorComponent } from './components/modal-aceite-convite-colaborador/modal-aceite-convite-colaborador.component';
import { ModalConfirmacaoComponent } from "../@modules/manage/clientes/components/modal-confirmacao/modal-confirmacao.component";
import { TaskComponent } from "./components/task/task.component";
import { CreateWorkitemComponent } from "../@modules/main/components/work/create-workitem/create-workitem.component";
import { WorkDayComponent } from "../@modules/main/components/work/work-day/work-day.component";
import { ModalApontamentosComponent } from "../@modules/main/components/apontamentos/modal-apontamentos/modal-apontamentos.component";
import { WorkComponent } from "../@modules/main/components/work/work.component";
import { HeaderComponent } from "../@layout/base/header/header.component";

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(ptBR, "pt-BR");
export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: environment.msalConfig.auth,
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE,
    },
  });
}

@NgModule({
  imports: [
    RouterModule,
    AppMaterialModule,
    CommonModule,
    DragDropModule,
    HammerModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    NgxMaskModule.forRoot(),
    NgxMaterialTimepickerModule.setLocale("pt-BR"),
    Ng2CarouselamosModule,
    PerfectScrollbarModule,
    NgxEchartsModule,
    NgChartjsModule.registerPlugin([]),
    ChartsModule,
    NgbModule,
    MdePopoverModule,
    MatSliderModule,
    CKEditorModule,
    CollapseModule,
    NgxMatSelectSearchModule
  ],
  declarations: [
    SidebarComponent,
    MenuPanelComponent,
    MenuAccordionComponent,
    MenuNavComponent,
    FilterPipe,
    CpfCnpjPipe,
    TimerComponent,
    ModalEmpresaComponent,
    ModalPerfilComponent,
    PaginacaoComponent,
    ModalConfirmacaoPadraoComponent,
    CpfCnpjMaskDirective,
    CpfMaskDirective,
    TabDirective,
    ClickStopPropagationDirective,
    RouterLinkDisabledDirective,
    ModalAceiteConviteColaboradorComponent,
    ModalConfirmacaoComponent,
    TaskComponent,
    CreateWorkitemComponent,
    WorkComponent,
    WorkDayComponent,
    ModalApontamentosComponent,
    HeaderComponent
  ],
  exports: [
    AppMaterialModule,
    RouterModule,
    CommonModule,
    DragDropModule,
    HammerModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    SidebarComponent,
    MenuPanelComponent,
    MenuAccordionComponent,
    MenuNavComponent,
    TimerComponent,
    TaskComponent,
    ModalEmpresaComponent,
    ModalPerfilComponent,
    ModalConfirmacaoComponent,
    PaginacaoComponent,
    ModalConfirmacaoPadraoComponent,
    FilterPipe,
    CpfCnpjPipe,
    CpfCnpjMaskDirective,
    CpfMaskDirective,
    RouterLinkDisabledDirective,
    TabDirective,
    ClickStopPropagationDirective,
    NgxMaterialTimepickerModule,
    Ng2CarouselamosModule,
    PerfectScrollbarModule,
    NgxEchartsModule,
    NgChartjsModule,
    ChartsModule,
    NgbModule,
    MdePopoverModule,
    MatSliderModule,
    CKEditorModule,
    CollapseModule,
    NgxMaskModule,
    NgxMatSelectSearchModule,
    CreateWorkitemComponent,
    WorkComponent,
    WorkDayComponent,
    ModalApontamentosComponent,
    HeaderComponent
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MatPaginatorIntl, useValue: PtBrPaginatorIntl() },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    AzureSSOService,
    MsalService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: () => ({
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
        ]),
      }),
    },
    TimerComponent,
    ModalEmpresaComponent,
    ModalPerfilComponent,
    TaskComponent,
    HeaderComponent
  ],
})
export class SharedModule { }
