import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal-aceite-convite-colaborador',
  templateUrl: './modal-aceite-convite-colaborador.component.html',
  styleUrls: ['./modal-aceite-convite-colaborador.component.scss']
})
export class ModalAceiteConviteColaboradorComponent implements OnInit {

  textoCancelar = "Cancelar";
  textoOk = "OK";
  textoRemove = "";
  exibirBtnRemover = false;

  titulo = "Alerta!";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
    private dialog: MatDialogRef<ModalAceiteConviteColaboradorComponent>
  ) {
    this.textoCancelar = data.textoCancelar || this.textoCancelar;
    this.textoOk = data.textoOk || this.textoOk;
    this.textoRemove = data.textoRemove || this.textoRemove;
    this.exibirBtnRemover = data.exibirBtnRemover || this.exibirBtnRemover;
    this.titulo = data.titulo || this.titulo;
  }

  confirm() {
    this.dialog.close(1);
  }

  remove() {
    this.dialog.close(2);
  }

  ngOnInit() { }

}
