import { Component, OnInit, Inject, AfterContentInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {
  ApiAzureDevopsService,
  FiltroGetSprintDevOps,
  FiltroGetTasksDevOps,
} from 'src/app/services/api-azure-devops.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimerComponent } from 'src/app/@shared/components/timer/timer.component';
import { SharedService } from 'src/app/services/shared.service';
import { ProjetosService } from 'src/app/services/projetos.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { Cliente } from 'src/app/models/cliente';
import { Projeto } from 'src/app/models/projeto';
import { CreateWorkitemComponent } from '../../../work/create-workitem/create-workitem.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, ReplaySubject, Subject } from 'rxjs-compat';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-modal-selecao-tarefa',
  templateUrl: './modal-selecao-tarefa.component.html',
  styleUrls: ['./modal-selecao-tarefa.component.scss']
})

export class ModalSelecaoTarefaComponent implements OnInit {
  isLoading: boolean;
  NUMERIC_PATTERN = /^\d{1,2}(?:\.\d{1,2})?$/;

  formSprintAndTask = new FormGroup({
    sprintFormControl: new FormControl(''),
    taskFormControl: new FormControl('')
  });

  get sprintFormControl() { return this.formSprintAndTask.get('sprintFormControl').value; }
  get taskFormControl() { return this.formSprintAndTask.get('taskFormControl').value; }
  private onDestroy = new Subject<void>();
  sprint: any;


  sprintFilteredOptions: Observable<string[]>;
  public filteredSprints: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public sprintMultiFilter: FormControl = new FormControl();

  sprintIdSelecionado;
  sprints: any[];

  task: any;

  taskFilteredOptions: Observable<string[]>;
  public filteredTasks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public taskMultiFilter: FormControl = new FormControl();

  taskIdSelecionado;

  tasks: any[] = [];
  tasksSearch: any[] = [];
  type: string = "Task";
  status = ['To Do', 'In Progress', 'Done'];
  searchText = "";
  typesNew = ['Task', 'Bug'];

  displayClientProject: boolean;
  stories = [];
  clients: Cliente[] = [];
  projects: Projeto[] = [];
  idProject = null;
  dataRef: Date;


  story: any;
  parent: any;
  responsavelId = null;

  constructor(
    private fb: FormBuilder,
    public devops: ApiAzureDevopsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TimerComponent>,
    private sharedService: SharedService,
    public clientService: ClienteService,
    public projectService: ProjetosService,
    public snackBar: MatSnackBar
  ) {


  }

  ngOnInit() {
    if (this.data && this.data.tela == 'timer') {
      this.formSprintAndTask.get('taskFormControl').setValidators([Validators.required]);
      this.formSprintAndTask.get('taskFormControl').updateValueAndValidity();
    }

    this.responsavelId = this.data.responsavelId;

    if (this.data?.dataRef)
      this.dataRef = new Date(this.data?.dataRef);

    this.getSprints();
  }


  private filtroSprint(filtro: any, multifilter: any, filtered: any, field: string, sprint?: boolean) {
    if (!filtro) {
     return;
    }
    let search = multifilter.value;
    if (!search) {
      filtered.next(filtro.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    filtered.next(
      sprint ?
      filtro.filter(response => `${response.tenant} - ${response.name}`.toLowerCase().indexOf(search) > -1) :
      filtro.filter(response => response[field].toLowerCase().indexOf(search) > -1)
    );
  }

  private filtroTask(filtro: any, multifilter: any, filtered: any, field: string, sprint?: boolean) {
    if (!filtro) {
     return;
    }
    let search = multifilter.value;
    if (!search) {
      filtered.next(filtro.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    filtered.next(
      sprint ?
      filtro.filter(response => `${response.parentTitle} - ${response.title}`.toLowerCase().indexOf(search) > -1) :
      filtro.filter(response => response[field].toLowerCase().indexOf(search) > -1)
    );
  }




  getSprints() {
    let filtro = new FiltroGetSprintDevOps()
    filtro.dataReferencia = this.dataRef;

    this.devops.getSprintsUsuario(filtro).subscribe(sprints => {
      this.sprints = sprints;

      this.filteredSprints.next(this.sprints.slice());
      this.sprintMultiFilter.valueChanges
       .pipe(takeUntil(this.onDestroy))
       .subscribe(() => {
        this.filtroSprint(this.sprints, this.sprintMultiFilter, this.filteredSprints, 'id', true);
        });
    });
  }

  clearMessages(): void {
    this.sharedService.clearMessages();
  }

  async changeClient(id, referenciaId?) {
    this.projectService.getProjects(id, null,  true)
      .subscribe(async response => {
        this.projects = response.filter(project => !!project.tag);

        if (this.projects.length === 0) {
          this.openSnackBar('Este cliente não possui PROJETOS com TAG associada', 'OK', 5000, 'warning-snackbar');
        }
      });
  }



  getTasks(id?: number) {

    this.isLoading = true;

    if (this.sprintFormControl == "") {
      this.formSprintAndTask.get('taskFormControl').disable();
    }
    if (this.sprintFormControl) {
      this.formSprintAndTask.get('taskFormControl').enable();
    } else {
      this.formSprintAndTask.get('taskFormControl').disable();
    }

    const filtro: FiltroGetTasksDevOps = {
      dataRef: this.dataRef,
      types: ["Task", "Bug", "Ticket"],
      id: id,
      //sprintId: this.sprint?.id,
      sprintId: this.sprintIdSelecionado,
      userId: this.responsavelId
      //status: this.status,
    };


    this.devops.getTasks(filtro)
      .subscribe(tasks => {
        this.tasks = tasks;

      this.filteredTasks.next(this.tasks.slice());
      this.taskMultiFilter.valueChanges
       .pipe(takeUntil(this.onDestroy))
       .subscribe(() => {
        this.filtroTask(this.tasks, this.taskMultiFilter, this.filteredTasks, 'id', true);
        });

        this.isLoading = false;
      });
  }

  checkTarefa(status) {
    if (status.checked) {

      Object.keys(this.formSprintAndTask.controls).forEach(
        key => {
          this.formSprintAndTask.controls[key].setValidators(null);
          this.formSprintAndTask.controls[key].updateValueAndValidity();
        });
    } else {
      Object.keys(this.formSprintAndTask.controls).forEach(
        key => {
          this.formSprintAndTask.controls[key].setValidators([Validators.required]);
          this.formSprintAndTask.controls[key].updateValueAndValidity();
        });
    }
  }

  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this.snackBar.open(message, action, { duration, panelClass });
  }

  changeSprint() {
    this.getTasks();
  }

  concluir(e) {
    this.task = this.tasks.filter(x => x.id == this.taskIdSelecionado)[0];
    this.dialogRef.close(this.task);
  }

  openCreateNew() {
    const dialogRef = this.dialog.open(CreateWorkitemComponent, {
      disableClose: true,
      width: '940px',
      data: {
        responsavelId: this.responsavelId,
        sprints: this.sprints,
        sprint: this.sprint,
        tela: 'devops'
      }
    });

    dialogRef.afterClosed()
      .subscribe(async retorno => {
        if (retorno != 'cancel') {
          this.openSnackBar('Item cadastrado com sucesso! Abrindo para Apontamento', 'OK', 3000, 'success-snackbar');
          if (retorno?.id) {
            this.dialogRef.close(retorno);
            // //buscar pelo ID
            // this.isLoading = true;
            // this.devops.getTaskById(retorno?.id).subscribe(tarefa => {
            //   this.dialogRef.close(tarefa);
            //   this.isLoading = false;
            // },
            //   (error: HttpErrorResponse) => {
            //     console.error(error);
            //     this.openSnackBar('Erro ao consultar tarefa!', 'OK', 5000);
            //     this.dialogRef.close();
            //   });
          }
        }
      });
  }
}
