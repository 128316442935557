<div class="container-fluid mt-3">
  <div class="d-flex align-items-center mb-3">
    <div class="d-flex align-items-center" style="flex-grow: 1;">
      <h1 class="m-0"><Strong>Calendário</Strong></h1>
    </div>
    <div class="d-flex">
      <div class="position-help-icon">
        <mat-icon class="help-icon"
          matTooltip="Defina em quais projetos está trabalhando para configurar a priorização do seu trabalho">help</mat-icon>
      </div>
    </div>
  </div>
</div>
<mat-progress-bar mode="indeterminate" [hidden]="!isLoading" color="primary"></mat-progress-bar>
<mat-card>
  <div class="row" *ngIf="isLoading">
    <div class="col-lg-12">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <mat-progress-spinner color="primary" mode="indeterminate" class="m-3"></mat-progress-spinner>
        Planejando seus trabalhos...
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!isLoading">
    <div class="col-12">
      <div class="calendario-outlook">
        <!-- Grade de horários (horários na direita)
        <div class="calendario-horarios">
          <!- Iteração pelos horários (blocos de 1 em 1 hora) ->
          <div class="calendario-hora">08:00</div>
          <div class="calendario-hora">09:00</div>
          <!- ... Adicione os horários restantes aqui ... ->
          <div class="calendario-hora">17:00</div>
        </div> -->

        <!-- Dias da semana ao topo -->
        <div class="calendario-dias-semana">
          <section class="active">
            <div class="no-padding">
              <div class="carousel-container">
                <div class="carousel-tarefas" ng2-carouselamos [items]="datasExpedientes" [$item]="itemTemplate"
                  [$prev]="prevTemplate" [$next]="nextTemplate" (onSelectedItem)="selectedItem = $event.item">
                </div>
              </div>
              <ng-template #prevTemplate>
                <button mat-icon-button style="color:white" aria-label="Example icon button with a vertical three dot icon">
                  <mat-icon>chevron_left</mat-icon>
                </button>
                <!-- <div class="btn-prev">
                  <div class="fundo"></div>
                  <i class="fas fa-chevron-circle-left" style="font-size: xx-large;"></i>
                </div> -->
              </ng-template>

              <ng-template #nextTemplate>
                <button mat-icon-button style="color:white" aria-label="Example icon button with a vertical three dot icon">
                  <mat-icon>chevron_right</mat-icon>
                </button>
                <!-- <div class="btn-next">
                  <div class="fundo"></div>
                  <i class="fas fa-chevron-circle-right"></i>
                </div> -->
              </ng-template>

              <ng-template let-data let-i="index" #itemTemplate>
                <div class="calendario-dia pt-3">
                  <p><strong>{{obterDiaSemanaPorExtenso(data.dataRef)}}</strong></p>
                  <app-work-day [parent]="this" [day]="data" [isLoading]="isLoading"></app-work-day>

                  <!-- Grade de compromissos (registros da jornada) 
                  <div class="calendario-compromissos">
                    <!- Exemplo de compromisso personalizado 
                    <div class="calendario-compromisso custom">Reunião</div>
      
                    <!- Exemplo de compromisso padrão (jornada de trabalho) 
                    <div class="calendario-compromisso default">Trabalho</div>
                  </div>
                  --->
                </div>
              </ng-template>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</mat-card>