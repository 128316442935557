<ng-container *ngIf="step==2">
    <h4 *ngIf="!data.editing" class="text-center mt-1 uppercase">PROGRAMAÇÃO<br />
        <small>Selecione uma opção para continuar</small>
    </h4>
    <h4 *ngIf="data.editing" class="text-center mt-1 uppercase">EDITAR PROGRAMAÇÃO<br />
        <small>Você poderá alterar uma programação de horário</small>
    </h4>
    <mat-nav-list class="mb-3">
        <mat-list-item *ngFor='let projeto of projetos' (click)="selecionouProjeto(projeto)">
            <mat-icon matListIcon>alarm</mat-icon>
            <h3 matLine>{{projeto.cliente}} - {{projeto.descricao}} </h3>
            <p matLine>
                <strong>#{{projeto.tag}} </strong>
            </p>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item *ngIf="data.editing && this.data.expediente.horarios.length > 1" (click)="excluirRegistro()">
            <mat-icon matListIcon>delete</mat-icon>
            <h3 matLine>Excluir planejamento</h3>
            <!-- <p matLine>
                <strong>#{{projeto.tag}} </strong>
            </p> -->
        </mat-list-item>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <br />
    <div class="d-flex justify-content-between">
        <div>
            <button mat-raised-button (click)="step=1"><i class="fas fa-chevron-circle-left"></i>&nbsp;Voltar</button>
        </div>
        <div>
            <button mat-raised-button color="primary" (click)="proceed()">{{data.editing ? "Salvar" :
                "Adicionar"}}&nbsp;<i class="fas fa-calendar-check"></i></button>
        </div>

        <!-- <p class="muted text-right"><small>Selecione um horário acima para continuar&nbsp;<i
                    class="fas fa-chevron-circle-right"></i></small></p> -->
    </div>
</ng-container>
<ng-container *ngIf="step==1">
    <h4 class="text-center mt-1 uppercase">{{projeto?.cliente}}<br />
        <small>Reservar um horário em meu expediente para<br />{{projeto?.descricao}}</small>
    </h4>

    <mat-form-field appearance="fill" class="mb-3">
        <mat-label>Horário planejado para início</mat-label>
        <mat-select [(ngModel)]="inicioExpediente" (ngModelChange)="changeItem()" [disabled]="this.data.editing"
            required>
            <mat-option *ngFor='let horario of horarios' [value]="horario">{{horario.dataInicio | date: "HH:mm"}}
            </mat-option>
        </mat-select>
        <mat-hint>Ajuste na barra ao fechar este componente.</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mb-3">
        <mat-label>Duração (em horas)</mat-label>
        <input matTooltip="Duração" matInput aria-label="default time" [ngxTimepicker]="duracaoValue" [format]="24"
            [min]="minDuration" [max]="maxDuration" readonly [(ngModel)]="duracaoHorario" [disabled]="!inicioExpediente"
            (ngModelChange)="changeItem()" />
        <ngx-material-timepicker #duracaoValue> </ngx-material-timepicker>
        <mat-icon matSuffix>alarm</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mb-3">
        <mat-label>Breve resumo</mat-label>
        <input matTooltip="Descrição do que pretende fazer" matInput [(ngModel)]="descricaoExpediente"
            (ngModelChange)="changeItem()" />
    </mat-form-field>
    <br />
    <div class="d-flex justify-content-between">
        <div>
            <button mat-raised-button (click)="step=2"><i
                    class="fas fa-chevron-circle-left"></i>&nbsp;Categorizar</button>
        </div>
        <div>
            <button mat-raised-button color="primary" (click)="proceed()">{{data.editing ? "Salvar" :
                "Adicionar"}}&nbsp;<i class="fas fa-calendar-check"></i></button>
        </div>

        <!-- <p class="muted text-right"><small>Selecione um horário acima para continuar&nbsp;<i
                    class="fas fa-chevron-circle-right"></i></small></p> -->
    </div>
</ng-container>