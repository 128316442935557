import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export const montarQueryString = <T>(obj: T) => {
  if (!obj) return "";

  const filtro = Object.entries(obj).filter(([k, v]) => v && !Array.isArray(v));
  Object.entries(obj).filter(([k, v]) => v && Array.isArray(v)).forEach(e => {
    for (let index = 0; index < e[1].length; index++) {
      filtro.push([e[0], e[1][index]]);
    }
  });
  return new URLSearchParams(filtro);
};

export const readWorkItemJsonField = (jsonString, fieldName) => {
  if (jsonString)
    return JSON.parse(jsonString)["Fields"][fieldName];
  else return null;
}

export const readWorkItemJsonValue = (jsonString, fieldName) => {
  if (jsonString)
    return JSON.parse(jsonString)[fieldName];
  else return null;
}

@Injectable({ providedIn: 'root' })
export class SharedService {
  public subjectDevops = new ReplaySubject<any>();
  private subjectAppointment = new ReplaySubject<any>();

  public runningTask: any;

  // startNewTask(task: any) {
  //   this.runningTask = task;
  //   this.subjectDevops.next(task);
  // }

  updateAppointmentGrid(message: string) {
    this.subjectAppointment.next({ text: message });
  }

  clearMessages() {
    this.subjectDevops.next();
    this.subjectAppointment.next();
  }

  getMessageDevopsGrid(): Observable<any> {
    return this.subjectDevops.asObservable();
  }

  getMessageAppointmentGrid(): Observable<any> {
    return this.subjectAppointment.asObservable();
  }
}
