<div class="custom-modal">
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="arastavel">
    <div class="header">
      <h2 mat-dialog-title>Dados Pessoais</h2>
      <mat-icon mat-dialog-close="cancel">close</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-progress-bar [hidden]="!isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
  <div mat-dialog-content class="content">
    <perfect-scrollbar>
      <form *ngIf="!isLoading" [formGroup]="cadastroUsuario">
        <div class="profile-photo">
          <label style="position: relative" for="profileInput">
            <img [src]="usuario.imagem ? usuario.imagem : avatar" alt="{{ usuario.nome }} foto" />
            <div class="hover-photo">
              <div class="photo-overlay"></div>
              <mat-icon>photo_camera</mat-icon>
              <p>Editar</p>
            </div>
          </label>
          <input id="profileInput" type="file" formControlName="imagem" (change)="changeListener($event)"
            accept=".png,.jpg,.jpeg" />
        </div>
        <div class="duas-colunas">
          <div class="item1">
            <mat-form-field>
              <mat-label>Nome:</mat-label>
              <input matInput placeholder="Nome" name="name" formControlName="nome" [(ngModel)]="usuario.nome" />
              <mat-error *ngIf="cadastroUsuario.get('nome').errors?.required">
                <small>O Campo nome é obrigatório!</small>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="item2">
            <mat-form-field>
              <mat-label>Email Principal:</mat-label>
              <input matInput placeholder="Email do usuário" [disabled]="!isAdmin" name="emailn" formControlName="email"
                [(ngModel)]="usuario.email" />
              <mat-error *ngIf="cadastroUsuario.get('email').errors?.required">
                <small>O Campo email é obrigatório.</small>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="duas-colunas">
          <div class="item1">
            <mat-form-field>
              <mat-label>Nome de usuário:</mat-label>
              <input matInput placeholder="Nome de usuário" name="logon" formControlName="logon"
                [(ngModel)]="usuario.logon" />
              <mat-error *ngIf="cadastroUsuario.get('logon').errors?.required">
                <small>O Campo nome de usuário é obrigatório!</small>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="item2">
            <mat-form-field>
              <mat-label>Senha:</mat-label>
              <input matInput placeholder="Senha" name="usenha" formControlName="senha" [(ngModel)]="usuario.senha" />
            </mat-form-field>
          </div>
        </div>
        <!-- <mat-card class="card-select">
                    <div class="item1">
                        <mat-form-field appearance="outline">
                            <mat-label>Time</mat-label>
                            <mat-select matNativeControl [disabled]="!isAdmin" formControlName="idTime"
                                [(ngModel)]="usuario.idTime">
                                <mat-option disabled value="">Selecione um time</mat-option>
                                <mat-option *ngFor="let time of times" [value]="time.id">
                                    {{ time.descricao }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="item2">
                        <mat-form-field appearance="outline">
                            <mat-label>Cargo</mat-label>
                            <mat-select matNativeControl [disabled]="!isAdmin" formControlName="idCargo"
                                [(ngModel)]="usuario.idCargo">
                                <mat-option disabled value="">Selecione um cargo</mat-option>
                                <mat-option *ngFor="let cargo of cargos" [value]="cargo.id">
                                    {{ cargo.descricao }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-card> -->
        <div class="duas-colunas">
          <div class="item1">
            <mat-form-field>
              <mat-label>CPF:</mat-label>
              <input matInput [disabled]="usuario.documento" mask="000.000.000-00" placeholder="CPF"
                formControlName="documento" [(ngModel)]="usuario.documento" />
            </mat-form-field>
          </div>
          <div class="item2">
            <mat-form-field>
              <mat-label>Celular:</mat-label>
              <input matInput mask="(00) 00000-0000" placeholder="Número do celular" name="cellphone" maxlength="15"
                formControlName="celular" [(ngModel)]="usuario.celular" />
            </mat-form-field>
          </div>
        </div>

        <div class="row" style="margin-top: 10px;">
          <div class="col-lg-12">
            <small>Contas sincronizadas</small>
            <div class="accounts">
              <div [class]="{vinculada : usuario.emailTFS }">
                <img src="assets\MS.png" class="ms" alt="">
                <div>{{usuario.emailTFS? usuario.emailTFS : "Sincronizar Microsoft"}}
                </div>
                <button *ngIf="usuario.emailTFS" type="button" mat-icon-button
                  (click)="usuario.emailTFS = null"><mat-icon>close</mat-icon></button>
                <button *ngIf="!usuario.emailTFS" type="button" mat-icon-button
                  (click)="loginWithMicrosoft()"><mat-icon>compare_arrows</mat-icon></button>
                <!-- <div>
                    <img src="assets\reload.png" alt="" class="reload" (click)="loginWithMicrosoft()">
                  </div> -->
              </div>
              <div [class]="{vinculada : usuario.jiraAccountId }">
                <img src="assets\images\jira-logo.svg" alt="" class="ms">
                <div>{{usuario.jiraAccountId ? "Conta vinculada" : "Sincronizar Jira"}}
                </div>
                <button *ngIf="usuario.jiraAccountId" type="button" mat-icon-button
                  (click)="usuario.jiraAccountId = null"><mat-icon>close</mat-icon></button>
                <button *ngIf="!usuario.jiraAccountId" type="button" mat-icon-button
                  (click)="redirecionarParaLinkExterno()"><mat-icon>compare_arrows</mat-icon></button>
                <!-- <div>
                    <img src="assets\reload.png" alt="" class="reload" (click)="redirecionarParaLinkExterno()">
                  </div> -->
              </div>
            </div>
          </div>
        </div>
      </form>
    </perfect-scrollbar>
  </div>
  <div class="modal-actions" mat-dialog-actions align="end">
    <button mat-raised-button [disabled]="cadastroUsuario.invalid || isLoading" (click)="save($event)" color="primary">
      Salvar
    </button>
  </div>
</div>