<div class="timer" id="time">
  <mat-progress-spinner color="primary" [hidden]="!isLoading" mode="indeterminate" diameter="35"></mat-progress-spinner>
  <ng-container *ngIf="!isLoading">
    <ng-container>
      <button *ngIf="play || !runningTask" mat-icon-button class="circle-button btn-play" (click)="playTime()">
        <i class="fas fa-play fa-sm"></i>
      </button>
    </ng-container>
    <button *ngIf="!play && runningTask" click-stop-propagation mat-icon-button [matMenuTriggerFor]="pauseMenu"
      class="circle-button btn-stop">
      <i class="fas fa-stop fa-sm"></i>
    </button>
    <mat-menu #pauseMenu="matMenu">
      <button *ngIf="!play" mat-menu-item (click)="stopT()">
        <i class="fas fa-check mr-2"></i>
        <span>Lançar Apontamento</span>
      </button>
      <button *ngIf="!play" mat-menu-item (click)="stopT(true)">
        <i class="fas fa-check-double mr-2"></i>
        <span>Lançar e finalizar tarefa</span>
      </button>
      <button mat-menu-item (click)="openConfirmation()">
        <i class="fas fa-times mr-2"></i>
        <span>Cancelar e excluir</span>
      </button>
    </mat-menu>
    <div class="timer-desc" *ngIf="!hideCron">
      <span class="timer-time" [class.waiting]="ticks < 0" *ngIf="!hideCron">{{ hoursDisplay ? hoursDisplay : '00' }} :
        {{
        (minutesDisplay) && (minutesDisplay <= 59) ? minutesDisplay : '00' }} : {{ (secondsDisplay) && (secondsDisplay
          <=59) ? secondsDisplay : '00' }} </span>
    </div>
    <p class="timer-task" *ngIf="!hideCron && descricao && (origem !== 'devops')" class="small m-0">{{ descricao ?
      descricao :
      null }}</p>
  </ng-container>
</div>