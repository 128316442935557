import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import {
  ApiAzureDevopsService,
  FiltroGetTasksDevOps,
} from 'src/app/services/api-azure-devops.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalApontamentosComponent } from '../../../@modules/main/components/apontamentos/modal-apontamentos/modal-apontamentos.component';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { TimerService } from 'src/app/services/timer.service';
import { TimerComponent } from 'src/app/@shared/components/timer/timer.component';
import { SharedService } from 'src/app/services/shared.service';
import { Subscription } from 'rxjs';
import { ModalSelecaoTarefaComponent } from '../../../@modules/main/components/board/devops-tasks/modal-selecao-tarefa/modal-selecao-tarefa.component';
import { ExpedienteTarefa } from 'src/app/models/expedientes';
import { EditWorkitemComponent } from '../../../@modules/main/components/work/edit-workitem/edit-workitem.component';
import { DevopsHelpers } from 'src/app/@helpers/devops.helpers';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})

export class TaskComponent implements OnInit, OnDestroy {
  @Input() atraso: Boolean = false;
  @Input() task: ExpedienteTarefa;

  @Input() admin: Boolean = false;

  @Output() onEdit = new EventEmitter();

  @ViewChild('timerChild', { static: false }) timerChild: TimerComponent;

  isVisible = true;
  isLoading = true;
  tasks: any[] = [];
  selectedItem: any;

  widthPorcentagemHoras: any;
  porcentagemHoras: any = 0.00;

  widthPorcentagemTarefas: any;
  porcentagemTarefas: any = 0.00;

  expanded = false;

  private subjectDevopsUnsubscribe: any;

  status: any = [
    'New',
    'To Do',
    'Approved',
    'Ready for test',
    'Commited',
    'Aguardando Priorização',
    'Done',
    'Staging',
    'In Progress',
  ];
  selectedOptions = new FormControl(['New', 'To Do', 'In Progress']);

  types: any = ['Ticket', 'Task', 'Bug', 'Epic', 'Feature', 'Product Backlog Item'];
  selectedTypes = new FormControl(['Ticket', 'Task', 'Bug']);

  private playPauseStopUnsubscribe: any;
  private play: boolean;

  messages = [];
  subscription: Subscription;
  origem = 'devops';

  public runningTask: any;


  constructor(
    public devops: ApiAzureDevopsService,
    public dialog: MatDialog,
    public userService: UsuarioService,
    private sharedService: SharedService,
    public timerService: TimerService
  ) {
  }

  ngOnInit() {

    this.playPauseStopUnsubscribe = this.timerService.playPauseStop$
      .subscribe((res: any) => this.setPlay(res));

    // this.subjectDevopsUnsubscribe = this.sharedService.subjectDevops
    //   .subscribe(async message => {
    //     if (message) {
    //       this.timerChild?.updateControls();
    //     }
    //   });

    // this.subscription = this.sharedService.getMessageDevopsGrid()
    //   .subscribe(message => {
    //     if (message) {
    //       this.messages.push(message);
    //       this.getWorkItems();
    //     } else {
    //       this.messages = [];
    //     }
    //   });

    // this.getWorkItems();

    // this.startNewTask(this.task);
  }

  ngOnDestroy() {
    this.playPauseStopUnsubscribe?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  // startNewTask(task: any): void {
  //   this.sharedService.startNewTask(task);
  //   // this.timerChild.updateControls();
  // }

  // public marginTopCard(task) {
  //   var hrInicio = task.horarioInicio?.substring(0, 2);
  //   var marginPerHour = 10;
  //   var inicioBase = 6;
  //   var calc = ((parseInt(hrInicio) - inicioBase) * marginPerHour).toFixed(2);
  //   var retorno = calc.toString() + "px";
  //   console.log(retorno);
  //   return retorno;
  // }

  private setPlay(res: any) {
    (res.play) ? this.play = true : this.play = false;
  }

  playTimer() {
    this.timerService.playTimer(this.task);
  }

  pauseTimer() {
    this.timerService.pauseTimer(this.task);
  }

  stopTimer() {
    this.timerService.stopTimer(this.task);
  }

  getWorkItems() {
    this.isLoading = true;
    let filtro = new FiltroGetTasksDevOps();
    filtro.status = this.selectedOptions.value;
    filtro.types = this.selectedTypes.value;
    this.devops.getTasks(filtro).subscribe(x => {
      x = x ? x : [];
      this.tasks = x;
      this.isLoading = false;
    },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.isLoading = false;
      });
  }

  statusChange(event?: any) {
    // this.getWorkItems();
  }


  openDialog(task: any): void {
    const dialogRef = this.dialog.open(ModalApontamentosComponent, {
      disableClose: true,
      width: '500px',
      data: {
        tela: 'devops',
        parametros: {
          // idSprint: this.sprint.id,
          // sprintName: this.sprint.name,
          task
        }
      }
    });

    dialogRef.afterClosed().subscribe(retorno => {
      if (retorno && retorno != 'cancel') {
        // this.openEditTask(task);
      }
    });
  }

  openEditTask(task) {
    const dialogRef = this.dialog.open(EditWorkitemComponent, {
      disableClose: true,
      width: '100%',
      data: {
        task: task
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result != 'cancel') {
        this.onEdit?.emit();
      }
    });
  }

  openNewTfs() {
    const dialogRef = this.dialog.open(ModalSelecaoTarefaComponent, {
      disableClose: true,
      width: '740px',
      data: { tela: 'devops' }
    });

    dialogRef.afterClosed()
      .subscribe(async retorno => {
        //if (retorno != 'cancel') {
        this.onEdit?.emit();
        //}
      });
  }
  iconUrl(task) {
    return DevopsHelpers.iconUrl(task);
  }

  itemUrl(task) {
    return DevopsHelpers.itemUrl(task);
  }

  itemParentUrl(task) {
    return DevopsHelpers.itemParentUrl(task);
  }

  // bgCardColor(status) {
  //   return this.atraso ? 'var(--red)' : 'white';
  // }

  bgColor(status) {
    switch (status) {
      case 'New': return '#5199FF';
      case 'To Do': return '#5199FF';
      case 'In Progress': return '#F3A712';
      case 'Commited': return '#939598';
      case 'Staging': return '#939598';
      case 'Done': return '#00CF91';
      default: return 'gainsboro';
    }
  }
}
