import { Injectable } from '@angular/core';
import { Planejamento } from '../../../../models/planejamento';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Departamento } from 'src/app/models/departamento';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: "root",
})
export class PlanejamentoService {
  constructor(private http: HttpClient) {}

  getPlannings(active?: boolean): Observable<Planejamento[]> {
    return this.http.get<Planejamento[]>(
      `Planejamento/listar/${active}`,
      httpOptions
    );
  }

  getPlanningById(id: number): Observable<Planejamento> {
    return this.http.get<Planejamento>(
      `Planejamento/details/${id}`,
      httpOptions
    );
  }

  createPlanning(planning: Planejamento): Observable<Planejamento> {
    return this.http.post<Planejamento>(
      `Planejamento/create`,
      planning,
      httpOptions
    );
  }

  editPlanning(planning: Planejamento): Observable<Planejamento> {
    return this.http.put<Planejamento>(
      `Planejamento/edit`,
      planning,
      httpOptions
    );
  }

  deletePlanning(id: number): Observable<Planejamento> {
    return this.http.delete<Planejamento>(
      `Planejamento/delete/${id}`,
      httpOptions
    );
  }

  public Departamentos: Departamento[] = [
    {
      id: 1,
      nome: "BOARD",
    },
    {
      id: 2,
      nome: "LIDERANÇA EXECUTIVA[LEX]",
    },
    {
      id: 3,
      nome: "LIDERANÇA OPERACIONAL[LOP]",
    },
    {
      id: 4,
      nome: "[LEX]GESTÃO ESTRATÉGIA",
    },
    {
      id: 5,
      nome: "[LEX][DEMANDA]COMERCIAL",
    },
    {
      id: 6,
      nome: "[LOP]ADM",
    },
    {
      id: 7,
      nome: "[LOP][ADM]FINANCEIRO",
    },
    {
      id: 8,
      nome: "[LOP][ADM]SUPRIMENTOS",
    },
    {
      id: 9,
      nome: "[LOP][ADM]INFRAESTRUTURA",
    },
    {
      id: 10,
      nome: "[LOP][ADM]DEPTO PESSOAL",
    },
    {
      id: 11,
      nome: "[LOP][ADM]FISCAL",
    },
    {
      id: 12,
      nome: "[LOP]OPS & NEW OPS",
    },
    {
      id: 13,
      nome: "[LOP][OPS & NEW OPS]GESTÃO DE PROJETOS",
    },
    {
      id: 14,
      nome: "[LOP][OPS & NEW OPS]SUPORTE",
    },
    {
      id: 15,
      nome: "[LOP][OPS & NEW OPS]DESENVOLVIMENTO",
    },
    {
      id: 16,
      nome: "[LOP][OPS & NEW OPS]QUALIDADE",
    },
    {
      id: 17,
      nome: "[LOP][DEMANDA]MARKETING",
    },
  ];
}
