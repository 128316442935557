import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class MessageService {
    private _listners = new Subject<any>();
    private data: any = undefined;

    listen(): Observable<any> {
        return this._listners.asObservable();
    }

    filter() {
        this._listners.next();
    }

    setData(data: any) {
        this.data = data;
    }

    getData(): any {
        return this.data;
    }

}
