import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
import { StorageService } from "src/app/services/storage/storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  mySubscription: any;
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    var token = state.root.queryParams.at;

    if (state.root.queryParams.newUser === "true") {
      StorageService.deleteUsuario();
    }

    if (!StorageService.getUsuario() || !StorageService.getToken() || (token && StorageService.getToken() != token)) {
      let queryParams: any = {
        ...state.root.queryParams,
        redirectUrl: state.url,
      };
      this.router.navigate(["/signin"], { queryParams });
      return false;
    } else {
      if (
        state.root.queryParams.projectName &&
        state.root.queryParams.organizationName
      ) {
        return this.authService
          .checkCompany(
            state.root.queryParams.projectName,
            state.root.queryParams.organizationName
          )
          .pipe(
            map((check) => {
              if (check.body.empresaVinculada === true) {
                return true;
              } else {
                let queryParams: any = {
                  ...state.root.queryParams,
                  redirectUrl: state.url,
                };
                this.router.navigate(["/select-company"], { queryParams });
                return false;
              }
            })
          );
      } else {
        return true;
      }
    }
  }
}
