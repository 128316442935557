export function DateToString(data: Date) {
    // Obter os componentes da data (ano, mês, dia, hora, minuto, segundo)
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Adicionar 1 ao mês, pois os meses começam em 0
    const dia = String(data.getDate()).padStart(2, '0');
    const hora = String(data.getHours()).padStart(2, '0');
    const minuto = String(data.getMinutes()).padStart(2, '0');
    const segundo = String(data.getSeconds()).padStart(2, '0');

    // Criar a string no formato desejado
    const dataFormatada = `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}`;

    console.log(dataFormatada); // Exibe a data no formato "2023-09-22T09:30:00"
    return dataFormatada;
}

export function AnosParaCombo() {
    let retorno = [];
    const anoFim = new Date().getFullYear();
    for (let index = 2018; index <= anoFim; index++) {
        retorno.push(index);
    }
    return retorno;
}