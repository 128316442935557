import { AppComponent } from "./app.component";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./@core/core.module";
import { AppDefaultModule } from "./@core/app-default/app-default.module";
import { AppRootModule } from "./@core/app-root/app-root.module";
import { AppBlankModule } from "./@core/app-blank/app-blank.module";
import { AppManageEmbedModule } from "./@core/app-manage-embed/app-manage-embed.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    AppDefaultModule,
    AppRootModule,
    AppBlankModule,
    AppManageEmbedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
