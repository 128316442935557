import { AuthService } from "src/app/services/auth.service";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ClienteService } from "src/app/services/cliente.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TimerService } from "src/app/services/timer.service";
import { ApiAzureDevopsService } from "src/app/services/api-azure-devops.service";
import { TimerComponent } from "src/app/@shared/components/timer/timer.component";
import { FormControl, FormGroup } from "@angular/forms";
import { ApontamentoService } from "src/app/services/apontamento.service";
import { SharedService } from "src/app/services/shared.service";
import { CreateWorkitemComponent } from "src/app/@modules/main/components/work/create-workitem/create-workitem.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalEmpresaComponent } from "src/app/@modules/manage/admin/cadEmpresas/modal-empresa/modal-empresa.component";
import { EmpresasService } from "src/app/services/empresas.service";
import { MatTableDataSource } from "@angular/material/table";
import { HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { StorageService } from "src/app/services/storage/storage.service";
import { ModalPerfilComponent } from "../../modais/modal-perfil/modal-perfil.component";

export interface Empresa {
  id?: any;
  razaoSocial: string;
  nomeFantasia: string;
  cnpj: string;
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  isLoading = false;
  @ViewChild("timerChild", { static: false }) timerChild: TimerComponent;
  public customCollapsedHeight = "48px";
  public customExpandedHeight = "48px";
  comunicados: any[];
  company: any = {};
  envUrl = `${environment.api}Usuario/imagem/perfil/${StorageService.getLogon()}`;

  alerts: any;

  clientId = null;
  cliente: any = {};
  grid: MatTableDataSource<any> = new MatTableDataSource<any>();
  paging = { index: 0, size: 10, length: 0 };
  task = new FormControl();
  sprint: any = {};
  tasks: any[] = [];
  status: string[] = ["To Do", "In Progress", "Done"];
  private playPauseStopUnsubscribe: any;
  private subjectDevopsUnsubscribe: any;
  private play: boolean;
  hasCompany: Boolean;
  empresaAtual: any;
  guidEmpresa: string;
  empresa = [];

  showCompanyOptions = false;
  showTimer = true;

  user: any = {};

  runningTask: any;

  constructor(
    public service: EmpresasService,
    public timerService: TimerService,
    public dialog: MatDialog,
    public clienteService: ClienteService,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public devops: ApiAzureDevopsService,
    private apontamentoService: ApontamentoService,
    private sharedService: SharedService,
    public snackBar: MatSnackBar
  ) {
    this.user = StorageService.getUsuario();
    this.guidEmpresa = activatedRoute.snapshot.paramMap.get("company");

    this.playPauseStopUnsubscribe = this.timerService.getValues()
      .subscribe(
        (res: any) => {
          this.runningTask = res?.task;
        }
      );

    // this.subjectDevopsUnsubscribe = this.sharedService.subjectDevops
    //   .subscribe(async message => {
    //     this.runningTask = message?.task;
    //   });

    this.apontamentoService.preAtual().subscribe((retorno) => {
      if (retorno) {
        this.runningTask = retorno;
        this.timerService.playTimer(retorno);
      }
    });

  }

  carregarNomesEmpresas() {
    this.showCompanyOptions = true;
    this.showTimer = false;
    this.service
      .detailsUserEmpresa()
      .subscribe(
        (data: any) => {
          this.isLoading = true;
          this.empresaAtual = data.body
            .map((c: any) => c)
            .filter(
              (identificador) =>
                identificador.identificadorGuid ==
                this.guidEmpresa.toLowerCase()
            )[0];
          const newArr = data.body.filter((e) => {
            return e !== this.empresaAtual;
          });
          this.empresa = newArr;
        },
        (error) => {
          console.error("Ocorreu um erro:", error);
        }
      )
      .add(() => (this.isLoading = false));
  }

  selectCompany(info) {
    window.location.href = '/' + info.identificadorGuid.toUpperCase() + '/manage';
  }

  openProfileModal() {
    const dialogRef = this.dialog.open(ModalPerfilComponent, {
      width: "540px",
      data: { logon: StorageService.getLogon() },
    });
    dialogRef.afterClosed().subscribe(() => { });
  }

  closeCompanyOptions() {
    this.showCompanyOptions = false;
  }

  logout() {
    this.authService.logout();
    location.reload();
  }

  onlyFirstName(name) {
    return name ? name.replace(/ .*/, "") : "";
  }

  ngOnInit() {

    if (this.guidEmpresa)
      this.carregarNomesEmpresas();
  }

  // startNewTask(task: any): void {
  //   this.sharedService.startNewTask(task);
  // }

  openSnackBar(message: string, action: string, duration: number, panelClass?: string): any {
    this.snackBar.open(message, action, { duration, panelClass });
  }

  public cadastrarEmpresa(empresa?: any) {
    const dialogRef = this.dialog.open(ModalEmpresaComponent, {
      width: "450px",
      data: { empresa },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === "cancel") {
        return;
      } else {
        // this.service.detailsUserEmpresa().subscribe((c: HttpResponse<any>) => {
        //   const redirectUrl = c.body[0].identificadorGuid + "/manage";
        //   this.router.navigateByUrl(redirectUrl);
        // });
      }
    });
  }

  public getCompanyUser() {
    this.service.detailsUserEmpresa().subscribe({
      next: (response: HttpResponse<any[]>) => {
        this.hasCompany = response.body?.length != 0 ? true : false;
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  // private setPlay(res: any) {
  //   if (res.play)
  //     this.timerService.playTimer();
  //   else
  //     this.timerService.stopTimer();
  // }

  // playTimer() {
  //   this.timerService.playTimer();
  // }

  // pauseTimer() {
  //   this.timerService.pauseTimer();
  // }

  // stopTimer() {
  //   this.timerService.stopTimer();
  // }

  openResponsiveMenu() {
    const menu = document.querySelector(".responsive-menu-content");
    menu.classList.toggle("open");
  }
}
