import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })
};
export class FiltroGetTasksDevOps {
  id?: number;
  userId: number;
  sprintId?: number;
  status?: string[];
  types?: string[];
  dataRef?: Date;
  referenciaId?: number;
}
export class FiltroSyncTasksDevOps {
  sprintId: string;
  sprintURL: string;
  sprintName: string;
}
export class FiltroGetSprintDevOps {
  userId?: number;
  team?: string[];
  sprintId?: number;
  dataReferencia?: Date;
}

export class FiltroCreateOrEditTaskDevOps {
  id?: number;
  sprintId?: number;
  idParent?: number;
  parentTitle?: string;
  idWorkItem?: number;
  title: string;
  originalEstimate: number;
  remainingWork?: number;
  emailTFS?: string;
  responsavelId?: number;
  tags: string;
  type?: string;
  bloqueada: boolean;
  concluida: boolean;
  motivoBloqueio: string;
  status: string;
  targetDate: Date;
  priority: number;

  areaPath?: string;
  description?: string;
  reproSteps?: string;
  acceptanceCriteria?: string;
}

@Injectable({
  providedIn: 'root'
})

export class ApiAzureDevopsService {
  isLoading = true;

  constructor(private http: HttpClient) { }

  getSprint(filtro: FiltroGetSprintDevOps): any {
    return this.http.post(`DevOps/sprint`, filtro, httpOptions);
  }

  createSprintTask(filtro: FiltroCreateOrEditTaskDevOps): any {
    return this.http.post(`DevOps/createtask`, filtro, httpOptions);
  }

  editSprintTask(filtro: FiltroCreateOrEditTaskDevOps): any {
    return this.http.put(`DevOps/edittask`, filtro, httpOptions);
  }

  getSprintsEmpresa(filtro?: FiltroGetSprintDevOps): Observable<any> {
    return this.http.post(`DevOps/sprints-empresa`, filtro, httpOptions) as Observable<any>;
  }

  getSprintsUsuario(filtro: FiltroGetSprintDevOps): Observable<any> {
    return this.http.post(`DevOps/sprints-usuario`, filtro, httpOptions) as Observable<any>;
  }

  getTaskById(id): Observable<any> {
    return this.http.get(`DevOps/task/details/${id}`, httpOptions) as Observable<any>;
  }

  getTasks(filtro: FiltroGetTasksDevOps): Observable<any> {
    return this.http.post(`DevOps/tasks`, filtro, httpOptions) as Observable<any>;
  }

  getStories(filtro: FiltroGetTasksDevOps): Observable<any> {
    return this.http.post(`Devops/userstories`, filtro, httpOptions) as Observable<any>;
  }

  syncTasks(filtro: FiltroSyncTasksDevOps) {
    return this.http.post(`DevOps/sync`, filtro, httpOptions);
  }
}
